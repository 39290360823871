/* src/app/profile/profile.component.scss */
::ng-deep .cutomSelect {
  width: 100%;
  border: 1px solid #f9fafc;
  border: none;
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: var(--bs-border-radius-pill) !important;
  background-color: #cfd1f9;
  padding: 10px;
  line-height: 1 !important;
  font-size: 13px;
}
.cursor-pointer {
  cursor: pointer !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel {
  left: 0px;
}
::ng-deep .ng-select-disabled {
  background-color: #bec0e5 !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background: #fff;
  top: 11px;
  height: 100px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  width: 94%;
  left: 10px;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 2px solid #fdfdfd !important;
  padding: 6px 10px;
  text-align: start;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #f4f5f5;
}
::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.bi {
  color: #26ADE8;
}
.form-control {
  font-size: 13px !important;
}
.custom-input {
  background-color: #cfd1f9 !important;
  height: 34px;
}
.profile-table-wrapper {
  background-color: var(--bs-white);
  font-size: 13px;
  height: 90vh !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.profile-table-wrapper table {
  width: 100%;
  white-space: nowrap !important;
}
.profile-table-wrapper thead th {
  position: sticky;
  top: 0;
  background-color: rgb(239, 255, 253);
  z-index: 10;
}
.profile-table-wrapper td {
  top: 0;
  background-color: white;
  z-index: 10;
}
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container,
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  height: 13px !important;
}
::ng-deep .ng-select .ng-select-container {
  height: 14px !important;
}
/*# sourceMappingURL=profile.component.css.map */
