import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  constructor() { }

  
  // Method to show toast with simplified types ('success', 'error', 'warning')
  show(textOrTpl: string | TemplateRef<any>, type: 'success' | 'danger' | 'warning' | 'secondary' = 'secondary') {
    const options = this.getToastOptions(type);
    this.toasts.push({ textOrTpl, ...options });
  }

  // Get default options based on the type
  private getToastOptions(type: string) {
    const className = 'bg-' + type +  ' text-light'
    return { classname: className, delay: 5000, autohide: true };
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts = [];
  }
}
