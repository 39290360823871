/* src/app/shared/add-edit-booklet/add-edit-booklet.component.scss */
.file-picker-text {
  text-align: end;
}
.nav-tabs .nav-link {
  border-color: var(--bs-primary) !important;
  border-bottom: 0px !important;
}
.nav-tabs .nav-link.disabled {
  border-color: gray !important;
  border-bottom: 0px !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: var(--bs-primary) !important;
  color: #fff;
}
.nav-tabs {
  border-color: var(--bs-primary) !important;
}
::ng-deep .ng-select .ng-clear-wrapper .ng-clear {
  margin-top: -1px;
}
.cursor-pointer {
  cursor: pointer !important;
}
::ng-deep .cutomSelect {
  width: 100%;
  height: 34px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: var(--bs-border-radius-pill) !important;
  background-color: #fff;
  padding: 8px;
  line-height: 1 !important;
  font-size: 13px;
}
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-placeholder,
input.form-control::placeholder {
  color: rgba(81, 88, 81, 0.8901960784) !important;
}
input.form-control[type=date]::placeholder {
  color: rgba(81, 88, 81, 0.8901960784) !important;
}
::ng-deep .cutomSelect.ng-select .ng-arrow-wrapper {
  width: 16px !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel {
  left: 0px;
}
::ng-deep .ng-select-disabled {
  background-color: #e2dddd !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background: #fff;
  top: 11px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  width: 94%;
  left: 10px;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid #dee2e6;
  padding: 6px 10px;
  text-align: start;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #c3c6c9;
}
::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}
/*# sourceMappingURL=add-edit-booklet.component.css.map */
