<!-- Modal structure -->
<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>

<div class="a4pagesize "> 
      <div class="m-auto mb-2 d-flex justify-content-around flex-column position-fixed align-items-end bottom-0 w-25" style="right:0px;z-index:999">

        <button class="btn btn-primary rounded-start-pill preview-btn mb-3" title="Download Pdf" (click)="downloadPDF()"><i class="bi bi-file-pdf-fill px-2 fs-6 d-inline-block"></i> <span class="d-inline-block fs-6">  Download Pdf </span></button>
      </div>
  
      <div class="row m-0 p-0">
      
        <!-- Download button -->
        
        <!-- Profile Content -->
        <div *ngIf="profileCompositeInit" class="rounded-1 col-12  shadow-sm p-0 mb-4">
          <!-- Profile Header -->
          <div>
            <img [src]="profileCompositeInit.profile_header" class="img-fluid w-100">
          </div>
  
          <div class="row p-0 m-0">
            <!-- Profile Image and Information -->
            <div class="col-md-3 card  rounded-0">
              <!-- Profile Image -->
              <div class="card-body mb-0 text-center">
                <img [src]="profileCompositeInit.image" class="img-fluid m-auto d-block " alt="Profile Image" width="200" height="200">
              </div>
            </div>
  
            <div class="col-md-9">
              <!-- Profile Information -->
              <div class="card mb-4  rounded-0">
                <div class="card-body">
                  <table class="table table-responsive mb-0 card-body table-light table-borderless">
                    <tr>
                      <th scope="row">Name:</th>
                      <td *ngIf="profileCompositeInit.linkedin_url != null">
                        <a [href]="'https://' + profileCompositeInit.linkedin_url" target="_blank" class="text-primary fw-bold p-0 bg-light">{{profileCompositeInit.fullname}}</a>, {{profileCompositeInit.company_name}}
                      </td>
                       
                    </tr>
                    <tr>
                      <th scope="row">Title:</th>
                      <td>{{profileCompositeInit.job_title}}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email:</th>
                      <td><a href="mailto:{{profileCompositeInit.email_address}}" class="text-primary fw-bold p-0 bg-light">{{profileCompositeInit.email_address}}</a></td>
                    </tr>
                    <tr>
                      <th scope="row">Website:</th>
                      <td><a [href]="'http://' + profileCompositeInit.company_website" target="_blank" class="text-primary fw-bold p-0 bg-light">{{profileCompositeInit.company_website}}</a></td>
                    </tr>
                    <tr>
                      <th scope="row">Phone:</th>
                      <td>{{profileCompositeInit.contact_no}}</td>
                    </tr>
                    <tr>
                      <th scope="row">Address:</th>
                      <td>{{profileCompositeInit.company_address}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Strategic Priorities and Job Role -->
          <div class="card mb-4" *ngIf="profileCompositeInit.strategic_priorities != null && profileCompositeInit.strategic_priorities.length > 0 ">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary" >Strategic Priorities</h5>
              <ul class="list-unstyled list-group list-group-flush ms-3 mb-3">
                <li class="list-group-item  border-bottom-0 small p-1" *ngFor="let priority of profileCompositeInit.strategic_priorities">{{priority}}</li>
              </ul>
            
            </div>
          </div>
          <!-- Strategic Priorities and Job Role -->
          <div class="card mb-4" *ngIf=" profileCompositeInit.job_role != null && profileCompositeInit.job_role.length > 0">
            <div class="card-body ">
            
              <h5 class="fs-5 card-header fw-semibold text-primary" >{{profileCompositeInit.fullname | getFirstName}}'s Role</h5>
              <p  class="mb-0 p-3">{{profileCompositeInit.job_role}}</p>
            </div>
          </div>
  
          <!-- Tech Stack and Funded Initiatives -->
          <div class="row m-0 ps-0">
            <div class="col-md-6 p-0" *ngIf="profileCompositeInit.tech_stack  != null && profileCompositeInit.tech_stack.length > 0">
              <div class="card mb-4" >
                <div class="card-body ">
                  <h5 class="fs-5 card-header fw-semibold text-primary ">Tech Stack</h5>
                  <ul class="list-group list-group-flush ms-3">
                    <li class="list-group-item  border-bottom-0 small p-1  border-bottom-0 small" *ngFor="let tech of profileCompositeInit.tech_stack">{{tech}}</li>
                  </ul>
                </div>
              </div>
            </div>
  
            <div class="col-md-6 -0" *ngIf="profileCompositeInit.funded_initiative != null && profileCompositeInit.funded_initiative.length > 0">
              <div class="card mb-4" >
                <div class="card-body ">
                  <h5  class="fs-5 card-header fw-semibold text-primary">Funded Initiatives For The Next 12 Months</h5>
                  <ul class="list-unstyled list-group list-group-flush ms-3">
                    <li class="list-group-item  border-bottom-0 small p-1" *ngFor="let initiative of profileCompositeInit.funded_initiative">{{initiative}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Other Interests, Topics, and Extras -->
          <div class="card mb-4" *ngIf="profileCompositeInit.technologies_interest != null && profileCompositeInit.technologies_interest.length > 0">
            <div class="card-body ">
              <h5  class="fs-5 card-header fw-semibold text-primary">Technologies Interested In</h5>
              <ul class="list-unstyled list-group list-group-flush ms-3" *ngIf="profileCompositeInit.technologies_interest.length > 0">
                <li class="list-group-item  border-bottom-0 small p-1" *ngFor="let technology of profileCompositeInit.technologies_interest">{{technology}}</li>
              </ul>
              <p class="p-3" *ngIf="profileCompositeInit.technologies_interest.length == 0"> Data not available. </p>
            </div>
          </div>
  
          <div class="card mb-4" *ngIf="profileCompositeInit.topics_interest != null && profileCompositeInit.topics_interest.length > 0">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary">Topics Interested In</h5>
              <ul class="list-unstyled list-group list-group-flush ms-3" *ngIf="profileCompositeInit.topics_interest.length > 0">
                <li class="list-group-item  border-bottom-0 small p-1" *ngFor="let topic of profileCompositeInit.topics_interest">{{topic}}</li>
              </ul>
              <p class="p-3" *ngIf="profileCompositeInit.topics_interest.length == 0"> Data not available. </p>
            </div>
          </div>
  
          <!-- Business Extras -->
          <div class="card mb-4" *ngIf="profileCompositeInit.profile_extras?.primary_business_activity">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary">Primary Business Activity</h5>
              <p class="p-3">{{profileCompositeInit.profile_extras.primary_business_activity}}</p>
            </div>
          </div>
  
          <div class="card mb-4" *ngIf="profileCompositeInit.profile_extras?.company_annual_revenue">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary">Company Annual Revenue</h5>
              <p class="p-3">{{profileCompositeInit.profile_extras.company_annual_revenue}}</p>
            </div>
          </div>
  
          <div class="card mb-4" *ngIf="profileCompositeInit.profile_extras?.number_of_employees">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary">Number of Employees</h5>
              <p class="p-3">{{profileCompositeInit.profile_extras.number_of_employees}}</p>
            </div>
          </div>
  
          <div class="card mb-4" *ngIf="profileCompositeInit.profile_extras?.annual_company_budget">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary">Annual Company Budget</h5>
              <p class="p-3">{{profileCompositeInit.profile_extras.annual_company_budget}}</p>
            </div>
          </div>
  
          <div class="card mb-4" *ngIf="profileCompositeInit.profile_extras?.scopeof_it_responsibility">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary">Scope of IT Responsibility</h5>
              <p class="p-3">{{profileCompositeInit.profile_extras.scopeof_it_responsibility}}</p>
            </div>
          </div>
  
          <div class="card mb-4" *ngIf="profileCompositeInit.profile_extras?.investment_plans">
            <div class="card-body ">
              <h5 class="fs-5 card-header fw-semibold text-primary">Investment Plans</h5>
              <p class="p-3">{{profileCompositeInit.profile_extras.investment_plans}}</p>
            </div>
          </div>
  
          <!-- Custom Fields -->
          <div class="card mb-4" *ngIf="profileCompositeInit.custom_fields && profileCompositeInit.custom_fields != null">
            <div class="card-body ">
              <ul class="list-unstyled list-group list-group-flush  " *ngIf="profileCompositeInit.custom_fields.length > 0">
                <li class="list-group-item  border-bottom-0 small p-1" *ngFor="let field of profileCompositeInit.custom_fields">
                  <h5 class="fs-5 card-header fw-semibold text-primary">{{field.header}}</h5>
                  <p class="p-3">{{field.value}}</p>
                </li>
              </ul>
              <p class="p-3" *ngIf="profileCompositeInit.custom_fields.length == 0"> Data not available. </p>
            </div>
          </div>
  
          <!-- Footer Links -->
          <!-- <div class="text-center mt-4">
            <a class="text-decoration-none text-primary-emphasis" href="https://www.commonsense.events/" target="_blank">www.CommonSense.events</a><br>
            <a class="text-decoration-none text-primary-emphasis" href="https://www.buyerforesight.com/" target="_blank">www.BuyerForesight.com</a>
          </div> -->
        </div>
      </div>
</div>