import { Component, inject, Input, Optional, TemplateRef, ViewChild, viewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProfileExtrasModel, ProfileModel } from '../../model/profile.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileViewModel } from '../../profile/profile.viewModel';
import {
  DataUrl,
  NgxImageCompressService,
  UploadResponse,
} from 'ngx-image-compress';
import { BehaviorSubject } from 'rxjs';
import { StandardResponseModal } from '../../model/booklet.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-add-profile',
  standalone: false,
  templateUrl: './add-edit-profile.component.html',
  styleUrl: './add-edit-profile.component.scss',
})
export class AddProfileComponent {
  // activeModal = inject(NgbActiveModal);
  serviceResponseProgress = false;
  industryListR = new BehaviorSubject<[]>([])
  duplicateValue: boolean = false;
  duplicateMsg:any
  dataFiledLinkedin: boolean = false;
  dataFiledEmail: boolean = false;
  newProfile: ProfileModel = new ProfileModel();
  newProfileForm: FormGroup;
  // tslint:disable-next-line: max-line-length
  profileImageEncoded: string = '';
  userId: string;
  bookletId: string;
  startegyList = [];
  roleList = [];
  techList = [];
  fundList = [];
  industryList:any = []
  addressLimitExceed:boolean = false
  @Input() data:any
  @ViewChild('content') content!:TemplateRef<any>
  enableExtra:boolean
  imgResult: string = '';
  customFieldsCount = 0;
  demoImage: string = '';
  selectedProfileExtras:any = {
    primary_business_activity:'',
    company_annual_revenue:'',
    number_of_employees:'',
    annual_company_budget:'',
    scopeof_it_responsibility:'',
    investment_plans:''
  }
  profileCreatedBy:any
  enableExtraAddButton:boolean = true
  private modalService = inject(NgbModal);
  
  constructor(
    private fb: FormBuilder,
    private vm: ProfileViewModel,
    private appActiveRoute: ActivatedRoute,
    private imageCompress: NgxImageCompressService,
    public toastService: ToastService,
    private router: Router,
    @Optional() public activeModal: NgbActiveModal
  ) {

    
    this.vm.addProfileComponent = this;
    this.profileImageEncoded = this.vm.DefaultProfilePicture;
    
  }

  ngOnInit() {

    if(this.data){
      this.newProfile = this.data.selectedProfileData as ProfileModel;
      this.newProfile.custom_fields && ( this.customFieldsCount = this.newProfile.custom_fields.length)
      this.profileCreatedBy = this.data.selectedProfileData.created_by;
     
      this.profileImageEncoded = this.newProfile.image
      this.enableExtra = true


      if(this.data.selectedProfileExtras){
        this.selectedProfileExtras = JSON.parse(JSON.stringify(this.data.selectedProfileExtras))  as ProfileExtrasModel
        this.enableExtraAddButton = false
        delete this.selectedProfileExtras.profile_id
        delete this.selectedProfileExtras.id
      }

      this.createUpdateForm()

      // console.log(this.data)
    } else{
      this.createForm();
    }
    // console.log(this.data , this.newProfile)

    this.userId = localStorage.getItem('bfs_buyerprofile_uid');
    this.bookletId = this.appActiveRoute.snapshot.paramMap.get('Id')
    this.vm.subscribeAddProfile(this.industryListR);
    this.vm.retrieveAllIndustry('add-profile');
    
  }

  addProfileExtras(){
    this.selectedProfileExtras = new ProfileExtrasModel();
    this.selectedProfileExtras.profile = this.newProfile.id;
    this.enableExtraAddButton = false;
  }

  removeProfileExtras(){
 
    if(this.data.selectedProfileExtras ){
        this.vm.deleteProfileExtras(this.data.selectedProfileExtras.id )
    }
  }

  profileExtrasDeleted(){
    this.enableExtraAddButton = true;
    this.selectedProfileExtras = undefined;
    this.data.selectedProfileExtras = null
    this.newProfileForm.value.profile_extras = this.fb.group(null)
    
  }

  createUpdateForm() {
    this.newProfileForm = this.fb.group({
      fullName: new FormControl(this.newProfile.fullname, Validators.required),
      companyName: new FormControl(this.newProfile.company_name, Validators.required),
      jobTitle: new FormControl(this.newProfile.job_title, Validators.required),
      emailAddress: new FormControl(this.newProfile.email_address, [Validators.required, Validators.email]),
      companyWebsite: new FormControl(this.newProfile.company_website, Validators.required),
      linkedinUrl: new FormControl(this.newProfile.linkedin_url),
      companyAddress: new FormControl(this.newProfile.company_address, Validators.required),
      contactNo: new FormControl(this.newProfile.contact_no, Validators.required),
      jobRole: new FormControl(this.newProfile.job_role),
      strategicProrities: this.fb.array(this.newProfile.strategic_priorities),
      techStack: this.fb.array(this.newProfile.tech_stack),
      industry: new FormControl(this.newProfile.industry),
      fundedInitiative: this.fb.array(this.newProfile.funded_initiative),
      technologiesInterest: this.fb.array(this.newProfile.technologies_interest),
      topicsInterest: this.fb.array(this.newProfile.topics_interest),
      customFields: this.fb.array(this.populateCustomFields(this.newProfile.custom_fields || [])),
      profile_extras:  this.createProfileExtrasGroup() // Initialize the extras array
    });

    if (this.selectedProfileExtras) {
      this.newProfileForm.get('profile_extras').patchValue(this.selectedProfileExtras);
    }
  }

  populateCustomFields(customFields: any[]): FormGroup[] {
    return customFields.map(field => {
        return this.fb.group({
            header: new FormControl(field.header, Validators.required),
            value: new FormControl(field.value, Validators.required)
        });
    });
}
  
  createProfileExtrasGroup(): FormGroup {
    const extrasGroup = this.fb.group({});
    
    Object.keys(this.selectedProfileExtras).forEach(key => {
      extrasGroup.addControl(key, this.fb.control(this.selectedProfileExtras[key]));
    });
  
    return extrasGroup;
  }

  industryListRecieved(){
    this.industryList = this.industryListR.value;
  }

  removeImage() {
    this.imgResult = '';
    this.profileImageEncoded = this.vm.DefaultProfilePicture
  }

  compressFile() {
    return this.imageCompress
      .uploadFile()
      .then(({ image, orientation, fileName }: UploadResponse) => {
        this.profileImageEncoded = image;
        this.imgResult = '';
        if (fileName.includes('ico')) {
          this.imgResult = image;
        }
        console.warn('File Name:', fileName);
        console.warn(
          `Original: ${image.substring(0, 50)}... (${image.length} characters)`
        );
        console.warn(
          'Size in bytes was:',
          this.imageCompress.byteCount(image),
          this.imageCompress.byteCount(image) > 262144
        );
        if (this.imageCompress.byteCount(image) > 262144) {
          this.imageCompress
            .compressFile(image, orientation, 20, 30)
            .then((result: DataUrl) => {
              this.profileImageEncoded = result;
              console.warn(
                `Compressed: ${result.substring(0, 50)}... (${
                  result.length
                } characters)`
              );
              console.warn(
                'Size in bytes is now:',
                this.imageCompress.byteCount(result)
              );
            });
        }
      });
  }

  
  public uploadImage(event) {
    const fileList = event.target.files;
    const file = fileList[0];
    if (fileList && file) {
      const reader = new FileReader();
      reader.onload = (fileData) => {
        this.profileImageEncoded = String(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  createForm() {
    this.newProfileForm = this.fb.group({
      fullName: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      jobTitle: new FormControl('', Validators.required),
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      companyWebsite: new FormControl('', Validators.required),
      industry: new FormControl(null, Validators.required),
      linkedinUrl: new FormControl(''),
      companyAddress: new FormControl('', Validators.required),
      contactNo: new FormControl('', Validators.required),
      jobRole: new FormControl(''),
      strategicProrities: this.fb.array([ ]),
      techStack: this.fb.array([ ]),
      fundedInitiative: this.fb.array([ ]),
      technologiesInterest: this.fb.array([ ]),
      topicsInterest: this.fb.array([ ]),
      customFields: this.fb.array([]),
    });
  }

  checkDuplicate(event, type) {
    // console.log(event.target.value ,this.newProfileForm.get('linkedinUrl').value,this.newProfileForm.get('emailAddress').value,  type, this.dataFiledLinkedin , this.dataFiledEmail)
    this.duplicateValue = false;
    let duplItem = '';
    if (!this.dataFiledLinkedin && type == 'linkedin') {
      duplItem = this.newProfileForm.get('linkedinUrl').value;
      this.dataFiledLinkedin = true;
    }
    if (!this.dataFiledEmail && type == 'email') {
      duplItem = this.newProfileForm.get('emailAddress').value;
      this.dataFiledEmail = true;
    }
    if (duplItem.length > 3) {
      this.vm.retrieveDuplicate(type, duplItem, this.bookletId);

      // if(data.result =='A Profile with same email address already exists in this booklet' || data.result == "A Profile with same Linkedin Url already exists in this booklet"){
      //   this.duplicateValue = true
      // }
    }
    //
    this.dataFiledLinkedin = false;
    this.dataFiledEmail = false;
  }

  duplicateRecived(e:any){
    if(e.result =='A Profile with same email address already exists in this booklet' ||
       e.result == "A Profile with same Linkedin Url already exists in this booklet"){
        this.toastService.show(e?.result, 'warning');
        this.duplicateValue = true
      } else if(e.result != 'No duplicate found'){
        this.duplicateMsg = e 
        this.open(this.content)
      }
   
    
  }

  open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.updateFormValue(this.duplicateMsg?.profileData)
			},
			(reason) => {
				// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
    );
	}

  updateFormValue(data) {
    // this.dataFiled = true
    if (data) {
      this.newProfileForm.get('fullName').setValue(data.fullname);
      this.newProfileForm.controls['companyName'].setValue(data.company_name);
      this.profileImageEncoded = data.image;
      this.newProfileForm.controls['jobTitle'].setValue(data.job_title);
      this.newProfileForm.controls['emailAddress'].setValue(data.email_address);
      this.newProfileForm.controls['companyAddress'].setValue(
        data.company_address
      );
      this.newProfileForm.controls['industry'].setValue(data.industry_id);
      this.newProfileForm.controls['companyWebsite'].setValue(
        data.company_website.includes('http')
          ? data.company_website.slice(data.company_website.indexOf('//') + 2)
          : data.company_website
      );
      this.newProfileForm.controls['linkedinUrl'].setValue(
        data.linkedin_url.includes('http')
          ? data.linkedin_url.slice(data.linkedin_url.indexOf('//') + 2)
          : data.linkedin_url
      );
      this.newProfileForm.controls['contactNo'].setValue(data.contact_no);
      this.newProfileForm.controls['jobRole'].setValue(data.job_role);

      if (data.strategic_priorities.length > 0) {
        for (let i = 0; i < data.strategic_priorities.length; i++) {
          document.getElementById('addPriorities').click();
        }
        this.newProfileForm.controls['strategicProrities'].setValue(
          data.strategic_priorities
        );
      }

      if (data.tech_stack.length > 0) {
        for (let i = 0; i < data.tech_stack.length; i++) {
          document.getElementById('addTechStack').click();
        }
        this.newProfileForm.controls['techStack'].setValue(data.tech_stack);
      }

      if (data.funded_initiative.length > 0) {
        for (let i = 0; i < data.funded_initiative.length; i++) {
          document.getElementById('addInitiatives').click();
        }
        this.newProfileForm.controls['fundedInitiative'].setValue(
          data.funded_initiative
        );
      }
    }
  }

  addPriorities(): void {
    (this.newProfileForm.get('strategicProrities') as FormArray).push(
      this.fb.control('')
    );
  }

  // removePriorities() {
  //   const index =
  //     (this.newProfileForm.get('strategicProrities') as FormArray).controls
  //       .length - 1;
  //   (this.newProfileForm.get('strategicProrities') as FormArray).removeAt(
  //     index
  //   );
  // }

  getStrategicPrioritesFormControls(): FormControl[] {
    return (this.newProfileForm.get('strategicProrities') as FormArray).controls as FormControl[];
  }

  getProfileExtraControls(): { [key: string]: FormControl } {
    return (this.newProfileForm.get('profile_extras') as FormGroup).controls as { [key: string]: FormControl };
  }
  // StratChange(e:any){
  //   this.vm.getStatProList(e.target.value).subscribe(( e:{result:[]}) => this.startegyList = e.result)
  // }

  // roleChange(e:any){
  //   this.vm.getJobList(e.target.value).subscribe(( e:{result:[]}) => this.roleList = e.result)
  // }

  // techChange(e:any){
  //   this.vm.getTechList(e.target.value).subscribe(( e:{result:[]}) => this.techList = e.result)
  // }
  // fundChange(e:any){
  //   this.vm.getFundedList(e.target.value).subscribe(( e:{result:[]}) => this.fundList = e.result)
  // }

  addTechStack(): void {
    (this.newProfileForm.get('techStack') as FormArray).push(
      this.fb.control('')
    );
  }

  // removeTechstack() {
  //   const index =
  //     (this.newProfileForm.get('techStack') as FormArray).controls.length - 1;
  //   (this.newProfileForm.get('techStack') as FormArray).removeAt(index);
  // }

  getTechStackFormControls(): FormControl[] {
    return (this.newProfileForm.get('techStack') as FormArray).controls as FormControl[];
  }

  addInitiatives(): void {
    (this.newProfileForm.get('fundedInitiative') as FormArray).push(
      this.fb.control('')
    );
  }

  // removeInitiatives() {
  //   const index =
  //     (this.newProfileForm.get('fundedInitiative') as FormArray).controls
  //       .length - 1;
  //   (this.newProfileForm.get('fundedInitiative') as FormArray).removeAt(index);
  // }

  addCustomFields(): void {
    if (this.customFieldsCount < 10) {
        this.customFieldsCount++;
        
        const customFieldsArray = this.newProfileForm.get('customFields') as FormArray;
        const newFieldGroup = this.fb.group({
            header: this.fb.control(''),  // Header control
            value: this.fb.control('')     // Value control
        });

        customFieldsArray.push(newFieldGroup);  // Add the new group to the FormArray
    }
}

// Remove the last custom field from the form
// removeCustomFields(): void {
//     const customFieldsArray = this.newProfileForm.get('customFields') as FormArray;
//     if (this.customFieldsCount > 0) {
//         customFieldsArray.removeAt(this.customFieldsCount - 1);   
//         this.customFieldsCount--;  // Decrement the count
//     }
// }

  getCustomFormControls(): FormArray {
    return this.newProfileForm.get('customFields') as FormArray;
  }

  castToFormControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  getInitiativeFormControls():FormControl[] {
    return (this.newProfileForm.get('fundedInitiative') as FormArray).controls as FormControl[];
  }

  addTechnologies(): void {
    (this.newProfileForm.get('technologiesInterest') as FormArray).push(
      this.fb.control('')
    );
  }

  // removeTechnologies() {
  //   const index =
  //     (this.newProfileForm.get('technologiesInterest') as FormArray).controls
  //       .length - 1;
  //   (this.newProfileForm.get('technologiesInterest') as FormArray).removeAt(
  //     index
  //   );
  // }

  getTechnologiesFormControls(): FormControl[] {
    return (this.newProfileForm.get('technologiesInterest') as FormArray)
      .controls as FormControl[];
  }

  addTopics(): void {
    (this.newProfileForm.get('topicsInterest') as FormArray).push(
      this.fb.control('')
    );
  }

  addressChange(){
    if(this.newProfileForm.value.companyAddress.length > 200){
      this.addressLimitExceed = true
      this.toastService.show('Address text max limit (200) exceeded.', 'warning');
    }
  }

  // removeTopics() {
  //   const index =
  //     (this.newProfileForm.get('topicsInterest') as FormArray).controls.length -
  //     1;
  //   (this.newProfileForm.get('topicsInterest') as FormArray).removeAt(index);
  // }

  getTopicsFormControls():FormControl[] {
    return (this.newProfileForm.get('topicsInterest') as FormArray).controls as FormControl[];
  }

  addProfile() {
    // console.log(this.newProfileForm.controls)
    const urlRegex = /^(?:https?:\/\/)?[\wÀ-ž.-]+(?:\.[\wÀ-ž.-]+)+[\wÀ-ž\-._~:/?#[\]@!$&'()*+,;=%]+$/;

    if (!this.newProfileForm.valid) {
      this.toastService.show('Madatory fields are missing or email is valid', 'danger');
      
    } else if (
      !this.newProfileForm.valid &&
      this.newProfileForm.controls['emailAddress'].errors?.['required']
    ) {
      this.toastService.show('Entered email is invalid!', 'danger');
       
    }  else if (
      (this.newProfileForm.value.linkedinUrl.length > 0 &&
        this.newProfileForm.value.linkedinUrl.includes('http')) ||
      (this.newProfileForm.value.companyWebsite.length > 0 &&
        this.newProfileForm.value.companyWebsite.includes('http'))
    ) {
      this.toastService.show('"http" is not required on LinkedIn or website', 'warning');
      
    } else if(!urlRegex.test(this.newProfileForm.value.linkedinUrl)  ){
        this.toastService.show('Please provide valid url in LinkedIn ', 'danger');
      }  else if(!urlRegex.test(this.newProfileForm.value.companyWebsite)  ){
      this.toastService.show('Please provide valid url in website', 'danger');
    } else if(this.newProfileForm.value.companyAddress.length > 200){
      this.addressLimitExceed = true
      this.toastService.show('Address text max limit (200) exceeded.', 'warning');
    } else {
      this.serviceResponseProgress = true;

     
      this.newProfile.fullname = this.newProfileForm.value.fullName;
      this.newProfile.company_name = this.newProfileForm.value.companyName;
      this.newProfile.image = this.profileImageEncoded + '';
      this.newProfile.job_title = this.newProfileForm.value.jobTitle;
      this.newProfile.email_address = this.newProfileForm.value.emailAddress;
      this.newProfile.company_address =
        this.newProfileForm.value.companyAddress;
      this.newProfile.industry = this.newProfileForm.value.industry;
      this.newProfile.industry_name = this.industryList.find(
        (e) => e.id == this.newProfileForm.value.industry
      ).name;
      this.newProfile.company_website =
        this.newProfileForm.value.companyWebsite.includes('http')
          ? this.newProfileForm.value.companyWebsite.slice(
              this.newProfileForm.value.companyWebsite.indexOf('//') + 2
            )
          : this.newProfileForm.value.companyWebsite;
      this.newProfile.linkedin_url =
        this.newProfileForm.value.linkedinUrl.includes('http')
          ? this.newProfileForm.value.linkedinUrl.slice(
              this.newProfileForm.value.linkedinUrl.indexOf('//') + 2
            )
          : this.newProfileForm.value.linkedinUrl;
      this.newProfile.contact_no = this.newProfileForm.value.contactNo;
      this.newProfile.job_role = this.newProfileForm.value.jobRole;
      this.newProfile.tech_stack = this.newProfileForm.value.techStack;
      this.newProfile.strategic_priorities =
        this.newProfileForm.value.strategicProrities;
      this.newProfile.topics_interest =
        this.newProfileForm.value.topicsInterest;
      this.newProfile.technologies_interest =
        this.newProfileForm.value.technologiesInterest;
      this.newProfile.funded_initiative =
        this.newProfileForm.value.fundedInitiative;

        let customFieldData = [];
        const customFieldsArray = this.newProfileForm.get('customFields') as FormArray;
        
        // Iterate over the FormArray to get header and value pairs
        customFieldsArray.controls.forEach((fieldGroup) => {
            const customItem = {
                header: fieldGroup.get('header')?.value || '',
                value: fieldGroup.get('value')?.value || ''
            };
            customFieldData.push(customItem);
        });
        
        // Now customFieldData can be sent to the API
        // console.log(customFieldData);
     
      this.newProfile.custom_fields =
      customFieldData.length > 0 ? customFieldData : null;
      if(this.selectedProfileExtras ) {
        this.newProfile.profile_extras = this.newProfileForm.value.profile_extras
      }
      // console.log(customFieldData , this.newProfile)

      if(this.data){
        this.newProfile.modified_by = Number(this.userId),
        this.newProfile.modified_at = new Date();
        this.vm.updateProfile(this.newProfile);
      } else{
        this.newProfile.booklet_id = Number(this.bookletId);
        this.newProfile.created_by = Number(this.userId),
        this.vm.createProfile(this.newProfile);
      }
      
    }
  }

  profileCreated() {
    this.serviceResponseProgress = false;
    if( this.data?.selectedProfileExtras){

      this.updateProfileExtras()

    } else if(this.newProfileForm.value?.profile_extras && !this.enableExtraAddButton){

      this.createProfileExtras()
        
    } else {
      !this.bookletId && this.activeModal.close()
      
      this.resetForm();
      this.bookletId && this.router.navigateByUrl('/booklet/'+ this.bookletId)
    }
  }

  profileExtraCreated(){
    if(this.data){
      this.activeModal.close()
    } else{
      this.resetForm();
      this.router.navigateByUrl('/booklet/'+ this.bookletId)
    }
  }

  updateProfileExtras() {
    let obj = Object.assign({},this.newProfileForm.value.profile_extras)
    obj['profile']=this.newProfile.id
    obj['id']=this.data.selectedProfileExtras.id
    this.vm.patchProfileExtras(obj );
  }

  createProfileExtras() {
    let obj = Object.assign({},this.newProfileForm.value.profile_extras)
    obj['profile']=this.newProfile.id
    this.vm.postProfileExtras(obj );
  }

  resetForm() {
    // tslint:disable-next-line: max-line-length
    this.duplicateValue = false;
    this.profileImageEncoded = this.vm.DefaultProfilePicture;
    this.newProfileForm.reset();
    this.createForm();
  }

  // Function to remove an item from 'fundedInitiative' by index or the last one
removeInitiative(index?: number) {
  const initiativeArray = this.newProfileForm.get('fundedInitiative') as FormArray;
  if (initiativeArray.length > 0) {
    const removeIndex = index ?? initiativeArray.length - 1;
    if (removeIndex >= 0 && removeIndex < initiativeArray.length) {
      initiativeArray.removeAt(removeIndex);
    }
  }
}

// Function to remove an item from 'techStack' by index or the last one
removeTechstack(index?: number) {
  const techStackArray = this.newProfileForm.get('techStack') as FormArray;
  if (techStackArray.length > 0) {
    const removeIndex = index ?? techStackArray.length - 1;
    if (removeIndex >= 0 && removeIndex < techStackArray.length) {
      techStackArray.removeAt(removeIndex);
    }
  }
}

// Function to remove an item from 'customFields' by index or the last one
removeCustomFields(index?: number) {
  const customFieldsArray = this.newProfileForm.get('customFields') as FormArray;
  if (customFieldsArray.length > 0) {
    const removeIndex = index ?? customFieldsArray.length - 1;
    if (removeIndex >= 0 && removeIndex < customFieldsArray.length) {
      customFieldsArray.removeAt(removeIndex);
      this.customFieldsCount--;  // Adjust count if necessary
    }
  }
}

// Function to remove an item from 'technologiesInterest' by index or the last one
removeTechnologies(index?: number) {
  const technologiesArray = this.newProfileForm.get('technologiesInterest') as FormArray;
  if (technologiesArray.length > 0) {
    const removeIndex = index ?? technologiesArray.length - 1;
    if (removeIndex >= 0 && removeIndex < technologiesArray.length) {
      technologiesArray.removeAt(removeIndex);
    }
  }
}

// Function to remove an item from 'topicsInterest' by index or the last one
removeTopics(index?: number) {
  const topicsArray = this.newProfileForm.get('topicsInterest') as FormArray;
  if (topicsArray.length > 0) {
    const removeIndex = index ?? topicsArray.length - 1;
    if (removeIndex >= 0 && removeIndex < topicsArray.length) {
      topicsArray.removeAt(removeIndex);
    }
  }
}

// Function to remove an item from 'strategicProrities' by index or the last one
removePriorities(index?: number) {
  const prioritiesArray = this.newProfileForm.get('strategicProrities') as FormArray;
  if (prioritiesArray.length > 0) {
    const removeIndex = index ?? prioritiesArray.length - 1;
    if (removeIndex >= 0 && removeIndex < prioritiesArray.length) {
      prioritiesArray.removeAt(removeIndex);
    }
  }
}

// removeProfileExtras(key: string) {
//   const extrasGroup = this.newProfileForm.get('profile_extras') as FormGroup;

//   // Check if the control with the given key exists
//   if (extrasGroup.contains(key)) {
//     // Capture the ID or relevant value before removing the control
//     const removedExtraId = extrasGroup.get(key)?.get('id')?.value;

//     // Remove the control with the given key
//     extrasGroup.removeControl(key);

//     // Optional: Call a method to delete the extra from the backend, if needed
//     if (removedExtraId) {
//       this.vm.deleteProfileExtras(removedExtraId);
//     }

//     console.log(`Removed key: ${key}`, extrasGroup, removedExtraId);
//   }
// }



// removeProfileExtras() {
//   const extrasArray = this.newProfileForm.get('customFields') as FormArray;

//   // Check if 'selectedProfileExtras' has an 'id' and the array is not empty
//   if (this.selectedProfileExtras.id && extrasArray.length > 0) {
//     // Find the index of the form control with the matching 'id'
//     const index = extrasArray.controls.findIndex(
//       control => control.get('id')?.value === this.selectedProfileExtras.id
//     );

//     // If a matching index is found, remove the item at that index
//     if (index !== -1) {
//       extrasArray.removeAt(index);
//       this.vm.deleteProfileExtras(this.selectedProfileExtras.id);  // Call the delete method
//     }
//   }
// }



  
}
