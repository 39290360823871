<div class="modal-header">
  <div class="dialog-button-container">
    <span class="button-seperator">
      <p class="fs-4 text-primary">Upload Profile</p>
    </span>
  </div>
</div>

<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <h5>
        Please find a sample file below, which you can use to do Bulk Upload
      </h5>
      <p>
        Please only upload csv & excel file and keep the headers same as sample
        file.
      </p>
      <p>
        <a class="btn btn-info me-3" href="../../../../assets/Sample-file.csv">
            <i class="bi bi-filetype-csv text-light fw-800 "></i>
        </a>
        <a class="btn btn-info" href="../../../../assets/test_bp.xls">
            <i class="bi bi-filetype-xls text-light fw-800 "></i>
        </a>
      </p>
      
        <div class="input-group"> 
           
            <input
            #fileUpload
            type="file"
            class="form-control"
            (input)="onInput($event)"
            (change)="onFileSelected($event)"
            [(ngModel)]="inputFileName"
            accept=".csv,.xls,.xlsx"
            />
            
    
        </div>
      

      <div *ngIf="file" class="mt-2">
        <span> {{ file[0].name }} </span>
        <i class="bi bi-archive-fill text-danger fw-800 ms-2 cursor-pointer" (click)="removeFile()"></i>
         
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" style="padding-top: 1px">
  <span class="button-seperator">
    <button
      class="btn btn-secondary"
      (click)="eventCallSkip()"
    
    >
      Close
    </button>
  </span>

  <span class="button-seperator">
    <button
      class="btn btn-primary"
      style="width: 120px"
      (click)="eventCallUpdate()"
      
    >
      Upload
    </button>
  </span>
</div>
