import { Component, inject } from '@angular/core';
import { ProfileModel, ProfileExtrasModel } from '../../model/profile.model';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileViewModel } from '../profile.viewModel';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { profileBookletEditBeforeExportComponent } from '../../booklet/booklet-modals/profileBookletEditBeforeExportComponent/profile-booklet-edit-before-export.component';
import { AddProfileComponent } from '../../shared/add-edit-profile/add-edit-profile.component';
import { BehaviorSubject } from 'rxjs';
import { DeleteProfileComponent } from '../profile-modals/delete-profile/delete-profile.component';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrl: './profile-details.component.scss',
})
export class ProfileDetailsComponent {
  serviceResponseProgress = true;
  checkLastCharacter = false;
  userId: string;
  profileInit: ProfileModel;
  profileExtras: ProfileExtrasModel;
  profileId: string;
  firstName: string;
  private modalService = inject(NgbModal);
  profileModel = new BehaviorSubject<ProfileModel>(new ProfileModel());
  profileExtrasModel = new BehaviorSubject<ProfileExtrasModel>(
    new ProfileExtrasModel()
  );

  constructor(
    private appActiveRoute: ActivatedRoute,
    private vm: ProfileViewModel,
    private router: Router
  ) {
    this.vm.profileDetailsComponent = this;
    this.router.events.subscribe((val: NavigationStart) => {
      if (val.url !== undefined) {
        if (!val.url.startsWith('/profile')) {
          localStorage.removeItem('fromBooklet');
        }
      }
    });
  }

  ngOnInit() {
    this.profileId = this.appActiveRoute.snapshot.paramMap.get('id');
    this.userId = localStorage.getItem('bfs_buyerprofile_uid');
    this.vm.subscribeProfileDetail(this.profileModel, this.profileExtrasModel);
    this.vm.retrieveProfilebyId(this.profileId);
  }

  ngOnDestroy(): void {
    localStorage.removeItem('fromBooklet');
  }

  retrieveProfilebyIdReceived() {
    this.profileInit = this.profileModel.value;
    const fullname = this.profileInit.fullname;
    const nameSplitted = fullname.split(' ');
    this.firstName = nameSplitted[0];

    this.serviceResponseProgress = false;
    if (this.firstName[this.firstName.length - 1] === 's') {
      this.checkLastCharacter = true;
    } else {
      this.checkLastCharacter = false;
    }

    this.getProfileExtras();
  }

  getProfileExtras() {
    this.vm.getProfileExtras(this.profileInit.id);
  }

  ProfileExtrasRecieved() {
    this.profileExtras = this.profileExtrasModel.value;
    this.serviceResponseProgress = false;
  }

  getUrl(url: string) {
    if (!url.includes('http')) {
      return 'https://' + url;
    } else {
      return url;
    }
  }

  removeHttp(url: string) {
    if (url.includes('https://') || url.includes('http://')) {
      if (url.includes('https://')) {
        url = url.replace('https://', '');
      }
      if (url.includes('http://')) {
        url = url.replace('http://', '');
      }
    }
    return url;
  }

  deleteProfileDialog() {
    // e.preventDefault(); // Prevent page from refreshing
    const dialogconfig = this.modalService.open(DeleteProfileComponent);
    dialogconfig.componentInstance.data = {
      selectedItemName: this.profileInit.fullname,
      selectedItemId: this.profileInit.id,
    };

    dialogconfig.result.then(
      (result) => {
        if (localStorage.getItem('fromBooklet')) {
          let bookletId = localStorage.getItem('BookletID');
          this.router.navigate(['booklet/' + bookletId]);
          localStorage.removeItem('fromBooklet');
        } else {
          this.router.navigate(['profile']);
        }
        console.log('Modal closed with result:', result);
      },
      (reason) => {
        // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
        console.log('Modal dismissed with reason:', reason);
        
      }
    );
  }

   

  editProfileDialog() {
    // e.preventDefault(); // Prevent page from refreshing
    const dialogconfig = this.modalService.open(AddProfileComponent,{size:'xl'});
    dialogconfig.componentInstance.data = {
      userId: Number(this.userId),
      selectedProfileData: this.profileInit,
      selectedProfileExtras: this.profileExtras,
    };
    dialogconfig.result.then(
      (result) => {
        this.vm.retrieveProfilebyId(this.profileId);
        console.log('Modal closed with result:', result);
      },
      (reason) => {
        // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
        console.log('Modal dismissed with reason:', reason);
        this.vm.retrieveProfilebyId(this.profileId);
      }
    );
  }

  exportProfileDialog(fullname: any, path: any, id: any) {
    const modalRef = this.modalService.open(
      profileBookletEditBeforeExportComponent
    );
    modalRef.componentInstance.data = {
      selectedItemName: fullname,
      path: path,
      selectedItemId: id,
      type: 'Profile',
    };

    modalRef.result.then(
      (result) => {
        console.log('Modal closed with result:', result);
      },
      (reason) => {
        // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
        console.log('Modal dismissed with reason:', reason);
      }
    );
  }
}
