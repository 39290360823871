import { Routes } from '@angular/router';
import { BookletComponent } from './booklet/booklet.component';
import { ProfileComponent } from './profile/profile.component';
import { FrozenItemComponent } from './frozen-item/frozen-item.component';
import { StatsComponent } from './stats/stats.component';
import { AddProfileComponent } from './shared/add-edit-profile/add-edit-profile.component';
import { BookletCompositeComponent } from './booklet/booklet-composite/booklet-composite.component';
import { AddBookletComponent } from './shared/add-edit-booklet/add-edit-booklet.component';
import { BookletDetailsComponent } from './booklet/booklet-details/booklet-details.component';
import { LoginComponent } from './login/login.component';
import { ProfileCompositeComponent } from './profile/profile-composite/profile-composite.component';
import { ProfileDetailsComponent } from './profile/profile-details/profile-details.component';
import { canActivate } from './services/auth-guard.service';
 

export const routes: Routes = [
  { path: '', redirectTo: '/booklet', pathMatch: 'full' },
  { path: 'booklet', component: BookletComponent , canActivate: [canActivate]},
  { path: 'booklet/:Id/add-profile', component: AddProfileComponent ,canActivate: [canActivate]},
  { path: 'booklet/:Id', component: BookletDetailsComponent ,canActivate: [canActivate]},
  { path: 'add-booklet', component: AddBookletComponent ,canActivate: [canActivate]},
  { path: 'booklet-composite/:name', component: BookletCompositeComponent,canActivate: [canActivate] },
  { path: 'profile-composite/:name', component: ProfileCompositeComponent,canActivate: [canActivate] },
  { path: 'profile', component: ProfileComponent,canActivate: [canActivate] },
  { path: 'profile/:id', component: ProfileDetailsComponent,canActivate: [canActivate] },
  { path: 'frozen', component: FrozenItemComponent ,canActivate: [canActivate]},
  { path: 'stats', component: StatsComponent,canActivate: [canActivate] },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '/booklet' }  // Wildcard route for a 404 page
]
 