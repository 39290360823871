<p
  class="p-3 m-5 border border-secondary mb-3 mt-4 text-center fw-bold h-100 rounded-4 pt-5 fs-4"
  style="
    border-style: dashed !important;
    border-color: #e4e5f7 !important ;
    border-width: 2px;
    color: #6e6f7e;
    background-color: #f3f4f8;
  " *ngIf="show"
>
  No data found! <br /><br />
  <img
    class="d-block h-auto w-50 m-auto"
    src="../../assets/undraw_empty_re_opql.svg"
  />
</p>
