import { Component, inject, Input } from '@angular/core';
import { ProfileViewModel } from '../../profile.viewModel';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-profile',
  templateUrl: './delete-profile.component.html',
  styleUrl: './delete-profile.component.scss'
})
export class DeleteProfileComponent {

  @Input() data:any
  activeModal = inject(NgbActiveModal);
  serviceResponseProgress = false;
  selectedItemName: string;
  selectedItemId: number;
  
  constructor(   private fb: FormBuilder, private vm:ProfileViewModel) {
        this.vm.deleteProfileComponent = this
  }
  
  ngOnInit() {
          this.selectedItemName = this.data.selectedItemName;
          this.selectedItemId = this.data.selectedItemId;
   }
  
  eventCallYes() {
   this.serviceResponseProgress = true;
   this.vm.deleteProfile(this.selectedItemId)
  }
  
  eventCallNo() {
    this.activeModal.close('modal closed')
    this.serviceResponseProgress = false;
  }
}
