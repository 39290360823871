import { Component, inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileViewModel } from '../../profile.viewModel';

@Component({
  selector: 'app-copyprofiledialog',
  templateUrl: './copyprofiledialog.component.html',
  styleUrl: './copyprofiledialog.component.scss'
})
export class CopyprofiledialogComponent {
  serviceResponseProgress = false;
  @Input() data:any
  activeModal = inject(NgbActiveModal);
  userId: string;
  selectedProfileName: string;
  selectedBookletName:string
  selectedProfileId: number;
  selectedBookletId:number

  constructor(   private fb: FormBuilder, private vm:ProfileViewModel) {
        this.vm.copyprofiledialogComponent = this
}

ngOnInit() {
         this.userId = localStorage.getItem('bfs_buyerprofile_uid');
        this.selectedProfileName = this.data.selectedProfileName;
        this.selectedBookletName = this.data.selectedBookletName;
        this.selectedProfileId = this.data.selectedProfileId;
        this.selectedBookletId = this.data.selectedBookletId
 }

eventCallYes() {
  this.serviceResponseProgress = true;

  const request = { created_by: Number(this.userId), bklt: this.selectedBookletId };
  this.vm.copyProfile(String(this.selectedProfileId), request) 
  
}

eventCallNo() {
  this.activeModal.close('modal closed')
  this.serviceResponseProgress = false;
}
}
