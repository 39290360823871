export class ProfileListModel {
    id: number;
    fullname: string;
    region: RegionModel
    company_name: string;
    booklet_name: string;
    job_title: string;
    image: string;
    is_booklet_masking: boolean;
}

export class ProfilePaginatedListModel {
    count: number;
    next: string;
    previous: string;
    results: [ProfileListModel];
}

export class ProfileSearchModel {
    deep_search:boolean
    string:string
    cm_name:string
    j_title: string
    client: number
    industry:number
    region:number
    crt_month:number
    crt_year:number
}


export class RegionModel {
    id: number
    name: string
  }
export class SimpleModal {
    id: number
    name: string
  }
 

export class ProfileModel {
    id: number;
    booklet_id: number;
    created_by: number;
    created_at: Date;
    modified_by: number;
    modified_at: Date;
    fullname: string;
    company_name: string;
    image: string;
    job_title: string;
    email_address: string;
    company_website: string;
    company_address: string;
    linkedin_url: string;
    contact_no: string;
    job_role: string;
    tech_stack: string[];
    funded_initiative: string[];
    technologies_interest: string[];
    topics_interest: string[];
    strategic_priorities: string[];
    industry:number;
    industry_name:string;
    region:SimpleModal[]
    profile_extras: ProfileExtrasModel;
    custom_fields:ProfileCustomFields[];
}

export class ProfileCompositeModel {
    id: number;
    profile_header: string;
    booklet_id: number;
    created_by: number;
    created_at: Date;
    modified_by: number;
    modified_at: Date;
    fullname: string;
    company_name: string;
    image: string;
    job_title: string;
    email_address: string;
    company_website: string;
    company_address: string;
    linkedin_url: string;
    contact_no: string;
    job_role: string;
    tech_stack: [];
    funded_initiative: [];
    technologies_interest: [];
    topics_interest: [];
    strategic_priorities: []
    profile_extras: ProfileExtrasModel;
    
}


export class ProfileExtrasModel {
    annual_company_budget: string;
    company_annual_revenue: string;
    id: number;
    booklet: {};
    investment_plans: string;
    number_of_employees: string;
    primary_business_activity: string;
    profile: number
    prospect_name: string;
    profile_image: string;
    scopeof_it_responsibility: string;
}
export class ProfileCustomFields {
    header: string;
    value:string;
}