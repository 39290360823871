import { Injectable } from "@angular/core";
import { DfFormModalComponent } from "./df-form-modal/df-form-modal.component";
import { DeskFeedbackModel } from "./desk-feedback-model";
import { IApiServiceDelegate } from "../model/desk-feedback.model";
import { ToastService } from "../services/toast.service";
import { APILIST } from "../services/api-list";
import { ApiService } from "../services/api.service";


@Injectable({
    providedIn: "root",
})


export class DeskFeedbackViewModel {


    parentFormComponent: DfFormModalComponent;

    constructor(private apiService: ApiService,public toastService: ToastService) { }


    submitFeedback(payload: DeskFeedbackModel){
        let d: IApiServiceDelegate = {
            Data: payload,
            Method: "POST",
            Action: APILIST.FEEDBACK,
            Success: (e: any) => {
                this.toastService.show('Feedback submitted successfully', 'success');
                this.parentFormComponent.feedbackSubmitted();
                return e;
            },
            Error: (e: String) => {
                this.toastService.show('Error while submitting feedback', 'danger');
                return e;
            },
        };
        this.apiService.callDeskFeedbackService(d);
    }
}