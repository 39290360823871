<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>
 
      <!-- Modal Header -->
      <div class="modal-header ">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h5 class="modal-title m-0" id="exampleModalLabel">{{ data.bookletTitle }}</h5>
            <!-- Optional event date display -->
            <p class="m-0" style="font-size: 15px;">Event Date - {{ data.eventDate | date: 'mediumDate' }}</p>
          </div>

          <div class="d-flex">
            <button type="button" class="btn btn-outline-secondary me-2" (click)="eventCallSkip()">Skip</button>
            <button type="button" class="btn btn-secondary" style="width: 120px;" (click)="eventCallSubmit()">Submit</button>
          </div>
        </div>
      </div>

      <!-- Modal Body -->
      <div class="modal-body">
        <!-- Images Section -->
        <div class="mb-3">
          <img [src]="data.cover" class="img-fluid" style="max-height: 1451px; width: 100%;" />
        </div>
        <div class="mb-3">
          <img [src]="data.profileHeader" class="img-fluid" style="max-height: 226px; width: 100%;" />
        </div>
        <div class="mb-3" *ngIf="data.bookletType === 'CS'">
          <img [src]="data.backCoverImage" class="img-fluid" style="max-height: 1451px; width: 100%;" />
        </div>

         
      </div>

      <!-- Modal Footer (Optional if needed) -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="eventCallSkip()" data-bs-dismiss="modal">Close</button>
      </div>
     