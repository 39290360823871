// app.module.ts
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AsyncPipe, CommonModule } from '@angular/common';
import { RouterOutlet, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './shared/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule, NgbAlertModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BookletComponent } from './booklet/booklet.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ProfileComponent } from './profile/profile.component';
import { StatsComponent } from './stats/stats.component';
import { FrozenItemComponent } from './frozen-item/frozen-item.component';
import { GetFirstName } from './pipe/allpipes.pipe';
import { BookletCompositeComponent } from './booklet/booklet-composite/booklet-composite.component';
import { BookletDetailsComponent } from './booklet/booklet-details/booklet-details.component';
import { UploadBookletDialogComponent } from './booklet/booklet-modals/upload-booklet-dialog/upload-booklet-dialog.component';
import { PreviewBookletComponent } from './booklet/booklet-modals/preview-booklet/preview-booklet.component';
import { profileBookletEditBeforeExportComponent } from './booklet/booklet-modals/profileBookletEditBeforeExportComponent/profile-booklet-edit-before-export.component';
import { FreezeBookletComponent } from './booklet/booklet-modals/freeze-booklet/freeze-booklet.component';

import { EditBookletDialogComponent } from './booklet/booklet-modals/edit-booklet-dialog/edit-booklet-dialog.component';
import { DeleteBookletComponent } from './booklet/booklet-modals/delete-booklet/delete-booklet.component';
import { AddRegionComponent } from './booklet/booklet-modals/add-region/add-region.component';
import { AddClientComponent } from './booklet/booklet-modals/add-client/add-client.component';
import { routes } from './app.routes';
import { AddProfileComponent } from './shared/add-edit-profile/add-edit-profile.component';
import { AddBookletComponent } from './shared/add-edit-booklet/add-edit-booklet.component';
import { LoginComponent } from './login/login.component';
import { FreezeProfileComponent } from './profile/profile-modals/freeze-profile/freeze-profile.component';

import { ProfileCompositeComponent } from './profile/profile-composite/profile-composite.component';
import { LoginService } from './services/login.service';
import { DisplayToastMsgComponent } from './shared/display-toast-msg/display-toast-msg.component';
import { ProfileDetailsComponent } from './profile/profile-details/profile-details.component';
import { DeleteProfileComponent } from './profile/profile-modals/delete-profile/delete-profile.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { CopyprofiledialogComponent } from './profile/profile-modals/copyprofiledialog/copyprofiledialog.component';
import { RestoreBookletProfileComponent } from './frozen-item/restore-booklet-profile/restore-booklet-profile.component';

import { CopyProfileComponent } from './shared/copy-profile/copy-profile.component';
import { AuthGurdService } from './services/auth-guard.service';
import { DeskFeedbackComponent } from './desk-feedback/desk-feedback.component';
import { DfSelectionModalComponent } from './desk-feedback/df-selection-modal/df-selection-modal.component';
import { DfFormModalComponent } from './desk-feedback/df-form-modal/df-form-modal.component';
import { NoDataFoundComponent } from './shared/no-data-found/no-data-found.component';
// import { ChartModule } from '@angular/chartjs';

@NgModule({
  declarations: [
    AppComponent, 
    BookletComponent, 
    HeaderComponent, 
    BookletDetailsComponent, 
    UploadBookletDialogComponent, 
    AddBookletComponent,
    ProfileComponent, 
    StatsComponent, 
    FrozenItemComponent, 
    GetFirstName, 
    BookletCompositeComponent, 
    AddRegionComponent, 
    AddProfileComponent,
    PreviewBookletComponent, 
    profileBookletEditBeforeExportComponent, 
    FreezeBookletComponent, 
    AddClientComponent,
    EditBookletDialogComponent, 
    DeleteBookletComponent, 
    FreezeProfileComponent, 
    LoginComponent, 
    FreezeProfileComponent, 
    CopyProfileComponent,
    ProfileCompositeComponent,
    DisplayToastMsgComponent,
    ProfileDetailsComponent,
    DeleteProfileComponent,
    LoadingComponent,
    CopyprofiledialogComponent,
    RestoreBookletProfileComponent,
    DfSelectionModalComponent,
    DfFormModalComponent,
    DeskFeedbackComponent,
    NoDataFoundComponent
  
  ],  // Declare components
  imports: [
    RouterModule.forRoot(routes), // Configure routing with your routes
    BrowserModule,
    HttpClientModule,
    RouterOutlet,
    FontAwesomeModule,
    CommonModule,
    FontAwesomeModule,
    NgbNavModule,
    NgbAlertModule,
    CommonModule,
    AsyncPipe,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    InfiniteScrollDirective,
    NgbToastModule
  ],
  providers: [LoginService, AuthGurdService],
  bootstrap: [AppComponent]  // Bootstrap the root component
})
export class AppModule { }
