<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>

<div class="container px-0 bg-body-tertiary" >
    <div class="row p-4  d-flex justify-content-center">

       <div class="col-3">
        <h6 class="text-muteed small text-primary"><i class="bi bi-calendar3 text-primary fw-600 me-1"></i>Year</h6>
        <div class="input-group">
            <!--    -->
            <ng-select class="rounded-pill cutomSelect"  [clearable]="false"
            [(ngModel)]="statsChartFilter.crt_year"
             placeholder="Select Year"
             (ngModelChange)="yearFilterClicked($event)"
             >    
                <ng-option *ngFor="let years of yearChoices"  [value]="years"> 
                    {{ years }}
                </ng-option>
            </ng-select>
       </div>
           
        </div>


        <div class="col-3">
            <h6 class="text-muteed small text-primary"><i class="bi bi-justify text-primary fw-600 me-1"></i>Event Type</h6>
            <div class="btn-group" role="group" aria-label="Basic outlined example">
                <button type="button" class="btn btn-outline-primary filter-btn"  [class.rounded-end-pill]="i==1"
                (click)="typeFilterClicked(event.id)" [class.rounded-start-pill]="i==0"
                [class.active]="event.id == statsChartFilter.bklt_type" 
                *ngFor="let event of typeChoices ;let i =index">{{event.name}}</button>
              </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <div class="chart-wrapper">
                <canvas id="canvas">{{chart}}</canvas>
                <p class="small mt-3 text-center fst-italic">Showing total number of profiles on a year</p>
              </div>
        </div>
    </div>
</div>