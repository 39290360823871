/* src/app/booklet/booklet-modals/add-region/add-region.component.scss */
::ng-deep .cutomSelect .ng-dropdown-panel {
  left: 0px;
}
::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background: #fff;
  top: 7px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid #dee2e6;
  padding: 6px 10px;
  text-align: start;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #c3c6c9;
}
::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}
/*# sourceMappingURL=add-region.component.css.map */
