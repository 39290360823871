<!-- Container with custom background -->
 
    <div class="modal-header d-flex justify-content-between align-items-center">
        <p class="fs-4 mb-0 text-primary">Edit Booklet</p>
        <div>
            <button class="btn btn-sm btn-secondary me-2 p-2" (click)="eventCallSkip()">Skip</button>
            <button class="btn btn-sm btn-primary p-2"   (click)="eventCallUpdate()">Update</button>
        </div>
    </div>
 

<!-- Main dialog content -->
<div class="modal-body">
    <div class="container">
        <form *ngIf="newBookletForm" [formGroup]="newBookletForm">
            <!-- Booklet Title and Event Date -->
            <div class="row mb-3">
                <div class="col-8">
                    <label for="bookletTitle" class="form-label">Booklet Title* (Max 50 Chars)</label>
                    <input type="text" class="form-control" id="bookletTitle" formControlName="bookletTitle">
                </div>
                <div class="col-4">
                    <label for="eventDate" class="form-label">Event Date*</label>
                    <input type="date" class="form-control" id="eventDate" formControlName="eventDate" autocomplete="off">
                </div>
            </div>

            <!-- Booklet Cover Upload -->
            <div class="card mb-3">
                <div class="card-body text-center">
                    <img [src]="bookletCoverEncoded" class="img-fluid mb-3" style="cursor: pointer; max-height: 500px;" (click)="chooseBookletCover.click()">
                    <input (change)="uploadBookletCover($event)" #chooseBookletCover type="file" style="display: none;">
                </div>
            </div>

            <!-- Profile Header Upload -->
            <div class="card mb-3">
                <div class="card-body text-center">
                    <img [src]="profileHeaderEncoded" class="img-fluid mb-3" style="cursor: pointer; max-height: 200px;" (click)="chooseProfileHeader.click()">
                    <input (change)="uploadProfileHeader($event)" #chooseProfileHeader type="file" style="display: none;">
                </div>
            </div>

            <!-- Booklet Back Cover Upload (Conditional) -->
            <div class="card mb-3" *ngIf="bookletInit.type === 'CS'">
                <div class="card-body text-center">
                    <img [src]="bookletBackcoverEncoded" class="img-fluid mb-3" style="cursor: pointer; max-height: 500px;" (click)="chooseBookletBackCover.click()">
                    <input (change)="uploadBookletBackCover($event)" #chooseBookletBackCover type="file" style="display: none;">
                </div>
            </div>
        </form>
    </div>
 
