/* src/app/booklet/booklet.component.scss */
.card-img-top {
  width: 200px;
  height: auto;
}
.card-text {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
::ng-deep .cutomSelect {
  width: 100%;
  border: none;
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: var(--bs-border-radius-pill) !important;
  background-color: #cfd1f9;
  padding: 10px;
  line-height: 1 !important;
  font-size: 13px;
}
::ng-deep .cutomSelect.ng-select .ng-arrow-wrapper {
  width: 16px !important;
  margin-right: 11px;
}
::ng-deep .cutomSelect .ng-dropdown-panel {
  left: 0px;
}
::ng-deep .ng-select-disabled {
  background-color: #bec0e5 !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background-color: #DBDDFF !important;
  top: 11px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  width: 94%;
  left: 10px;
  padding: 8px 0px;
  height: 100px;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid #dee2e6;
  padding: 6px 10px;
  text-align: start;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #f3f3f3;
}
::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}
::ng-deep .table tr td {
  padding-top: 8px;
}
.form-control {
  font-size: 13px !important;
}
.custom-input {
  background-color: #cfd1f9 !important;
  height: 34px;
}
.mainContent ::ng-deep .ng-select {
  height: 36px !important;
  background: #fff !important;
  padding: 14px;
}
.mainContent ::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background: #fff !important;
  top: 7px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  width: 94%;
  left: 6px;
  padding: 0px;
}
.mainContent ::ng-deep .ng-select .ng-clear-wrapper {
  top: -1px !important;
}
table ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container,
table ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-size: 12px !important;
}
table .mainContent ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value::before {
  color: #26ADE8 !important;
  content: "\f1dd";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  padding-right: 10px;
}
table .mainContent ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow::before {
  color: #26ADE8 !important;
  content: "\f4fa";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  padding-right: 10px;
}
::ng-deep .table tr td h6 {
  font-size: 14px;
}
::ng-deep .table tr td p {
  font-size: 13px;
}
.preview-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: solid 0.5px #ccc;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  margin-bottom: 0;
  opacity: 1;
  overflow: hidden;
  padding: 6px;
  position: relative;
  text-align: left;
  top: 0;
  vertical-align: center;
  white-space: nowrap;
  width: 40px;
  color: #fff !important;
  background-color: #022253 !important;
}
.preview-btn:hover {
  width: 182px;
}
.preview-btn i {
  width: 32px !important;
}
.sidebar-filter-btn.btn-outline-primary:hover {
  background-color: #DBDDFF !important;
}
.sidebar-filter-btn.btn-outline-primary {
  color: #022253 !important;
  font-weight: 600;
  border-color: #C2C5EE !important;
}
.sidebar-filter-btn.btn-outline-primary.active {
  background-color: #cfd1f9 !important;
  color: #022253 !important;
  font-weight: 600;
  border-color: #C2C5EE !important;
}
.bi {
  color: #26ADE8;
}
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container,
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  height: 13px !important;
}
::ng-deep .ng-select .ng-select-container {
  height: 14px !important;
}
/*# sourceMappingURL=booklet.component.css.map */
