 
  <div class="modal-header">
    <h4 class="modal-title">Associate Region*</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
  
  <div class="modal-body">
    <div class="container">
  
      <ng-container>
        <!-- If not creating a client -->
        <div  class="d-flex justify-content-between align-items-center">
          <div class="mb-3 w-100">
            <label for="clientSelect" class="form-label">Select a region</label>
            <ng-select [(ngModel)]="selectedRegion" [items]="regionList.value" class="form-select ms-2 cutomSelect  " bindLabel="name" bindValue="id">
            </ng-select>
          </div>
  

        </div>
  
         
      </ng-container>
  
    </div>
  </div>
  
  <div class="modal-footer justify-content-center">
    <button  class="btn btn-secondary" (click)="closeDialogWithData()">Save</button>
    <button class="btn btn-primary" (click)="closeDialog()">Close</button>
  </div>