import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileViewModel } from '../../profile/profile.viewModel';

@Component({
  selector: 'app-restore-booklet-profile',
  templateUrl: './restore-booklet-profile.component.html',
  styleUrl: './restore-booklet-profile.component.scss'
})
export class RestoreBookletProfileComponent {
  serviceResponseProgress = true;
	activeModal = inject(NgbActiveModal);
  @Input() data:any
  userId: string;
  selectedBookletName: string;
  selectedBookletId: number;
  selectedProfileName: string;
  selectedProfileId: number;
  selectedItemName:number;
  selectedItemId:number;

  constructor( private vm : ProfileViewModel) {
    this.vm.restoreBookletProfileComponent = this
    this.userId = localStorage.getItem('bfs_buyerprofile_uid');
   
  
}

ngOnInit(): void {

  if(this.data.type == 'Profile'){
    this.selectedBookletName = this.data.selectedBookletName;
    this.selectedBookletId = this.data.selectedBookletId;
    this.selectedProfileName = this.data.selectedProfileName;
    this.selectedProfileId = this.data.selectedProfileId;
  } else if(this.data.type == 'Booklet'){
    this.selectedItemName = this.data.selectedItemName;
    this.selectedItemId = this.data.selectedItemId;
  }
}

eventCallYes() {
this.serviceResponseProgress = true;

const request = {created_by: Number(this.userId), bklt: this.selectedBookletId};
if(this.data.type == 'Profile'){
  this.vm.restoreProfile(String(this.selectedProfileId), request)
} else if(this.data.type == 'Booklet'){
  this.vm.restoreBooklet(String(this.selectedItemId), request)
}


}

eventCallNo() {
  this.serviceResponseProgress = false;
  this.activeModal.close('success')

 }
}
