<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>



<div class="container-fluid px-0 mt-0">
   <div class="row p-0 m-0">
    <div class="col-md-3 col-lg-3 p-0 sidebar   shadow-sm overflow-auto" style="background-color: #e4e5f7;">
      <div class="offcanvas-md offcanvas-end  shadow-sm  " tabindex="-1" id="sidebarMenu"
        aria-labelledby="sidebarMenuLabel">
        
        <div class="offcanvas-body d-md-flex flex-column   px-2 px-md-4    overflow-y-auto position-relative">
          <p class="fw-semibold small mt-3 text-primary"> Showing <span class="small text-gray">   {{profileResults?.length}} out of {{profileListModel.value.count}} </span> profiles.
            <span class="float-end mt-1 cursor-pointer" (click)="resetFilter()" style="font-size: 10px;"> Clear All  <i  class="bi bi-x-circle-fill me-0 text-danger d-inline-block"></i>  </span>
            </p>
          <ul class="nav flex-column mt-1 gap-3 mb-4">
             
            <li class="nav-item text-start">
              <h6 class=" small text-primary"><i class="bi bi-search text-primary fw-600 me-1"></i>Search Profile</h6>
              <div class="input-group"  >
                  <input type="text" class="form-control form-control-sm rounded-pill custom-input" placeholder="Search here.."  [(ngModel)]="profileSearchModel.string" (ngModelChange)="profileSearch($event)">
              </div>
               
            </li>
            <li class="nav-item text-start">
              <h6 class=" small text-primary"><i class="bi bi-search text-primary fw-600 me-1"></i>Company</h6>
              <div class="input-group"  >
                  <input type="text" class="form-control form-control-sm rounded-pill custom-input" placeholder="Search here.."  [(ngModel)]="profileSearchModel.cm_name" (ngModelChange)="onInputChange($event)">
              </div>
               
            </li>
            <li class="nav-item text-start">
              <h6 class=" small text-primary"><i class="bi bi-search text-primary fw-600 me-1"></i>Job Title</h6>
              <div class="input-group"  >
                  <input type="text" class="form-control form-control-sm rounded-pill custom-input" placeholder="Search here.."  [(ngModel)]="profileSearchModel.j_title" (ngModelChange)="onInputChange($event)">
              </div>
               
            </li>
            <li class="nav-item text-start">
              <h6 class="text-muteed small text-primary"><i class="bi bi-building text-primary fw-600 me-1"></i>Industry</h6>
              <div class="input-group">
                  <ng-select class="client-dropdown rounded-pill cutomSelect"   (ngModelChange)="onValueChange($event)" [(ngModel)]="profileSearchModel.industry" placeholder="Select industry">    
                      <ng-option *ngFor="let client of industryList.value "  [value]="client?.id"> 
                          {{ client?.name }}
                      </ng-option>
                  </ng-select>
                 
              </div>
            </li>
            <li class="nav-item text-start">
              <h6 class="text-muteed small text-primary"><i class="bi bi-building text-primary fw-600 me-1"></i>Client</h6>
              <div class="input-group">
                  <ng-select class="client-dropdown rounded-pill cutomSelect"   (ngModelChange)="onValueChange($event)" [(ngModel)]="profileSearchModel.client" placeholder="Select client">    
                      <ng-option *ngFor="let client of clientList.value "  [value]="client?.id"> 
                          {{ client?.name }}
                      </ng-option>
                  </ng-select>
                 
              </div>
            </li>
            <li class="nav-item text-start">
              <h6 class="text-muteed small text-primary"><i class="bi bi-globe2 text-primary fw-600 me-1"></i>Region</h6>
              <div class="input-group">
              <ng-select class="region-dropdown rounded-pill cutomSelect"   (ngModelChange)="onValueChange($event)" [(ngModel)]="profileSearchModel.region" placeholder="Select region">    
                      <ng-option *ngFor="let region of regionList.value"  [value]="region.id"> 
                          {{ region.name }}
                      </ng-option>
              </ng-select>
                 
              </div>
            </li>
            <li class="nav-item text-start">
              <h6 class="text-muteed small text-primary"><i class="bi bi-calendar3 text-primary fw-600 me-1"></i>Year</h6>
              <div class="input-group">
                  <ng-select class="year-dropdown rounded-pill cutomSelect"   [(ngModel)]="profileSearchModel.crt_year" (ngModelChange)="onValueChange($event, 'year')" placeholder="Select year">    
                      <ng-option *ngFor="let years of calendarYears"  [value]="years"> 
                          {{ years }}
                      </ng-option>
                  </ng-select>
                 
              </div>
            </li>
            <li class="nav-item text-start">
              <h6 class="text-muteed small text-primary"><i class="bi bi-calendar-month text-primary fw-600 me-1"></i>Month</h6>
              <div class="input-group">
                  <ng-select class="month-dropdown rounded-pill cutomSelect"  [disabled]="!profileSearchModel.crt_year" [(ngModel)]="selectedMonth" (ngModelChange)="monthChanged($event)" placeholder="Select month">    
                      <ng-option *ngFor="let month of calendarMonths"  [value]="month"> 
                          {{ month }}
                      </ng-option>
                  </ng-select>
                 
              </div>
            </li>

          </ul>
          
        </div>
       
      </div>
    </div>
      
       <!-- Main Content -->
       <main class="col-md-9  col-lg-9 p-0  bg-body-tertiary" style="background-color: #f2f2f2;">
    
           
        

         <!-- <div class="header py-3">
          
         </div> -->

           <!-- Booklet List -->
           <div class="row p-0 m-0"> 
               <div class="col-12 p-0">
                <div class="profile-table-wrapper" *ngIf="profileResults?.length > 0"
                      infiniteScroll  [fromRoot]="true"
                       [infiniteScrollDistance]="2" [infiniteScrollThrottle]="12" (scrolled)="onProfileScroll()"  [scrollWindow]="false">
                  
               <table class="table table-hover" >
                            <thead>
                              <tr> 
                              <th colspan="1" class="text-center"><i class="bi bi-list"></i> S/N</th>
                              <th colspan="1"><i class="bi bi-list"></i> Profile</th>
                              <th><i class="bi bi-list"></i> Job Title</th>
                              <th style="width:300px"><i class="bi bi-list"></i> Company</th>
                              <th><i class="bi bi-list"></i> Region</th>
                              <!-- <th> <i class="bi bi-list"></i>Masking</th> -->
                              <th class="text-center"><i class="bi bi-list"></i> Actions</th>
                            </tr>
                            </thead>
                           <tbody class="mainContent border-bottom border-top" >
                             <tr class=" bg-primary-light "  *ngFor="let profile of profileResults; let i=index;" >
                              <td class="text-center">{{i+1}}</td>
                               <td   conspan="2" style="cursor: pointer;">
                                  <a [routerLink]="['/profile', profile.id]" class="text-decoration-none"> <div class="d-flex">
                                    <img class="rounded-5 shadow-sm" width="25" height="25" [src]="profile.image" alt="">
                                    <div class="ms-1 p-1 text-primary text-nowrap fw-bold text-capitalize">
                                      {{profile.fullname}}
                                    </div>
                                  </div>
                                </a>
                               </td>
                               <!-- <td [routerLink]="['/profile', profile.id]" class="text-start " >
                                  
                                   <h6 class="m-0 text-primary">{{profile.fullname}}</h6>
                               
                               </td> -->
                               <td class="text-start text-nowrap"> 
                                <p class="m-0 text-muted small fst-italic pt-1" [title]="profile.job_title"><i class="bi bi-person-fill"></i> 
                                  <!-- {{profile.job_title}}   -->
                                  {{profile.job_title?.length > 30 ? profile.job_title.substring(0, 30) + '...' : profile.job_title}} 
                                </p>
                               </td>
                               <td class="text-start text-nowrap" style="width:300px"> 
                                <p class="m-0 pt-1 text-muted small fst-italic" [title]="profile.company_name"><i class="bi bi-buildings-fill"></i> {{profile?.company_name.length < 32 ? profile?.company_name : profile?.company_name.slice(0,32) + '...'}}
                                </p>
                               </td>
                               <td class="text-muted small text-nowrap" *ngIf="profile.region.length > 0">
                                <i class="bi bi-globe"></i> {{profile?.region[0]?.name}} 
                               </td>
                               <td class="text-muted small text-nowrap" *ngIf="profile.region.length == 0"><i class="bi bi-globe me-1"></i>  <span > N/A </span></td>
                              
                             
                               <td class="text-center">
                                  
                               
                                 <div class="btn-group  rounded-0">
                                   <button class="btn btn-light btn-sm rounded-pill me-1" title="Download profile" (click)="exportProfileDialog(profile.fullname, '/profile-composite', profile.id)" >
                                     <i class="bi bi-download  "></i>
                                   </button>
                                   <button class="btn btn-light btn-sm rounded-pill me-1" title="Copy profile" (click)="copyDialog(profile)">
                                    <i class="bi bi-copy " ></i></button>
                                   <!-- <div class="dropdown">

                                           <button class="btn btn-light btn-sm rounded-pill dropdown-toggle" id="dropdownMenuButton{{i}}"   type="button"
                                           data-bs-toggle="dropdown" aria-expanded="false">
                                           <i class="bi bi-share text-dark"></i>
                                           </button>
                                           <ul class="dropdown-menu"  [attr.aria-labelledby]="'dropdownMenuButton' + i"   >
                                           <li><a class="dropdown-item" href="javascript:void(0)" (click)="copyDialog(profile)">
                                               <i class="bi bi-copy text-warning me-2" ></i> Copy</a> </li>
                                           <li><a class="dropdown-item" href="javascript:void(0)"  (click)="freezeDialog(profile)">
                                               <i class="bi bi-archive-fill text-danger me-2"></i>Freeze</a>
                                           </li>
                                           </ul>
                                   </div> -->
                                 </div>
                               </td>
                             </tr>
                            
                             </tbody>
                       </table>
                      </div>
                        
                       <app-no-data-found [show]="profileResults  == undefined || profileResults?.length == 0 "></app-no-data-found>  
              
               
               </div>
             
              
           </div>

            
       </main>
   </div>
</div>
 
 
 