/* src/app/booklet/booklet-details/booklet-details.component.scss */
::ng-deep .table > :not(caption) > * > * {
  background-color: var(--bs-white);
}
::ng-deep .table tr td {
  text-align: left;
  vertical-align: middle;
  border-right: 1px solid lightgray !important;
  padding-left: 20px;
}
::ng-deep .table tr th {
  text-align: left;
  vertical-align: middle;
  padding-left: 20px;
  border-right: 1px solid lightgray !important;
}
::ng-deep .table tr td:last-child {
  border-right: 0px solid lightgray !important;
}
::ng-deep .table tr th:last-child {
  border-right: 0px solid lightgray !important;
}
::ng-deep .ng-select-disabled {
  background-color: #e2dddd !important;
}
::ng-deep .table tr td {
  text-align: left;
  vertical-align: middle;
  padding-left: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
.profile-table-wrapper {
  background-color: var(--bs-white);
  font-size: 13px;
  height: 86vh !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.profile-table-wrapper table {
  width: 100%;
  white-space: nowrap !important;
}
.profile-table-wrapper thead th {
  position: sticky;
  top: 0;
  background-color: rgb(239, 255, 253);
  z-index: 10;
}
.profile-table-wrapper td {
  top: 0;
  background-color: white;
  z-index: 10;
}
.bi {
  color: #26ADE8;
}
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container,
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  height: 13px !important;
}
::ng-deep .ng-select .ng-select-container {
  height: 14px !important;
}
/*# sourceMappingURL=booklet-details.component.css.map */
