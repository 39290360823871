export class BookletArchiveListModel {
    id: number;
    // tslint:disable-next-line: variable-name
    created_by: { id: number, name: string };
    // tslint:disable-next-line: variable-name
    created_at: Date;
    // tslint:disable-next-line: variable-name
    organized_at: Date;
    title: string;
    type: string;
}

export class BookletArchivePaginatedListModel {
    count: number;
    next: string;
    previous: string;
    results: [BookletArchiveListModel];
}

export class ProfileArchiveListModel {
    id: number;
    fullname: string;
    // tslint:disable-next-line: variable-name
    company_name: string;
    // tslint:disable-next-line: variable-name
    booklet_name: string;
    // tslint:disable-next-line: variable-name
    job_title: string;
    image: string;
}

export class ProfileArchivePaginatedListModel {
    count: number;
    next: string;
    previous: string;
    results: [ProfileArchiveListModel];
}