import { Component, inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BookletViewModel } from '../../../booklet/booklet.viewModel';
import { ProfileViewModel } from '../../profile.viewModel';

@Component({
  selector: 'app-freeze-profile',
  templateUrl: './freeze-profile.component.html',
  styleUrl: './freeze-profile.component.scss'
})
export class FreezeProfileComponent {
  serviceResponseProgress = false;
  @Input() data:any
  activeModal = inject(NgbActiveModal);
  userId: string;
  selectedItemName: string;
  selectedItemId: number;

  constructor(   private fb: FormBuilder, private vm:ProfileViewModel) {
        this.vm.freezeProfileComponent = this
}

ngOnInit() {
         this.userId = localStorage.getItem('bfs_buyerprofile_uid');
        this.selectedItemName = this.data.selectedItemName;
        this.selectedItemId = this.data.selectedItemId;
 }

eventCallYes() {
  this.serviceResponseProgress = true;

  const request = {created_by: Number(this.userId)};
  this.vm.archiveProfile(String(this.selectedItemId), request)
  
}

eventCallNo() {
  this.activeModal.close('modal closed')
  this.serviceResponseProgress = false;
}

}
