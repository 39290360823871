 
// export const StatsColors = [
//   'rgba(193, 1, 26)',
//   'rgba(92, 92, 92)',
//   'rgba(193, 1, 26)',
//   'rgba(92, 92, 92)',
//   'rgba(193, 1, 26)',
//   'rgba(92, 92, 92)',
//   'rgba(193, 1, 26)',
//   'rgba(92, 92, 92)',
//   'rgba(193, 1, 26)',
//   'rgba(92, 92, 92)',
//   'rgba(193, 1, 26)',
//   'rgba(92, 92, 92)'
// ]

export const StatsChartModel = {
  type: 'bar',
  data: {
    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    datasets: [
      {
        label: '# of profiles',
        data: [],
        borderWidth: 1,
        // backgroundColor: StatsColors,
        // borderColor: StatsColors, 
        maxBarThickness: 25
      },
    ],
  },
  options: {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
};



export class StatsChartFilters {
  crt_year: number
  bklt_type: string
}