export class APILIST  {
// user
static GET_USER_BY_ID = 'user/:userId'
static USER_LOGIN = 'user/login'

//booklet
static BOOKLET = 'booklet'
static BOOKLET_BY_ID = 'booklet/:bookletId'
static UPLOAD_BOOKLET = 'profile-bulk-upload?user='
static ARCHIVE_BOOKLET = 'booklet-archive'
static ARCHIVE_BOOKLET_BY = 'booklet/:bookletId/archive_booklet'
static RESTORE_ARCHIVE_BOOKLET = 'booklet-archive/:bookletId/restore_booklet'
static ASSOCIATE_CLIENT = 'booklet/add_to_client'
static REMOVE_CLIENT = 'booklet/:ID/remove_from_client'
static BOOKLET_PREVIEW = 'booklet-composite/'
static BOOKLET_DOWNLOAD_PDF = 'booklet-composite/:bookletId/export_custom_booklet'
static BOOKLET_DOWLOAD_EXCELL = 'booklet-composite/download_booklet'

//profile
static PROFILE = 'profile'
static PROFILE_BY_ID = 'profile/:profileId'
static PROFILE_EXTRA = 'profile-extras'
static PROFILE_COPY = 'profile/:profileId/copy_profile'
static PROFILE_ARCHIVE = 'profile-archive'
static PROFILE_ARCHIVE_BY = 'profile/:profileId/archive_profile'
static PROFILE_RESTORE = 'profile-archive/:profileId/restore_profile'
static PROFILE_PREVIEW = 'profile-composite/:profileId'
static PROFILE_DOWNLOAD_PDF = 'profile-composite/:profileId/export_custom_profile'
static PROFILE_DUPLICATE = 'profile-duplicate/check_profile_duplicate'

// other
static CLIENT = 'client'
static INDUSTRY = 'industry'
static REGION = 'region'
static FEEDBACK = '/external_feedback/submit'

//stats
static PROFILE_MONTHLY_STATS = 'stats-profile-monthly'
static STATS_LIST = 'profile/stat_prio_list'
static FUNDED_LIST = 'profile/funded_initiative_list'
static JOB_LIST ='profile/job_role_list'
static TECH_LIST = 'profile/tech_stack_list'

}