import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { LoginModel } from '../../login/login.model';
import { filter } from 'rxjs';

@Component({
  selector: 'app-header',
  standalone: false,
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit{
  userProfile: LoginModel;
  isLoggedIn: boolean = false;

  constructor(private router:Router, private loginService: LoginService){
    this.updateUserLoginInfo()
  }

  ngOnInit(): void {
    this.loginService.loginMasterEvent.subscribe(res => {
      if(res == true){
        this.updateUserLoginInfo();
      }
    })
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd) // Only react to NavigationEnd events
    ).subscribe((event: NavigationEnd) => {
      this.checkRoute(event.urlAfterRedirects);
    });
  }

  private checkRoute(url: string): void {
    // Check if the current URL matches the specified patterns
    const currentRoute = this.router.url; 
    
    // if (currentRoute === '/profile' || currentRoute == '/booklet' || currentRoute === '/frozen' || currentRoute === '/booklet/'  ) {
    //   document.getElementById('main')?.classList.add('allow-scroll');
    // } else {
    //   document.getElementById('main')?.classList.remove('allow-scroll');
    // }
    this.router.events.subscribe(() => {
      const currentRoute = this.router.url;
      const segments = currentRoute.split('/').filter(Boolean); // Split route into segments

      // Check for '/profile', '/frozen', '/booklet', or '/booklet/:id'
      if (
        currentRoute === '/profile' ||
        currentRoute === '/frozen' ||
        (segments[0] === 'booklet' && segments.length === 1) || 
        (segments[0] === 'booklet' && segments.length === 2 && !isNaN(+segments[1]))
      ) {
        document.getElementById('main')?.classList.add('allow-scroll');
      } else {
        document.getElementById('main')?.classList.remove('allow-scroll');
      }
    });
 
  }
  
  updateUserLoginInfo(){
    const storedUser = this.loginService.getLoggedInUserProfile();
    console.log(storedUser )
    if (storedUser) {
      this.isLoggedIn = true;
      this.userProfile = storedUser;
    } else {
      this.isLoggedIn = false;
    }
  }

  logout(){
    this.isLoggedIn = false;
    this.loginService.clearLoggedInUserId();
    this.loginService.clearLoggedInUserProfile();
    this.loginService.updateLoginEventChanged();
    this.router.navigateByUrl('/login')
  }

  

}
