import { Injectable } from "@angular/core";
import { ApiService } from "../services/api.service";
import { IApiServiceDelegate } from "../model/desk-feedback.model";
import { APILIST } from "../services/api-list";
import { BehaviorSubject, Observer } from "rxjs";
import { ToastService } from "../services/toast.service";
import { StatsComponent } from "./stats.component";


@Injectable({
    providedIn: "root"
  })


export class StatsViewModel { 

    parentStatsComponent: StatsComponent; 

    statsDataResponse = new BehaviorSubject<[]>([]);

    constructor(private apiService: ApiService, public toastService: ToastService){
      
    }

    subscribeToStatsDataResponse(observer: Observer<[]>){
     this.statsDataResponse.subscribe(observer) 
    }
  

    getStats(crt_year, bklt_type) {
        var url = APILIST.PROFILE_MONTHLY_STATS + "?crt_year=" + crt_year + "&bklt_type=" + bklt_type;
        let d: IApiServiceDelegate = {
            Data: {},
            Method: "GET",
            Action: url,
            Success: (e: any) => {
            this.statsDataResponse.next(e);
            this.parentStatsComponent.dataReceived();
            return e;
            },
            Error: (e: String) => {
            return e;
            },
        };
        this.apiService.callService(d);
    }

}