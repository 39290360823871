<div class="modal-content" id="modalfeedback" (click)="clickOFF($event)">

<form [formGroup]="feedbackForm" (ngSubmit)="onSubmit($event)">

    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Submit Feedback</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal($event)"></button>
    </div>

    <div class="modal-body">

      <div class="row mb-3" *ngIf="finalImageBase64">
        <div class="col-12 d-flex justify-content-center mb-2">
          <img src="{{finalImageBase64}}" alt="" style="max-width: 300px; border: 1px solid grey; max-height: 150px;">
        </div>
      </div>


      <div class="row">

        <div class="col-12">
          <div class="mb-3">
            <label  class="form-label" for="name">Full Name *</label>
             
              <input  [disabled]="true"  type="text" id="name" class="form-control" ngbAutofocus
                formControlName="name" required />
              <div  *ngIf="form_name?.invalid && (form_name?.dirty || form_name?.touched)">
                Full name is required
              </div>
              <div class="text-success mt-1 small  " *ngIf="form_name?.valid && (form_name?.dirty || form_name?.touched)">
                Looks good!
              </div>
             
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label  class="form-label" for="email">Your Official Email *</label>
            
              <input  [disabled]="true"  type="email" id="email" class="form-control"
                formControlName="email" required />
              <div  *ngIf="form_email?.invalid && (form_email?.dirty || form_email?.touched)">
                A valid email address associated with buyerforesight.com is necessary for completing the feedback.
              </div>
              <div class="text-success mt-1 small  " *ngIf="form_email?.valid && (form_email?.dirty || form_email?.touched)">
                Looks good!
              </div>
            
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label   class="form-label" for="title">Feedback Title *</label>
             
              <input  type="text" id="title" class="form-control"
                formControlName="title" required />
              <div  *ngIf="form_title?.invalid && (form_title?.dirty || form_title?.touched)">
                Feedback title is required
              </div>
              <div class="text-success mt-1 small  " *ngIf="form_title?.valid && (form_title?.dirty || form_title?.touched)">
                Looks good!
              </div>
             
          </div>
        </div>


        <div class="col-12">
          <div class="mb-3">
            <label   class="form-label" for="title" style="margin-right: 20px;">Feedback Type *</label>


            <div class="form-check  form-check-inline">
              <input
                class="form-check-input "
                type="radio"
                name="type"
                
                id="inlineType1"
                [value]="1"
                [checked]="true"
              />
              <label class="form-check-label" for="inlineType1">Feature Required</label>
            </div>
            <div class="form-check  form-check-inline">
              <input
                class="form-check-input  "
                type="radio"
                id="inlineType2"
                 
                [value]="2"
                name="type"
              />
              <label class="form-check-label" for="inlineType2">Fix Required</label>
            </div> 

   
          </div>
        </div>




        

        <div class="col-12">
          <div class="mb-3">
            <label   class="form-label" for="comment">Feedback Description *</label>
             

              <textarea    formControlName="comment" 
              class="form-control" id="comment" rows="4" required></textarea>
 
              <div class="form-description-mesaage"
              *ngIf="form_comment?.invalid && (form_comment?.dirty || form_comment?.touched)">
                Feedback description is required
              </div>
              <div  class="form-description-mesaage text-success mt-1 small  "
              *ngIf="form_comment?.valid && (form_comment?.dirty || form_comment?.touched)">
                Looks good!
            </div>
             
          </div>
        </div>
 

      </div>

     
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeModal($event)">
        Close
      </button>
      <button type="button" class="btn btn-primary ms-auto" type="submit">Send</button>
    </div>


  </form>
    
  </div>