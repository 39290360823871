import { Component, inject, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
 
import { BookletPaginatedListModel, BookletSearchModel } from '../../model/booklet.model';
import { ProfileViewModel } from '../../profile/profile.viewModel';
import { CopyprofiledialogComponent } from '../../profile/profile-modals/copyprofiledialog/copyprofiledialog.component';
import { RestoreBookletProfileComponent } from '../../frozen-item/restore-booklet-profile/restore-booklet-profile.component';

@Component({
  selector: 'app-copy-profile',
  standalone: false,
  templateUrl: './copy-profile.component.html',
  styleUrl: './copy-profile.component.scss'
})
export class CopyProfileComponent {
  serviceResponseProgress = false;
  @Input() data:any
  activeModal = inject(NgbActiveModal);

  selectedProfileName: string;
  selectedProfileId: number;
  bookletInit: BookletPaginatedListModel;
  bookletResults= [];
 
  page=1;
  page_size:10;
  calendarMonths = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
  currentDate = new Date();
  currentMonth = this.currentDate.getMonth();
  currentYear = this.currentDate.getUTCFullYear();
  calendarYears = [this.currentYear - 2 ,this.currentYear - 1, this.currentYear, this.currentYear + 1,];
  private modalService = inject(NgbModal);
  selectedYear = 'Year';
  selectedMonthIndex = this.currentMonth;
  selectedMonth = this.calendarMonths[this.selectedMonthIndex].substring(0, 3);
  selectedBookletType = 'CS';
  conferences = [];
  bookletTypeLists = [{key: 'CS', value: 'Conference'}, {key: 'RT', value: 'Roundtable'}, {key: 'CA', value: 'Clients'}];
  bookletSearchModel:BookletSearchModel =
  {
    bklt_type:'CS',
    bklt_title: null,
    client: null,
    region:null,
    oz_month:this.currentMonth,
    oz_year:null
  }
  previousPageId: string;
  nextPageId: string;
  urlPart: string;
  pageNo:number = 1
  pageSize:number= 7

  constructor(   private fb: FormBuilder, private vm:ProfileViewModel) {
    this.vm.copyProfileComponent = this
    this.serviceResponseProgress = true
  }
  
  ngOnInit() {

    // this.userId = localStorage.getItem('bfs_buyerprofile_uid');
  
    this.selectedProfileName = this.data.selectedProfileName;
    this.selectedProfileId = this.data.selectedProfileId;
    this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
  }

   
  bookletListRecived(e:any){
    this.bookletInit = e
    this.bookletResults = e.results
   
    this.serviceResponseProgress = false
  }
  
    

filterYear(selectedYearValue: number) {
    this.bookletSearchModel.oz_year = String(selectedYearValue);
    this.selectedYear = String(selectedYearValue)
  }

filterMonth(selectedMonthValue: string) {
    this.selectedMonth = selectedMonthValue.substring(0, 3);
    this.selectedMonthIndex = this.calendarMonths.findIndex(x => x === selectedMonthValue);
    this.bookletSearchModel.oz_month = this.selectedMonthIndex
}

filterBooklet(selectedBookletValue: string) {
    this.selectedBookletType = selectedBookletValue;
    this.bookletSearchModel.bklt_type = this.selectedBookletType
    this.filterName()
}

filterName() {
  this.serviceResponseProgress = true
  this.bookletResults = []
  this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
}

applyBookletFilter() {
    this.serviceResponseProgress = true
    this.bookletResults = []
    this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
}

previousPage(reqParam: string) {
  if(reqParam != null){
    this.pageNo--
  }
  this.serviceResponseProgress = true
    this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
}

nextPage(reqParam: string) {
    if(reqParam != null){
      this.pageNo++
    }
    this.serviceResponseProgress = true
    this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
}

eventCallNo() {
  this.activeModal.close('modal closed')
}

eventCallYes(item:any){
  if(this.data.type=='Copy'){
    this.copyDialogMessage(item)
  } else if( this.data.type=='Restore'){
    this.restoreDialogMessage(item)
  }
}

copyDialogMessage(selectedBooklet: any) {
    const modalRef = this.modalService.open(CopyprofiledialogComponent);
    modalRef.componentInstance.data = { selectedBookletName: selectedBooklet.title, selectedBookletId: selectedBooklet.id,
      selectedProfileName: this.selectedProfileName, selectedProfileId: this.selectedProfileId };
   
    modalRef.result.then(
     (result) => {
      this.eventCallNo()
       console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
       console.log('Modal dismissed with reason:', reason);
     })
}

restoreDialogMessage(selectedBooklet: any) {
  const modalRef = this.modalService.open(RestoreBookletProfileComponent);
  modalRef.componentInstance.data = {type:'Profile', selectedBookletName: selectedBooklet.title, selectedBookletId: selectedBooklet.id,
    selectedProfileName: this.selectedProfileName, selectedProfileId: this.selectedProfileId };
 
  modalRef.result.then(
   (result) => {
     console.log('Modal closed with result:', result);
     this.eventCallNo()
   },
   (reason) => {
     // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
     console.log('Modal dismissed with reason:', reason);
   })
}
}
