<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>



<div class="container-fluid px-0 mt-0">
   <div class="row p-0 m-0">
    <div class="col-md-3 col-lg-3 p-0 sidebar  shadow-sm overflow-auto" style="background-color: #e4e5f7;">
      <div class="offcanvas-md offcanvas-end shadow-sm  " tabindex="-1" id="sidebarMenu"
        aria-labelledby="sidebarMenuLabel">
         
        <div class="offcanvas-body d-md-flex flex-column   px-2 px-md-4    overflow-y-auto ">
          
          <div class="btn-group mb-4 mt-3"> 
            <button class="btn btn-sm btn-outline-primary sidebar-filter-btn rounded-start-pill" [class.active]="selectedTabType == 'B'" id="Booklet" (click)="checkCurrentTab('B')"> Booklet </button>
             <button class="btn btn-sm btn-outline-primary sidebar-filter-btn rounded-end-pill" [class.active]="selectedTabType == 'P'" id="Profile" (click)="checkCurrentTab('P')">  Profile</button>
         </div>
     
         <div class="position-relative mb-2 " > 
          <p class="fw-semibold small text-primary" *ngIf="selectedTabType == 'B'">Showing  <span class="small text-gray">  {{bookletArchiveResults?.length}} of  {{bookletArchiveList.value.count}} Booklets. </span><span class="float-end mt-1 cursor-pointer" (click)="resetFilter()" style="font-size: 10px;"> Clear All  <i  class="bi bi-x-circle-fill me-1 text-danger d-inline-block"></i>  </span></p>
          <p class="fw-semibold small text-primary" *ngIf="selectedTabType == 'P'">Showing <span class=" small text-gray"> {{profileArchiveResults?.length}} of  {{profileArchiveList.value.count}} Profiles. </span><span class="float-end mt-1 cursor-pointer" (click)="resetFilter()" style="font-size: 10px;"> Clear All  <i  class="bi bi-x-circle-fill me-1 text-danger d-inline-block"></i>  </span></p>
           
         </div> 
          <ul class="nav flex-column mt-2 gap-3 mb-4">
            <!-- <li class="nav-item text-center">
              <button (click)="resetFilter()" class="btn btn-sm btn-secondary rounded-pill"> <i class="bi bi-x-circle-fill me-1 text-light "></i> Clear All </button>
            </li> -->
            <li class="nav-item text-start">
              <h6 class=" small text-primary" *ngIf="selectedTabType == 'P'"><i class="bi bi-search text-primary fw-600 me-1"></i>Search Profile</h6>
              <h6 class=" small text-primary" *ngIf="selectedTabType == 'B'"><i class="bi bi-search text-primary fw-600 me-1"></i>Search Booklet</h6>
              <div class="input-group"  >
                  <input type="text" class="form-control form-control-sm rounded-pill custom-input" placeholder="Search here.."  [(ngModel)]="frozenItemSearch.name" (ngModelChange)="onInputChange($event)">
              </div>
               
            </li>
            
            <li class="nav-item text-start">
              <h6 class="text-muteed small text-primary"><i class="bi bi-calendar3 text-primary fw-600 me-1"></i>Year</h6>
              <div class="input-group">
                  <ng-select class="year-dropdown rounded-pill cutomSelect"   [(ngModel)]="frozenItemSearch.selectedYear" (ngModelChange)="onValueChange($event, 'year')" placeholder="Select year">    
                      <ng-option *ngFor="let years of calendarYears"  [value]="years"> 
                          {{ years }}
                      </ng-option>
                  </ng-select>
                 
              </div>
            </li>
            <li class="nav-item text-start">
              <h6 class="text-muteed small text-primary"><i class="bi bi-calendar-month text-primary fw-600 me-1"></i>Month</h6>
              <div class="input-group">
                  <ng-select class="month-dropdown rounded-pill cutomSelect" [disabled]="!frozenItemSearch.selectedYear"  [(ngModel)]="selectedMonth" (ngModelChange)="monthChanged($event)" placeholder="Select month">    
                      <ng-option *ngFor="let month of calendarMonths"  [value]="month"> 
                          {{ month }}
                      </ng-option>
                  </ng-select>
                 
              </div>
            </li>

          </ul>

        </div>
      </div>
    </div>
      
       <!-- Main Content -->
       <main class="col-md-9  col-lg-9 p-0  bg-body-tertiary pt-2" style="background-color: #f2f2f2;">
    
           
        

         <!-- <div class="header py-3">
          
         </div> -->

         <div class="row p-0 m-0"> 
          <div class="col-12 pe-0">
          
             
            <div  style="height: 86vh !important; overflow-x: hidden; overflow-y: auto;" 
            infiniteScroll  [fromRoot]="true" *ngIf="selectedTabType == 'B'"
             [infiniteScrollDistance]="2" [infiniteScrollThrottle]="12" (scrolled)="onScroll('B')"  [scrollWindow]="false"> 

             <app-no-data-found [show]="!bookletArchiveResults || (bookletArchiveResults?.length === 0)"></app-no-data-found> 
              
                  <table class="table table-hover custom-table"   *ngIf="bookletArchiveResults?.length > 0">
     
                      <tbody class=" mainContent" >
                        <tr class="shadow-sm overflow-auto rounded bg-primary-light "  *ngFor="let booklet of bookletArchiveResults ;let i = index" >
                          <td  *ngIf="booklet.type == 'CS'" class="cursor-pointer">
                            <a class="text-decoration-none" [routerLink]="['/booklet', booklet.id]">  <img class="" width="35" src="../../assets/conference.png" alt=""> </a> 
                          </td>
                          <td  *ngIf="booklet.type == 'RT'" class="cursor-pointer">
                            <a [routerLink]="['/booklet', booklet.id]" class="text-decoration-none">  <img class="" width="35" src="../../assets/meeting-room.png" alt=""> </a>
                          </td>
                          <td [routerLink]="['/booklet', booklet.id]" class="cursor-pointer">
                            <div class="d-flex flex-column text-start">
                              <a [routerLink]="['/booklet', booklet.id]" class="text-decoration-none">    <h6 class="m-0 text-primary fw-semibold">{{ booklet.title }}</h6></a>
                              <a [routerLink]="['/booklet', booklet.id]" class="text-decoration-none"> <p class="m-0 text-muted small fst-italic"><i class="bi bi-person me-0"></i> <b class="me-3"> {{booklet.created_by.name}} </b> 
                              </p></a>
                            </div>
                          </td>
                          <td> 
                            <p class="m-0 text-muted small ">  {{booklet.created_at | date :'medium'}} </p>
                          </td>
                          <td>
                            
                            <div class="btn-group">
                              <button class="btn btn-light btn-sm rounded-pill me-1" (click)="restoreBookletDialog(booklet)">
                                <i class="bi bi-brilliance text-primary"></i> Restore
                              </button>
                             
                            </div>
                          </td>
                        </tr>
                       
                        </tbody>
                  </table>
            </div> 
            <div  style="height: 88vh !important; overflow-x: hidden; overflow-y: auto;" 
            infiniteScroll  [fromRoot]="true"    *ngIf="selectedTabType == 'P'"
             [infiniteScrollDistance]="2" [infiniteScrollThrottle]="12" (scrolled)="onScroll('B')"  [scrollWindow]="false"> 
            
             <app-no-data-found [show]="!profileArchiveResults || profileArchiveResults.length == 0"></app-no-data-found> 
              
                  <table class="table table-hover custom-table" *ngIf="profileArchiveResults?.length > 0"  >
     
                      <tbody class=" mainContent" >
                        <tr class="shadow-sm overflow-auto rounded bg-primary-light "  *ngFor="let profile of profileArchiveResults ;let i = index" >
                          <td  >
                              <img class="img-fluid rounded-5 shadow" width="50" [src]="profile.image" alt="">
                          </td>
                          
                          <td  width="800">
                            <div class="d-flex flex-column text-start">
                              <h6 class="m-0 text-primary fw-semibold">{{ profile.fullname }}</h6>
                              <p class="m-0 text-muted small fst-italic"> {{profile.job_title}} at <b> {{profile.company_name}} </b>
                              </p>
                              <p class="m-0 text-muted small fst-italic text-start"> Booklet: <b>{{profile.booklet_name.length > 0 ? profile.booklet_name :'NA'}}</b></p>
                            </div>
                          </td>
                           
                          <td>
                            
                            <div class="btn-group">
                              <button class="btn btn-light btn-sm rounded-pill me-1" (click)="restoreProfileDialog(profile)">
                                 
                                <i class="bi bi-brilliance text-primary"></i> Restore
                              </button>
                             
                            </div>
                          </td>
                        </tr>
                       
                        </tbody>
                  </table>
            </div> 
               
                
            <app-no-data-found [show]="profileArchiveResults?.length == 0 || bookletArchiveResults?.length == 0"></app-no-data-found>  
         
          
          </div>
           
         
      </div>

       </main>
   </div>
</div>
 
 
 