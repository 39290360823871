import { Component, inject, Input } from '@angular/core';
import { BookletModel } from '../../../model/booklet.model';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ToastService } from '../../../services/toast.service';

 
import { BookletViewModel } from '../../booklet.viewModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-preview-booklet',
  standalone: false,
  templateUrl: './preview-booklet.component.html',
  styleUrl: './preview-booklet.component.scss'
})
export class PreviewBookletComponent {
  serviceResponseProgress = true;
	activeModal = inject(NgbActiveModal);
  @Input() data:any
  userId: string | undefined;
  newBooklet!: BookletModel;
  newBookletForm!: FormGroup;
  
  constructor( private vm:BookletViewModel,private router: Router ){
    if(localStorage.getItem('bfs_buyerprofile_uid')){
      this.userId = localStorage.getItem('bfs_buyerprofile_uid')  ;
    }
    this.vm.previewBookletComponent = this
  }
   


  ngOnInit(): void { 
    setTimeout(()=>  { console.log(this.data) ;this.serviceResponseProgress = false } , 1000 )
  }

  eventCallSkip(){
    this.activeModal.close('modal closed')
  }

  eventCallSubmit() {
    this.serviceResponseProgress = true;
    this.newBooklet = new BookletModel();
    this.newBooklet.created_by = Number(this.userId);
    this.newBooklet.title = this.data.bookletTitle;
    this.newBooklet.organized_at =  this.toPythonDateFormat(new Date(this.data.eventDate)) 
    this.newBooklet.type = this.data.bookletType;
    this.newBooklet.header_image = this.data.cover;
    this.newBooklet.profile_header_image = this.data.profileHeader;
    this.newBooklet.client = this.data.client;
    this.newBooklet.region = this.data.region;
    this.vm.createBooklet(this.newBooklet);
  }
  
   toPythonDateFormat(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Return date in 'YYYY-MM-DD HH:MM:SS' format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

  bookletCreated(e:any){
    // console.log(e)
    this.router.navigateByUrl('/booklet/'+e.id)
    this.serviceResponseProgress = false;
    this.activeModal.close('modal closed')
  }
  
}
