<div style="z-index:1000" *ngIf="serviceResponseProgress" class="position-fixed bg-white top-0 start-0 bottom-0 end-0  w-100 opacity-50" >
    <div class="flex-column d-flex align-items-center justify-content-center align-middle h-100">
        <!-- <div class="spinner-border " role="status" aria-hidden="true"></div>
        <div > <strong>Loading...</strong></div> -->

        <div class="loading-wave">
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
          </div>
	

          <h5 class="mt-2 text primary">Loading...</h5>
    </div>
</div>