import { Component, inject, Inject, Input } from '@angular/core';
import { BookletModel } from '../../../model/booklet.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { BookletViewModel } from '../../booklet.viewModel';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-edit-booklet-dialog',
  standalone: false,
  templateUrl: './edit-booklet-dialog.component.html',
  styleUrl: './edit-booklet-dialog.component.scss',
  providers: [DatePipe]
})
export class EditBookletDialogComponent {
  serviceResponseProgress = false;
  @Input() data:any
  activeModal = inject(NgbActiveModal);
  userId: string;
  bookletInit: BookletModel;

  bookletCoverEncoded: string;
  profileHeaderEncoded: string;
  bookletBackcoverEncoded!: string;

  

  newBookletForm: FormGroup;
  updatedBooklet: BookletModel = new BookletModel();
  modifiedEventDate!: any;

    constructor( private fb: FormBuilder, private vm:BookletViewModel, private datePipe: DatePipe, public toastService: ToastService) {
        this.vm.editBookletDialogComponent = this
    }

    ngOnInit() { 
      this.bookletInit = this.data.selectedBookletData as BookletModel;
        this.userId = localStorage.getItem('bfs_buyerprofile_uid');
        this.bookletCoverEncoded = this.bookletInit.header_image;
        this.profileHeaderEncoded = this.bookletInit.profile_header_image;
        if (this.bookletInit.type === 'CS') {
          this.bookletBackcoverEncoded = this.bookletInit.footer_image;
        }
        console.log(this.bookletInit)
        this.createForm();
    }

    createForm() {
      this.newBookletForm = this.fb.group({
        bookletTitle: new FormControl(this.bookletInit.title, Validators.required),
        eventDate: new FormControl('', Validators.required)
      });
      const formattedDate = this.datePipe.transform(new Date(this.bookletInit.organized_at), 'yyyy-MM-dd');
      this.newBookletForm.get('eventDate')?.setValue(formattedDate);
    }



    public uploadBookletCover(event) {
      const fileList = event.target.files;
      const file = fileList[0];
      if (fileList && file) {
        const reader = new FileReader();
        reader.onload = (fileData) => {
          this.bookletCoverEncoded = String(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }

    public uploadProfileHeader(event) {
      const fileList = event.target.files;
      const file = fileList[0];
      if (fileList && file) {
        const reader = new FileReader();
        reader.onload = (fileData) => {
          this.profileHeaderEncoded = String(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }

    public uploadBookletBackCover(event) {
      const fileList = event.target.files;
      const file = fileList[0];
      if (fileList && file) {
        const reader = new FileReader();
        reader.onload = (fileData) => {
          this.bookletBackcoverEncoded = String(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }

    eventCallUpdate() {
      if (!this.newBookletForm.valid) {
        this.toastService.show('One or More madatory fields are missing!', 'success');
      
      } else {
        this.serviceResponseProgress = true;

        // tslint:disable-next-line: max-line-length
        // this.modifiedEventDate = new Date(this.newBookletForm.value.eventDate._d.setMinutes(this.newBookletForm.value.eventDate._d.getMinutes() + 330));
        this.modifiedEventDate = new Date(this.newBookletForm.value.eventDate).toISOString();
        // console.log(this.modifiedEventDate)
        this.updatedBooklet = this.bookletInit;
        this.updatedBooklet.modified_by = Number(this.userId);
        this.updatedBooklet.modified_at = new Date().toISOString();
        this.updatedBooklet.title = this.newBookletForm.value.bookletTitle;
        this.updatedBooklet.organized_at = String(this.modifiedEventDate);
        this.updatedBooklet.header_image = this.bookletCoverEncoded;
        this.updatedBooklet.profile_header_image = this.profileHeaderEncoded;
        if (this.bookletInit.type === 'CS') {
          this.updatedBooklet.footer_image = this.bookletBackcoverEncoded;
        }

        this.vm.updateBooklet(String(this.updatedBooklet.id), this.updatedBooklet)
      }
    }

    eventCallSkip() {
      this.activeModal.close('modal closed')
    }

    bookletEdited(){
      this.activeModal.close('modal closed')
    }

}
