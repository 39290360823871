<div class="form-signin w-100 m-auto rounded shadow">
  <form [formGroup]="loginForm">

    <div class="text-center p-3">
      <img src="../../../assets/svg/profiler-high-resolution-logo-transparent.svg" class="img-fluid"
        style="height: 100%;" alt="">
    </div>

    <h1 class="h3  mt-1 mb-4 fw-normal text-center text-muted">Please sign in</h1>

    <div class="form-floating">
      <input type="username" formControlName="username" class="form-control" id="username" placeholder="username">
      <label for="username">Username</label>
    </div>

    <div *ngIf="loginForm.get('username')!.invalid && (loginForm.get('username')!.dirty || loginForm.get('username')!.touched)"
      class="small text-danger mt-1">
      <div *ngIf="loginForm.get('username')!.errors?.['required']">Username is required.</div>
      <div *ngIf="loginForm.get('username')!.errors?.['minlength']">Username must be at least 3 characters long.</div>
    </div>


    <div class="form-floating mt-3">
      <input type="password" formControlName="password" autocomplete="off"  class="form-control" id="password" placeholder="Password">
      <label for="password">Password</label>
    </div>

    <div *ngIf="loginForm.get('password')!.invalid && (loginForm.get('password')!.dirty || loginForm.get('password')!.touched)"
    class="small text-danger mt-1">
    <div *ngIf="loginForm.get('password')!.errors?.['required']">Password is required.</div>
    <div *ngIf="loginForm.get('password')!.errors?.['minlength']">Password must be at least 3 characters long.</div>
  </div>


    <div class="form-check text-start my-3">
      <input class="form-check-input" type="checkbox" id="rememberMe" formControlName="rememberMe">
      <label class="form-check-label" for="rememberMe">
        Remember me
      </label>
    </div>
    <button type="submit" class="btn btn-primary w-100 py-2" [disabled]="!loginForm.valid" (click)="onSubmit()">Sign in</button>
    <p class="mt-5 mb-3 text-center text-body-secondary">BuyerForesight © 2024</p>
  </form>
</div>