/* src/app/desk-feedback/desk-feedback.component.scss */
#feedbackScreeMain {
  background: transparent;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  cursor: crosshair;
}
::ng-deep .modal-backdrop {
  pointer-events: none !important;
}
::ng-deep .modal {
  pointer-events: none !important;
}
#feedbackScreeSelection {
  position: absolute !important;
  border: 2px dotted #3c05d4 !important;
}
#feedbackScreeSelection.complete {
  border-color: #0af043 !important;
}
.feedback-btn-wrapper {
  overflow: hidden;
}
.feedback-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: solid 0.5px #ccc;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  margin-bottom: 0;
  opacity: 1;
  overflow: hidden;
  padding: 5px 7px;
  position: relative;
  text-align: left;
  top: 0;
  vertical-align: top;
  white-space: nowrap;
  width: 28px;
  color: #fff !important;
  background-color: #022253 !important;
}
.feedback-btn:hover {
  width: 90px;
}
/*# sourceMappingURL=desk-feedback.component.css.map */
