<div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 1200;">
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [autohide]="toast.autohide"
      [delay]="toast.delay || 5000"
      [class]="toast.classname"
      (hidden)="toastService.remove(toast)">
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>
  
      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
      <button type="button" class="btn-close ms-2 mb-1 float-end" aria-label="Close" (click)="toastService.remove(toast)"></button>
    </ngb-toast>
  </div>