import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors} from '@angular/forms';

import { DeskFeedbackModel } from '../desk-feedback-model'; 
import { DeskFeedbackViewModel } from '../desk-feedback-viewModel';
 
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { LoginService } from '../../services/login.service';
 
 
 

@Component({
  selector: 'app-df-form-modal',
  templateUrl: './df-form-modal.component.html',
  styleUrls: ['./df-form-modal.component.scss'],
  providers: [ApiService, DeskFeedbackViewModel]
})
export class DfFormModalComponent implements OnInit {

 @Input() skippedPicture: boolean;
 @Input() finalImageBase64: string;

  feedbackForm: FormGroup;
  
  constructor(
    private vm: DeskFeedbackViewModel,
    public modalRef: NgbActiveModal,
    private loginService: LoginService) {
      
  }

  ngOnInit(): void {
    this.vm.parentFormComponent = this;
    this.initFormFields();

    
    
  }

  initFormFields(){
    this.feedbackForm = new FormGroup({
      name: new FormControl(this.loginService.getLoggedInUserProfile().firstname +" "+ this.loginService.getLoggedInUserProfile().lastname   , [Validators.required, Validators.minLength(4)]),
      email: new FormControl(this.loginService.getLoggedInUserProfile().email_address, [Validators.required, Validators.minLength(4), this.bfEmailValidator()]),
      title: new FormControl('', [Validators.required, Validators.minLength(4)]),
      comment: new FormControl('', [Validators.required, Validators.minLength(4)]), 
      type: new FormControl(1, [Validators.required]), 
    })

  }

  bfEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      var domain = "@buyerforesight.com"
      const forbidden = !control.value?.includes(domain) || !(control.value.length > domain.length);
      return forbidden ? {bfEmailValidator: {value: control.value}} : null;
    };
  }

  get form_name(): AbstractControl  { return this.feedbackForm.get('name'); }
  get form_email(): AbstractControl  { return this.feedbackForm.get('email'); }
  get form_title(): AbstractControl  { return this.feedbackForm.get('title'); }
  get form_comment(): AbstractControl  { return this.feedbackForm.get('comment'); }
  get form_type(): AbstractControl  { return this.feedbackForm.get('type'); }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  clickOFF(e){
    e.stopImmediatePropagation()
  }

  onSubmit(e) {
    e.stopImmediatePropagation()
    if (this.feedbackForm.invalid) {
      this.validateAllFormFields(this.feedbackForm);
    } else {
      var newModel = new DeskFeedbackModel();
      newModel = this.feedbackForm.value;
      newModel.appName = 'BuyerProfile';
      if(!this.skippedPicture){
        newModel.screenshot = [this.finalImageBase64]
      }
      this.vm.submitFeedback(newModel);
    }
  }

  feedbackSubmitted(){
    this.initFormFields();
    this.closeModal(event);
  }

  closeModal(e): void {
    e.stopImmediatePropagation()
    this.modalRef.close(false);
  }

}
