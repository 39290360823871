import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BookletCompositeModel } from '../../model/booklet.model';
import { ActivatedRoute } from '@angular/router';
import { GetFirstName } from '../../pipe/allpipes.pipe'
import { BookletViewModel } from '../booklet.viewModel';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-booklet-composite',
  standalone: false,
 
  templateUrl: './booklet-composite.component.html',
  styleUrl: './booklet-composite.component.scss'
})
export class BookletCompositeComponent implements OnInit {
  serviceResponseProgress = true;
  srpColor = 'primary';
  srpMode = 'indeterminate';
  srpValue = 60;
  custom_ph:string   
  custom_email:string       
  bookletId: string  ;
  bookletName: string   ;
  custom_location!: string  ; 
  actionsSubscription: any;
  bookletCompositeInit : BookletCompositeModel  ; 
  bookletComposite = new BehaviorSubject<BookletCompositeModel>(new BookletCompositeModel())
  masking_profiles_param: string | undefined


  constructor(private appActiveRoute: ActivatedRoute,private vm:BookletViewModel, ) {
     this.vm.bookletCompositeComponent = this 
    
    }

  ngOnInit() {
    this.loadData()
   this.vm.subscribeBookletComposite(this.bookletComposite)
   this.vm.retrieveBookletCompositebyId(+this.bookletId)
 
  }

  bookletpreviewReceived( ){
    this.serviceResponseProgress = false;
    this.bookletCompositeInit = this.bookletComposite.value ;
  }

  downloadPDF() {
    this.serviceResponseProgress = true;
    const fileName = this.bookletCompositeInit.title + '.pdf';
    let payload = {
      'custom_ph':this.custom_ph,
      'custom_email':this.custom_email,
      'masking_profiles':this.masking_profiles_param
    }
    this.vm.downloadBookletCompositePDF(Number(this.bookletId), payload, fileName);
  }

  downloadExcell() {
    this.serviceResponseProgress = true;
    const fileName = this.bookletCompositeInit.title + '.xlsx';
    let payload = {
      'custom_ph':this.custom_ph,
      'custom_email':this.custom_email,
      'masking_profiles':this.masking_profiles_param
    }
    this.vm.downloadBookletCompositeExcell(Number(this.bookletId), payload, fileName);
  }

  loadData(){
    this.bookletId = this.appActiveRoute.snapshot.queryParamMap.get('bklt_id') || null ;
    this.bookletName = this.appActiveRoute.snapshot.paramMap.get('name') || null;
    // this.remove_client_logo = this.appActiveRoute.snapshot.queryParamMap.get('remove_client_logo') == null ? '' : this.appActiveRoute.snapshot.queryParamMap.get('remove_client_logo');
    this.custom_ph = this.appActiveRoute.snapshot.queryParamMap.get('custom_ph') || null 
    this.custom_email = this.appActiveRoute.snapshot.queryParamMap.get('custom_email') || null;
    this.custom_location = this.appActiveRoute.snapshot.queryParamMap.get('custom_location_address') || null;
    this.masking_profiles_param = this.appActiveRoute.snapshot.queryParams['masking_profiles'] || null;
  }

  downloadSuccess(){
    this.serviceResponseProgress = false;
  }

  downloadFailed(){
    this.serviceResponseProgress = false;
  }
}
