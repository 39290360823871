import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BookletViewModel } from '../../booklet.viewModel';

 

@Component({
  selector: 'app-delete-booklet',
  standalone: false,
  templateUrl: './delete-booklet.component.html',
  styleUrl: './delete-booklet.component.scss'
})
export class DeleteBookletComponent {
	activeModal = inject(NgbActiveModal);
  @Input() data:any
  serviceResponseProgress:boolean = false
  actionsSubscription: any;

  constructor(private vm:BookletViewModel,) { this.vm.deleteBookletComponent = this}
  
  ngOnInit(): void {
   
   
      // Handle the action here
      
  }

  deleteBooklet(){
    this.serviceResponseProgress = true; 
      // this.store.dispatch(BookletActions.deleteBooklet({id:this.data.selectedItemId}))
      this.vm.deleteBooklet(this.data.selectedItemId)
      this.serviceResponseProgress = true
  }

  closeIt(){
    this.serviceResponseProgress = false;
    this.activeModal.close('modal closed')
  }
}
