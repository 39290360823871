import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientModel, RegionModel, StandardModel, StandardResponseModal } from '../../../model/booklet.model';
 
 
 
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { BookletViewModel } from '../../booklet.viewModel';

@Component({
  selector: 'app-add-client',
  standalone: false,
  templateUrl: './add-client.component.html',
  styleUrl: './add-client.component.scss'
})

export class AddClientComponent {
  activeModal = inject(NgbActiveModal);
  @Input() data:any
  selectedClient:any
  makeClient:boolean = true
 
  form:FormGroup
  
  clientList = new BehaviorSubject<StandardModel[]>([])
  
  constructor( private vm:BookletViewModel, private fb: FormBuilder,) { this.vm.addClientComponent = this}

  ngOnInit(): void {
 
    this.vm.retrieveAllClients()
    this.vm.subscribeClientList(this.clientList)
    this.createForm()
  }

  createForm(){
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.form =  this.fb.group({
      clientName: ['',Validators.required],
      clientUrl: ['',Validators.pattern(reg)]
    });
  }

  createClient(){
    let formData = { name:'',url:'',created_by:1}
    formData.name = this.form.controls['clientName'].value
    formData.url = this.form.controls['clientUrl'].value
    formData.created_by =  +localStorage.getItem('bfs_buyerprofile_uid')
    this.vm.createClients(formData)
  }


  


  closeDialog(){
    this.activeModal.close('modal closed')
  }

  closeDialogWithData(){
    this.activeModal.close(this.selectedClient)
  }
}
