import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BookletModel, ClientModel, FileUploadKeys, RegionModel, StandardModel, StandardResponseModal } from '../../model/booklet.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 
 
import { PreviewBookletComponent } from '../../booklet/booklet-modals/preview-booklet/preview-booklet.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { BookletViewModel } from '../../booklet/booklet.viewModel';
import { AddClientComponent } from '../../booklet/booklet-modals/add-client/add-client.component';
import { ToastService } from '../../services/toast.service';
 


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-add-booklet',
  standalone: false,
  templateUrl: './add-edit-booklet.component.html',
  styleUrl: './add-edit-booklet.component.scss'
})
export class AddBookletComponent {
  
  serviceResponseProgress = false;
  // Define the possible properties for encoded files and file names
  selectedClient:any
  selectedRegion:any
  bookletCoverCSFile:any
  profileHeaderCS:any
  bookletCoverCS:any
  profileHeaderCSFile:any
  bookletBackcoverCS:any
  bookletBackcoverCSFile:any
  bookletCoverRT:any
  bookletCoverRTFile:any
  profileHeaderRT:any
  profileHeaderRTFile:any

  userId: string | undefined;
  Client: ClientModel | undefined;
  Region: RegionModel | undefined;
  newBooklet: BookletModel | undefined;
  bookletType = '';
  modifiedEventDate: Date | undefined;
  newBookletForm: FormGroup | undefined;
  regions$: Observable<RegionModel[]> | undefined;
  clients$: Observable<ClientModel[]> | undefined;
  private modalService = inject(NgbModal);
  selected = null;
  
  @ViewChild('roundtableTab', {static:true}) roundtableTab: ElementRef | undefined;
  
  clientList = new BehaviorSubject<StandardModel[]>([])
  regionList = new BehaviorSubject<StandardModel[]>([])

constructor(private fb: FormBuilder,private vm:BookletViewModel,public toastService: ToastService,){
  this.createForm()
}

  ngOnInit() {
    this.userId = localStorage.getItem('bfs_buyerprofile_uid') || undefined;
    var preID = localStorage.getItem('activeTab') || 0;
    if(preID != '0'){
      this.selected = '1';
    }
    this.vm.subscribeClientList(this.clientList)
    this.vm.subscribeRegionList(this.regionList)
    this.vm.retrieveAllClients()
    this.vm.retrieveAllRegion()
    
  }

  
  

  clientupdated( ){
    let arr = this.clientList.value as any
    this.Client = arr.filter(e => e.id == this.selectedClient)[0]
    this.assignClient(this.selectedClient);
  }

  regionupdated(){
    let arr = this.regionList.value as any
    this.Region = arr.filter(e => e.id == this.selectedRegion)[0]
    this.assignRegion(this.selectedRegion);
  }

  openAddClientPopup(){

    const modalRef = this.modalService.open(AddClientComponent);
 
    modalRef.result.then(
     (result) => {
       
       console.log('Modal closed with result:', result);
       if(result !== undefined){
        this.vm.retrieveAllClients()
      }
     },
     (reason) => {
        
       console.log('Modal dismissed with reason:', reason);
     })

    
}


// openAddRegionPopup(){

//   const modalRef = this.modalService.open(AddRegionComponent);

//   modalRef.result.then(
//    (result) => {

//      console.log('Modal closed with result:', result);
//      if(result !== undefined){
//       let arr = this.regionList.value as any
//       this.Region = arr.filter(e => e.id == result)[0]
//       this.assignRegion(result);
//     }
//    },
//    (reason) => {
   
//      console.log('Modal dismissed with reason:', reason);
//    })
   
// }

// Generic file upload handler
  handleFileUpload(event: any, encodedFileProp: FileUploadKeys, fileNameProp: FileUploadKeys) {
 
  const fileList = event.target.files;
  const file = fileList[0];
  if (fileList && file) {
    const reader = new FileReader();
    reader.onload = (fileData) => {
      this[encodedFileProp] = String(reader.result);  // Dynamically set encoded file content
      this.newBookletForm?.patchValue({ [encodedFileProp]:String(reader.result) });
    };
    reader.readAsDataURL(file);
    this[fileNameProp] = file.name;  // Dynamically set the file name
  }
}

public handleFileRemoval(encodedFileProp: FileUploadKeys, fileNameProp: FileUploadKeys, fileInput: HTMLInputElement) {
  this[encodedFileProp] = '';  // Clear encoded file content
  this[fileNameProp] = '';     // Clear file name
  this.newBookletForm?.patchValue({ [encodedFileProp]: '' });  // Update form with empty values

  // Reset the file input field to allow re-adding the same file
  if (fileInput) {
    fileInput.value = '';  // Reset the file input element
  }
}

// Specific methods using the generic handler
  uploadBookletCoverCS(event: any) {
  this.handleFileUpload(event, 'bookletCoverCS', 'bookletCoverCSFile');
}

 uploadProfileHeaderCS(event: any) {
  this.handleFileUpload(event, 'profileHeaderCS', 'profileHeaderCSFile');
}

  uploadBookletBackCoverCS(event: any) {
  this.handleFileUpload(event, 'bookletBackcoverCS', 'bookletBackcoverCSFile');
}

  uploadBookletCoverRT(event: any) {
  this.handleFileUpload(event, 'bookletCoverRT', 'bookletCoverRTFile');
}

  uploadProfileHeaderRT(event: any) {
  this.handleFileUpload(event, 'profileHeaderRT', 'profileHeaderRTFile');
  
}

// Specific methods using the generic handler for file removal
 removeBookletCoverCS(fileInput: HTMLInputElement) {
  this.handleFileRemoval('bookletCoverCS', 'bookletCoverCSFile', fileInput);
}

 removeProfileHeaderCS(fileInput: HTMLInputElement) {
  this.handleFileRemoval('profileHeaderCS', 'profileHeaderCSFile', fileInput);
}

 removeBookletBackCoverCS(fileInput: HTMLInputElement) {
  this.handleFileRemoval('bookletBackcoverCS', 'bookletBackcoverCSFile', fileInput);
}

 removeBookletCoverRT(fileInput: HTMLInputElement) {
  this.handleFileRemoval('bookletCoverRT', 'bookletCoverRTFile', fileInput);
}

 removeProfileHeaderRT(fileInput: HTMLInputElement) {
  this.handleFileRemoval('profileHeaderRT', 'profileHeaderRTFile', fileInput);
}

createForm() {
  this.newBookletForm = this.fb.group({
    bookletTitle: ['', [Validators.required, Validators.maxLength(50)]],
    eventDate: ['', Validators.required],
    bookletCoverCS: [''],
    bookletCoverRT: [''],
    profileHeaderCS: [''],
    profileHeaderRT: [''],
    bookletBackcoverCS: [''],
    client: [''],
    region: ['']
  });
}

previewBookletCompositeDialog(bookletType: 'CS' | 'RT') {
  // if (!this.newBookletForm) return; 

  const bookletFormData = this.getBookletFormData(bookletType);
  // console.log(bookletFormData)
  if (this.isFormValid(bookletFormData)) {
    const modalRef = this.modalService.open(PreviewBookletComponent, { size: 'xl' });
    modalRef.componentInstance.data = bookletFormData
    
    modalRef.result.then(
    (result) => {},
    (reason) => {
      this.resetForm()
    })
  } else{
    this.toastService.show('One or more required field is not filled!', 'warning');
  }
  
}

resetForm() {
  if (this.newBookletForm) {
    this.newBookletForm.reset();
  }
  this.selectedClient = null
  this.selectedRegion = null
  this.bookletCoverCSFile = null
  this.profileHeaderCS = null
  this.bookletCoverCS = null
  this.profileHeaderCSFile = null
  this.bookletBackcoverCS = null
  this.bookletBackcoverCSFile = null
  this.bookletCoverRT = null
  this.bookletCoverRTFile = null
  this.profileHeaderRT = null
  this.profileHeaderRTFile = null
}

// Helper function to get form data
  getBookletFormData(bookletType: string) {
  const formData = this.newBookletForm?.value;
  return {
    bookletTitle: formData.bookletTitle,
    eventDate: formData.eventDate,
    bookletType:bookletType,
    cover: bookletType === 'CS' ? formData.bookletCoverCS : formData.bookletCoverRT,
    profileHeader: bookletType === 'CS' ? formData.profileHeaderCS : formData.profileHeaderRT,
    client: formData.client,
    region: formData.region,
    backCoverImage:bookletType === 'CS' ? formData.bookletBackcoverCS : null
  };
}

// Helper function to validate form
  isFormValid(bookletFormData: any) {
  return bookletFormData.bookletTitle && bookletFormData.eventDate && bookletFormData.cover && bookletFormData.profileHeader && bookletFormData.region && bookletFormData.client;
}

  assignClient(client: any) {
  this.newBookletForm?.patchValue({ client });
}

  assignRegion(region: any) {
  this.newBookletForm?.patchValue({ region });
}

 

}
