import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { LoginService } from "./login.service";

@Injectable()
export class AuthGurdService {

  constructor(private router: Router, private loginService: LoginService){ 
  }

  canActivate(): boolean {
    const userId: any = this.loginService.getLoggedInUserId();
    const userProfile = this.loginService.getLoggedInUserProfile()

    if (userId && userProfile){ 
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    } 
  }
  

}

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {  return inject(AuthGurdService).canActivate();};

 

 