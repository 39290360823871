<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>

<div class="a4pagesize" *ngIf="bookletCompositeInit">
  <div
    class="m-auto mb-2 d-flex justify-content-around flex-column position-fixed align-items-end bottom-0 w-25"
    style="right: 0px; z-index: 999"
  >
    <button
      title="download as pdf"
      class="btn btn-primary rounded-start-pill preview-btn mb-3"
      (click)="downloadPDF()"
    >
      <i class="bi bi-file-pdf-fill px-2 fs-6 d-inline-block"></i>
      <span class="d-inline-block fs-6"> Download Pdf </span>
    </button>
    <button
      title="download as excel"
      class="btn btn-primary rounded-start-pill preview-btn mb-3"
      (click)="downloadExcell()"
    >
      <i class="bi bi-file-earmark-excel-fill px-2 fs-6 d-inline-block"></i>
      <span class="d-inline-block fs-6"> Download Excel</span>
    </button>
  </div>
  <!-- Booklet Header -->
  <div class="card w-00">
    <img
      [src]="bookletCompositeInit?.header_image"
      class="w-100 card-body bg-light mb-2"
    />
  </div>

  <!-- Profile Start -->
  <div
    *ngFor="let profile of bookletCompositeInit?.profiles; let i = index"
    class="rounded-1 bg-light shadow-sm p-2 mb-4"
  >
    <div>
      <img
        [src]="bookletCompositeInit?.profile_header_image"
        class="w-100 mb-2"
      />
    </div>
    <div class="internal-sidenav-division mb-1">
      <div class="d-flex double-card-division-flex border-1">
        <div class="card w-25 rounded-0 shadow-none mb-0 bg-light">
          <img
            [src]="profile.image"
            class="card-body"
            alt="Profile Image"
            width="250"
            height="250"
          />
        </div>
        <div class="card w-75 rounded-0 mb-0">
          <table
            class="table table-responsive mb-0 card-body table-light table-borderless"
          >
            <tbody>
              <tr class="profile-field-body">
                <th><strong class="profile-field-title">Name: </strong></th>
                <td *ngIf="profile.linkedin_url.trim() !== ''">
                  <a
                    [href]="'https://' + profile.linkedin_url"
                    target="_blank"
                    >{{ profile.fullname }}</a
                  >, {{ profile.company_name }}
                </td>
                <td *ngIf="profile.linkedin_url.trim() === ''">
                  <span>{{ profile.fullname }}</span
                  >, {{ profile.company_name }}
                </td>
              </tr>
              <tr class="profile-field-body">
                <th><strong class="profile-field-title">Title: </strong></th>
                <td>{{ profile.job_title }}</td>
              </tr>
              <tr class="profile-field-body">
                <th><strong class="profile-field-title">Email: </strong></th>
                <td>
                  <a [href]="'mailto:' + profile.email_address">{{
                    profile.email_address
                  }}</a>
                </td>
              </tr>
              <tr class="profile-field-body">
                <th><strong class="profile-field-title">Website: </strong></th>
                <td>
                  <a
                    [href]="'http://' + profile.company_website"
                    target="_blank"
                    >{{ profile.company_website }}</a
                  >
                </td>
              </tr>
              <tr class="profile-field-body">
                <th><strong class="profile-field-title">Phone: </strong></th>
                <td>{{ profile.contact_no }}</td>
              </tr>
              <tr class="profile-field-body">
                <th><strong class="profile-field-title">Address: </strong></th>
                <td>{{ profile.company_address }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        *ngIf="
          profile.strategic_priorities.length > 0 ||
          profile.job_role.trim().length > 0
        "
        class="card w-100 rounded-0 shadow-none"
      >
        <div
          class="card-body bg-light"
          *ngIf="profile.strategic_priorities.length > 0"
        >
          <div class="card-header rounded-0 fs-5 fw-semibold text-primary">
            Strategic Priorities
          </div>
          <ul class="list-group list-group-flush">
            <ng-container
              *ngFor="let priority of profile?.strategic_priorities"
            >
              <li class="list-group-item border-bottom-0">{{ priority }}</li>
            </ng-container>
          </ul>
        </div>
        <div
          class="card-body bg-light"
          *ngIf="profile.job_role.trim().length > 0"
        >
          <div class="card-header rounded-0 mt-2 fs-5 fw-semibold text-primary">
            {{ profile.fullname | getFirstName }} Role
          </div>
          <div class="card-body bg-white">
            <p>{{ profile.job_role }}</p>
          </div>
        </div>
        <div class="card-body bg-light" *ngIf="profile.tech_stack.length > 0">
          <div class="card-header rounded-0 fs-5 fw-semibold text-primary">
            Tech Stack
          </div>
          <ul class="list-group list-group-flush">
            <ng-container *ngFor="let tech of profile?.tech_stack">
              <li class="list-group-item border-bottom-0">{{ tech }}</li>
            </ng-container>
          </ul>
        </div>

        <div
          class="card-body bg-light"
          *ngIf="profile.funded_initiative.length > 0"
        >
          <div class="card-header rounded-0 fs-5 fw-semibold text-primary">
            Funded Initiatives For The Next 12 Months
          </div>
          <ul class="list-group list-group-flush">
            <ng-container *ngFor="let initiative of profile.funded_initiative">
              <li class="list-group-item border-bottom-0">{{ initiative }}</li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Booklet Footer -->
  <div *ngIf="bookletCompositeInit?.footer_image">
    <img
      [src]="bookletCompositeInit.footer_image"
      class="w-100 card-body bg-light mb-3"
    />
  </div>
</div>
