<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>

<!-- Main Container -->
<div class="container main-container bg-body-tertiary">
  <div class="row px-5">
    <!-- Main Content -->
    <div class="col-md-12">
      
      <br>
     

      <div class="row my-3">
        <form *ngIf="newBookletForm" [formGroup]="newBookletForm"> 
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-6">

              <div class="row">
                <div class="col-lg-4 d-flex align-items-center">
                  <h6 class="m-0 text-capitalize text-primary fw-bold">Select Type <span class="text-danger ms-1">*</span></h6>
                  <h6 class="m-0 ms-auto fw-bold text-end">:</h6>
                </div>
                <div class="col-lg-6">
                  <div class="d-grid">
                    <ng-select [(ngModel)]="selected" [ngModelOptions]="{standalone: true}" placeholder="Select Type" class="client-dropdown rounded-pill form-select  form-select-sm cutomSelect"    name="client-dropdown">
                    
                      <ng-option [value]="0">Conference</ng-option>
                      <ng-option [value]="1">Roundtable</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 d-flex align-items-center">
                  <h6 class="m-0 text-capitalize text-primary fw-bold">Booklet Title <span class="text-danger ms-1">*</span></h6>
                  <h6 class="m-0 ms-auto fw-bold text-end">:</h6>
                </div>
                <div class="col-lg-6">
                  <input type="text" class="form-control form-control-sm rounded-pill" placeholder="Booklet Title" formControlName="bookletTitle" maxlength="50">
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 d-flex  align-items-center">
                  <h6 class="m-0 text-capitalize text-primary fw-bold">Event Date <span class="text-danger ms-1">*</span></h6>
                  <h6 class="m-0 ms-auto fw-bold text-end">:</h6>
                </div>
                <div class="col-lg-6">
                    <input type="text" class="form-control form-control-sm rounded-pill"  placeholder="dd/mm/yyyy"
                    onfocus="(this.type='date')"
                    onblur="(this.type='text')"  formControlName="eventDate">
                </div>
              </div>

            </div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-4 d-flex  align-items-center">
                  <h6 class="m-0 text-capitalize text-primary fw-bold">Select Client <span class="text-danger ms-1">*</span></h6>
                  <h6 class="m-0 ms-auto fw-bold text-end">:</h6>
                </div>
                <div class="col-lg-6">
                  <div class="d-grid">
                    <ng-select [(ngModel)]="selectedClient" placeholder="Select Client" [ngModelOptions]="{standalone: true}" [items]="clientList.value" (change)="clientupdated()" class="client-dropdown rounded-pill form-select  form-select-sm cutomSelect" bindLabel="name" bindValue="id">
                    </ng-select>
                  </div>
                </div>
                <div class="col-lg-2 pt-1">
                  <i class="bi bi-plus-circle-fill text-primary d-block cursor-pointer" title="Create client" (click)="openAddClientPopup()"></i>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-4 d-flex  align-items-center">
                  <h6 class="m-0 text-capitalize text-primary fw-bold">Select Region <span class="text-danger ms-1">*</span></h6>
                  <h6 class="m-0 ms-auto fw-bold text-end">:</h6>
                </div>
                <div class="col-lg-6">
                  <div class="d-grid">
                    <ng-select [(ngModel)]="selectedRegion" [ngModelOptions]="{standalone: true}" placeholder="Select Region" [items]="regionList.value" (change)="regionupdated()" class="client-dropdown rounded-pill form-select  form-select-sm cutomSelect " bindLabel="name" bindValue="id">
                    </ng-select>
                     
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="selected == null">
            <p
                class=" border border-secondary mb-3 mt-4 text-center fw-bold h-100 rounded-4 pt-5 fs-4"
                style="
                  border-style: dashed !important;
                  border-color: #e4e5f7 !important ;
                  border-width: 2px;
                  color: #6e6f7e;
                  background-color: #f3f4f8;
                "  
              >
                No booklet type selected! <br /><br />
                <img
                  class="d-block h-auto w-25 m-auto"
                  src="../../assets/undraw_empty_re_opql.svg"
                />
              </p>

          </div>

          <div class="row mt-3" *ngIf="selected == '0'">
            <div class="col-lg-12">
              <div class="row justify-content-center align-items-center py-3 border-2  border-bottom  border-gray">
                <div class="col-lg-6">
                  <h6 class="text-primary fw-bold"><i class="bi bi-journal-plus me-3"></i>Booklet Cover <span
                      class="text-danger ms-1">*</span></h6>
                  <p class="m-0 mt-3 small text-muted fst-italic" *ngIf="!bookletCoverCSFile"> No File Uploaded yet.</p>
                  <p class="m-0 mt-3" *ngIf="bookletCoverCSFile"><i class="bi bi-filetype-png text-primary me-2" ></i> {{bookletCoverCSFile.length < 40 ? bookletCoverCSFile : bookletCoverCSFile.slice(0,40) }} 
                    <i (click)="removeBookletCoverCS(chooseBookletCoverCS)" class="bi bi-trash text-danger me-5 cursor-pointer float-end"></i></p>
                </div>
                <div class="col-lg-3 text-start">
                  <img [src]="bookletCoverCS" *ngIf="bookletCoverCSFile" height="50px" class="shadow-sm bg-white p-1 rounded w-auto cursor-pointer" style="max-width: 120px;" alt="...">
                </div>
                <div class="col-lg-3 text-end">
                  <button class="btn btn-outline-primary" (click)="chooseBookletCoverCS.click()">
                    <i class="bi bi-upload me-2"></i> <span *ngIf="!bookletCoverCSFile">Upload </span> <span *ngIf="bookletCoverCSFile">Re-Upload</span>
                  </button>
                  <input (change)="uploadBookletCoverCS($event)" #chooseBookletCoverCS  accept="image/jpeg, image/png, image/gif, image/jpg" type="file" hidden>
                </div>
              </div>
              <div class="row justify-content-center align-items-center py-3 border-2  border-bottom  border-gray">
                <div class="col-lg-6">
                  <h6 class="text-primary fw-bold"><i class="bi bi-person-add me-3"></i>Profile Header <span
                      class="text-danger ms-1">*</span> </h6>
                      <p class="m-0 mt-3 small text-muted fst-italic" *ngIf="!profileHeaderCSFile"> No File Uploaded yet.</p>
                      <p class="m-0 mt-3" *ngIf="profileHeaderCSFile"><i class="bi bi-filetype-png text-primary me-2" ></i>  {{profileHeaderCSFile.length < 40 ? profileHeaderCSFile : profileHeaderCSFile.slice(0,40) }} 
                        <i (click)="removeProfileHeaderCS(chooseProfileHeaderCS)" class="bi bi-trash text-danger me-5 cursor-pointer float-end"></i></p>
                </div>
                <div class="col-lg-3 text-start">
                  <img [src]="profileHeaderCS" height="50px" *ngIf="profileHeaderCSFile"
                    class="  shadow-sm bg-white p-1 rounded w-auto cursor-pointer"  height="50px"  style="max-width: 120px;" alt="...">
                </div>
                <div class="col-lg-3 text-end">
                  <button class="btn btn-outline-primary" (click)="chooseProfileHeaderCS.click()">
                    <i class="bi bi-upload me-2"></i><span *ngIf="!profileHeaderCSFile">Upload </span> <span *ngIf="profileHeaderCSFile">Re-Upload</span>
                  </button>
                 
                  <input (change)="uploadProfileHeaderCS($event)" #chooseProfileHeaderCS  accept="image/jpeg, image/png, image/gif, image/jpg" type="file" hidden>
                </div>
              </div>
              <div class="row justify-content-center align-items-center py-3 border-2  border-bottom  border-gray">
                <div class="col-lg-6">
                  <h6 class="text-primary fw-bold"><i class="bi bi-journal-plus me-3"></i>Booklet Back Cover <span
                      class="text-danger ms-1">*</span>
                  </h6>
                  <p class="m-0 mt-3 small text-muted fst-italic" *ngIf="!bookletBackcoverCSFile"> No File Uploaded yet.</p>
                  <p class="m-0 mt-3" *ngIf="bookletBackcoverCSFile"><i class="bi bi-filetype-png text-primary me-2" ></i>  {{bookletBackcoverCSFile.length < 40 ? bookletBackcoverCSFile : bookletBackcoverCSFile.slice(0,40) }} 
                    <i (click)="removeBookletBackCoverCS(chooseBookletBackCoverCS)" class="bi bi-trash text-danger me-5 cursor-pointer float-end"></i></p>
                </div>
                <div class="col-lg-3 text-start">
                  <img [src]="bookletBackcoverCS" height="50px" *ngIf="bookletBackcoverCSFile"
                    class="  shadow-sm bg-white p-1 rounded w-auto cursor-pointer "  height="50px"  style="max-width: 120px;" alt="...">
                </div>
                <div class="col-lg-3 text-end">
                  <button class="btn btn-outline-primary" (click)="chooseBookletBackCoverCS.click()">
                    <i class="bi bi-upload me-2"></i><span *ngIf="!bookletBackcoverCSFile">Upload </span> <span *ngIf="bookletBackcoverCSFile">Re-Upload</span>
                  </button>
            
                  <input (change)="uploadBookletBackCoverCS($event)" #chooseBookletBackCoverCS  accept="image/jpeg, image/png, image/gif, image/jpg" type="file" hidden>
                   
                </div>
              </div>
           

            </div>
            <div class="col-lg-12 mt-5">
              <div class="row">
                <div class="col text-lg-end">
                  <button class="btn btn-danger text-white me-3" (click)="resetForm()">
                    <i class="bi bi-arrow-counterclockwise me-2"></i>Reset
                  </button>
                  <button class="btn btn-primary text-white" (click)="previewBookletCompositeDialog('CS')">
                    <i class="bi bi-eye me-2"></i>Preview
                  </button>
                </div>
                
              </div>
            </div>
          </div>
          <div class="row mt-3" *ngIf="selected == '1'">
            <div class="col-lg-12">
              <div class="row justify-content-center align-items-center py-3 border-2  border-bottom  border-gray">
                <div class="col-lg-6">
                  <h6 class="text-primary fw-bold"><i class="bi bi-journal-plus me-3"></i>Booklet Cover <span
                      class="text-danger ms-1">*</span></h6>
                  <p class="m-0 mt-3 small text-muted fst-italic" *ngIf="!bookletCoverRTFile"> No File Uploaded yet.</p>
                  <p class="m-0 mt-3" *ngIf="bookletCoverRTFile"><i class="bi bi-filetype-png text-primary me-2" ></i>  {{bookletCoverRTFile.length < 40 ? bookletCoverRTFile : bookletCoverRTFile.slice(0,40) }} 
                    <i (click)="removeBookletCoverRT(chooseBookletCoverRT)" class="bi bi-trash text-danger me-5 cursor-pointer float-end"></i></p>
                </div>
                <div class="col-lg-3 text-start">
                  <img [src]="bookletCoverRT" *ngIf="bookletCoverRTFile" height="50px" class="rounded shadow-sm bg-white p-2 cursor-pointer" style="max-width: 120px;" alt="...">
                </div>
                <div class="col-lg-3 text-end">
                  <button class="btn btn-outline-primary" (click)="chooseBookletCoverRT.click()">
                    <i class="bi bi-upload me-2"></i> <span *ngIf="!bookletCoverRTFile">Upload </span> <span *ngIf="bookletCoverRTFile">Re-Upload</span>
                  </button>
                  <input (change)="uploadBookletCoverRT($event)" #chooseBookletCoverRT  accept="image/jpeg, image/png, image/gif, image/jpg" type="file" hidden>
                </div>
              </div>
              <div class="row justify-content-center align-items-center py-3 border-2  border-bottom  border-gray">
                <div class="col-lg-6">
                  <h6 class="text-primary fw-bold"><i class="bi bi-person-add me-3"></i>Profile Header <span
                      class="text-danger ms-1">*</span> </h6>
                      <p class="m-0 mt-3 small text-muted fst-italic" *ngIf="!profileHeaderRTFile"> No File Uploaded yet.</p>
                      <p class="m-0 mt-3" *ngIf="profileHeaderRTFile"><i class="bi bi-filetype-png text-primary me-2" ></i>   {{profileHeaderRTFile.length < 40 ? profileHeaderRTFile : profileHeaderRTFile.slice(0,40) }} 
                        <i (click)="removeProfileHeaderRT(chooseProfileHeaderRT)"  class="bi bi-trash text-danger me-5 float-end cursor-pointer"></i></p>
                </div>
                <div class="col-lg-3 text-start">
                  <img [src]="profileHeaderRT" height="50px" *ngIf="profileHeaderRTFile"
                    class="rounded shadow-sm bg-white p-2 cursor-pointer"  height="50px"  style="max-width: 120px;" alt="...">
                </div>
                <div class="col-lg-3 text-end">
                  <button class="btn btn-outline-primary" (click)="chooseProfileHeaderRT.click()">
                    <i class="bi bi-upload me-2"></i><span *ngIf="!profileHeaderRTFile">Upload </span> <span *ngIf="profileHeaderRTFile">Re-Upload</span>
                  </button>
                 
                  <input (change)="uploadProfileHeaderRT($event)" accept="image/jpeg, image/png, image/gif, image/jpg" #chooseProfileHeaderRT type="file" hidden>
                </div>
              </div>
               
           

            </div>
            <div class="col-lg-12 mt-5">
              <div class="row">
                <div class="col text-lg-end">
                  <button class="btn btn-danger text-white me-3" (click)="resetForm()">
                    <i class="bi bi-arrow-counterclockwise me-2"></i>Reset
                  </button>
                  <button class="btn btn-primary text-white" (click)="previewBookletCompositeDialog('RT')">
                    <i class="bi bi-eye me-2"></i>Preview
                  </button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
       </form>
      </div>
    </div>
 
  </div>
</div>

