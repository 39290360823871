import { ProfileModel } from "./profile.model";


export class BookletSearchModel {
  bklt_type:string
  bklt_title:any
  client: any
  region:any
  oz_month:any
  oz_year:any
  [key: string]: any;
}


export type FileUploadKeys = 'bookletCoverCS' | 'bookletCoverCSFile' |
'profileHeaderCS' | 'profileHeaderCSFile' |
'bookletBackcoverCS' | 'bookletBackcoverCSFile' |
'bookletCoverRT' | 'bookletCoverRTFile' |
'profileHeaderRT' | 'profileHeaderRTFile';

export class BookletListModel {
  id: number;
  created_by: { id: number, name: string };
  created_at: Date;
  organized_at: Date;
  client_list:SelectedClientModel[]
  region:number
  title: string;
  type: string; 
  masking_profiles: []
} 

export class BookletPaginatedListModel {
  count: number;
  next: string;
  previous: string;
  results: [BookletListModel];
}


export class SelectedClientModel {
  association_id : number
  client_id:number
  name:string
}

export class StandardResponseModal {
  results: StandardModel[]
}

export class StandardModel {
  id: number
  name: string
}

export class ClientModel {
  id: number
  name: string
}

export class RegionModel {
  id: number
  name: string
}

export class IApiServiceDelegate {
  Action: string
  Success: (e: String) => String
  Error: (e: String) => String
  Method: string
  Data: any | undefined
 }

export class BookletModel {
  id: number;
  created_by: number;
  created_at: string;
  organized_at: string;
  modified_by: number;
  modified_at: string;
  title: string;
  header_image: string;
  footer_image: string;
  profile_header_image: string;
  type: string;
  client: number;
  region: number;
  masking_profiles: [];
}

export class BookletCompositeModel {
  id: number;
  created_by: number;
  created_at: Date;
  organized_at: Date;
  modified_by: number;
  modified_at: Date;
  title: string;
  header_image: string;
  footer_image: string;
  profile_header_image: string;
  profile_footer_image: string;
  profiles: ProfileModel[] | undefined;
}

export class ProfileExtrasModel {
  annual_company_budget: string;
  company_annual_revenue: string;
  id: number;
  booklet: {};
  investment_plans: string;
  number_of_employees: string;
  primary_business_activity: string;
  profile: number
  prospect_name: string;
  profile_image: string;
  scopeof_it_responsibility: string;
}