 
import { Component, Renderer2, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { DfSelectionModalComponent } from './df-selection-modal/df-selection-modal.component';
import { DfFormModalComponent } from './df-form-modal/df-form-modal.component';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-desk-feedback',
  templateUrl: './desk-feedback.component.html',
  styleUrls: ['./desk-feedback.component.scss']
})
export class DeskFeedbackComponent implements OnInit, OnDestroy {

  sendfeedbackViewEnabled: boolean = false;
  selectedSelectionType: number | undefined;

  private unlistenerMousedown: () => void;
  private unlistenerMouseup: () => void;
  private unlistenerMouseMove: () => void;

  modalSelectionRef :NgbModalRef 
  modalFormRef  :NgbModalRef 

  static StaticCall;

 
  start = {
    x: 0,
    y: 0
  };
  end = {
    x: 0,
    y: 0
  };
  isSelecting = false;
  finalImageBase64: string | undefined;
  skippedPicture: boolean = false;

  constructor(private renderer2: Renderer2 ,private modalService: NgbModal
    ) {
      DeskFeedbackComponent.StaticCall = this;
    }

  ngOnInit(): void {
  }

  clickOFF(e){
    e.stopImmediatePropagation()
  }

  listenerMousedown(element,e){
    e.stopImmediatePropagation()
    this.unlistenerMousedown = this.renderer2.listen("document", "mousedown", event => {
      var el = document.getElementById(element); 
      this.isSelecting = true; 
      el.classList.remove('complete');
      this.start.x = event.pageX;
      this.start.y = event.pageY;  

      el.style.left = String(this.start.x) + 'px';
      el.style.top = String(this.start.y) + 'px';

    });
  }

  listenerMouseMove(element,e){
    e.stopImmediatePropagation()
    this.unlistenerMouseMove = this.renderer2.listen("document", "mousemove", event => { 
      var el = document.getElementById(element);
      if (!this.isSelecting) { return; }
      // Update our state
      this.end.x = event.pageX;
      this.end.y = event.pageY;

      el.style.left = String(this.start.x < this.end.x ? this.start.x : this.end.x) + 'px';
      el.style.top = String(this.start.y < this.end.y ? this.start.y : this.end.y) + 'px';
      el.style.width = String(Math.abs(this.start.x - this.end.x)) + 'px';
      el.style.height = String(Math.abs(this.start.y - this.end.y)) + 'px'; 

      
     });
  }

  listenerMouseup(element,e){
    e.stopImmediatePropagation()
    this.unlistenerMouseup = this.renderer2.listen("document", "mouseup", event => { 
      this.isSelecting = false;
      var el = document.getElementById(element);
      el.classList.add('complete');  

      const x = this.start.x < this.end.x ? this.start.x : this.end.x;
      const y = this.start.y < this.end.y ? this.start.y : this.end.y;
      const width = Math.abs(this.start.x - this.end.x);
      const height = Math.abs(this.start.y - this.end.y);
      this.generateImage(x, y, width, height, true);
    
     });
  }
   

  startListeners(){
    var feedbackScreeSelection = 'feedbackScreeSelection';
    this.listenerMousedown(feedbackScreeSelection, event);
    this.listenerMouseMove(feedbackScreeSelection,event);
    this.listenerMouseup(feedbackScreeSelection,event);
  } 

  destroyListener(){
    this.unlistenerMousedown();
    this.unlistenerMouseup();
    this.unlistenerMouseMove();
  }

  ngOnDestroy() {
    this.destroyListener();
  }

  generateImage(x, y, width, height, crop){ 
    
    if(crop) {
      DeskFeedbackComponent.StaticCall.destroyListener();
      html2canvas(document.body, {width: width, height: height, x:x, y:y, allowTaint:false}).then(function(canvas) { 
        const dataUrl = canvas.toDataURL();
        DeskFeedbackComponent.StaticCall.finalImageBase64 = dataUrl;
        DeskFeedbackComponent.StaticCall.imageSelectionDone();
      }).catch(function (error) {
        console.error('Error generating screenshot:', error);
      });

    } else {

      html2canvas(document.body).then(function(canvas) { 
        const dataUrl = canvas.toDataURL();
        DeskFeedbackComponent.StaticCall.finalImageBase64 = dataUrl;
        DeskFeedbackComponent.StaticCall.imageSelectionDone();
      }).catch(function (error) {
        console.error('Error generating screenshot:', error);
      });
    } 
    
  }
  

  imageSelectionDone(){
    this.openFormModal(event);
  }

  openFeedback(e){
    e.stopPropagation()
    this.sendfeedbackViewEnabled = true;
    this.openSelectionModal(e);
  }

  makeDefault(){
    this.sendfeedbackViewEnabled = false;
    this.skippedPicture = false;
    this.start = {x: 0, y: 0};
    this.end = {x: 0, y: 0 };
    this.isSelecting = false;
    this.finalImageBase64 = undefined; 

  }

  openSelectionModal(e) {
    e.stopImmediatePropagation()
    this.modalSelectionRef = this.modalService.open(DfSelectionModalComponent,{ backdrop: "static"});
    // console.log(this.modalSelectionRef , this.modalFormRef )
    this.modalSelectionRef.closed.subscribe((message: any) => {
      e.stopImmediatePropagation()
      // console.log(message)
      if(message){
        this.selectedSelectionType = message;

        if (this.selectedSelectionType === 3){
            this.startListeners();
        } else if (this.selectedSelectionType === 2){
            setTimeout(() => {
              this.generateImage(0, 0, 0, 0, false);
            }, 300);
        } else {
          this.skippedPicture = true;
          this.openFormModal(e);
        } 
        
      } else {
       
        this.makeDefault();
      }
    });
  }

  openFormModal(e){
    e.stopImmediatePropagation()
    this.modalFormRef = this.modalService.open(DfFormModalComponent , {backdrop: "static"})
     
    this.modalFormRef.componentInstance.skippedPicture = this.skippedPicture
    this.modalFormRef.componentInstance.finalImageBase64=  this.finalImageBase64
     
    this.modalFormRef.result.then(
      (result) => {
			this.makeDefault()
			},
      
    )
  }

  

}
