import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
 
 

@Component({
  selector: 'app-df-selection-modal',
  templateUrl: './df-selection-modal.component.html',
  styleUrls: ['./df-selection-modal.component.scss']
})
export class DfSelectionModalComponent implements OnInit {

  isIsCDB:boolean

  constructor(public modalRef:NgbActiveModal ) {
     
  }

  ngOnInit(): void {

    
  }

  clickOFF(e){
    e.stopImmediatePropagation()
  }

  selectedType(type: number, e){
    e.stopImmediatePropagation()
    this.modalRef.close(type);
  }

  close(e): void {
    e.stopImmediatePropagation()
    this.modalRef.close(undefined);
  }

}
