<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>
<div class="m-auto mb-2 d-flex justify-content-around flex-column position-fixed align-items-end bottom-0 w-25" style="right:0px;z-index:999">
  <button class="btn  btn-primary   rounded-start-pill preview-btn mb-3" title="Edit Profile" (click)="editProfileDialog()" ><i class="bi bi-pencil-fill  px-2 fs-6 text-white d-inline-block"></i> <span class="d-inline-block fs-6  text-white"> Edit Profile</span> </button>
  <button class="btn  btn-danger  rounded-start-pill preview-btn mb-3" title="Delete Profile" (click)="deleteProfileDialog()" ><i class="bi bi-trash-fill  px-2 fs-6 text-white d-inline-block"></i> <span class="d-inline-block fs-6 text-white"> Delete Profile</span></button>
  <button class="btn  btn-secondary   rounded-start-pill preview-btn mb-3" title="Export Profile"   (click)="exportProfileDialog(profileInit.fullname, '/profile-composite',profileInit.id)"  > <i class="bi bi-share-fill  px-2 fs-6 text-white d-inline-block"></i> <span class="d-inline-block fs-6 text-white"> Export Profile</span> </button>
</div>
 
<div class="container">
  <div class="row">
    <!-- Main content -->
    <div class="col-md-12 col-lg-12">
      <div class="profile-inner-division mt-2">
        <!-- Profile Form -->
        
        <div class="row" *ngIf="profileInit">
          <!-- Profile Image Section -->
          <div  class="col-md-3 d-flex flex-column align-items-start bg-body-secondary py-4 px-4"
          >
              <div class="text-center card p-3 mb-4 w-100"> 
                <img [src]=[profileInit.image]
                  width="200"
                  height="200"
                  class="img-fluid rounded-4 d-block mb-2 m-auto"
                />
                
            </div>

            <div class="row card p-2 m-0 w-100">
              <!-- Full Name -->
              <div class="col-md-12 mb-3 mt-3">
                <strong class="form-label fs-6 text-primary "><i class="bi bi-person-circle text-primary"></i> Full Name :</strong>
                <p class="mb-1"> {{profileInit.fullname ? profileInit.fullname : 'NA'}}</p>
              </div>

              <!-- Company Name -->
              <div class="col-md-12 mb-3">
                <strong class="form-label fs-6 text-primary"><i class="bi bi-building-fill text-primary"></i> Company Name :</strong>
                <p class="mb-1"> {{profileInit.company_name ? profileInit.company_name : 'NA'}}</p>
              </div>

              <!-- Job Title -->
              <div class="col-md-12 mb-3">
                <strong class="form-label fs-6 text-primary"><i class="bi bi-journal-album text-primary"></i> Job Title :</strong>
                <p class="mb-1"> {{profileInit.job_title ? profileInit.job_title : 'NA'}}</p>
              </div>

              <!-- Phone -->
              <div class="col-md-12 mb-3">
                <strong class="form-label fs-6 text-primary"><i class="bi bi-phone-fill text-primary"></i> Phone :</strong>
                <p class="mb-1"> {{profileInit.contact_no ? profileInit.contact_no : 'NA'}}</p>
              </div>
            </div>
          </div>

          <div class="col-md-9 bg-light p-4 ">
            <div class="card py-4 ">
                <div class="row m-0 ">
              <!-- Company Website -->
              <div class="col-md-6 mb-3">
                <strong class="form-label fs-6 text-primary"
                  ><i class="bi bi-browser-chrome text-primary"></i> Company Website :</strong>
                 <p class="mb-1">  <a *ngIf="profileInit.company_website" style="text-decoration:none; color: #0873bc;"
                        href="{{getUrl(profileInit.company_website)}}"
                        target="_blank">{{removeHttp(profileInit.company_website)}}
                     </a>
                     <span *ngIf="!profileInit.company_website"> NA </span></p>
              </div>

              <!-- Company Industry -->
              <div class="col-md-6 mb-3">
                <strong class="form-label fs-6 text-primary"><i class="bi bi-buildings-fill text-primary"></i> Company Industry :</strong>
                <p class="mb-1"> {{profileInit.industry_name ? profileInit.industry_name :'NA' }}</p>
              </div>

              <!-- Email -->
              <div class="col-md-6 mb-3">
                <strong class="form-label fs-6 text-primary"><i class="bi bi-envelope-at-fill text-primary"></i> Email :</strong>
                <p class="mb-1">  <a style="text-decoration:none; color: #0873bc;"
                           href="{{'mailto:' + profileInit.email_address}}">{{profileInit.email_address}}
                </a>
             </p>
              </div>

              <!-- LinkedIn -->
              <div class="col-md-6 mb-3">
                <strong class="form-label fs-6 text-primary"><i class="bi bi-linkedin text-primary"></i> Linkendin :</strong>
                <p class="mb-1"> <a *ngIf="profileInit.linkedin_url" style="text-decoration:none; color: #0873bc;"
                    href="{{getUrl(profileInit.linkedin_url)}}" target="_blank">{{removeHttp(profileInit.linkedin_url)}}
                 </a>
                 <span *ngIf="!profileInit.linkedin_url"> NA  </span> </p>
              </div>

              <!-- Company Address -->
              <div class="col-md-6 ">
                <strong class="form-label fs-6 text-primary"> <i class="bi bi-house-add-fill text-primary"></i> Address :</strong>
                <p class="mb-1"> {{profileInit.company_address}}</p>
              </div>
              <!-- Company Region -->
              <div class="col-md-6  ">
                <strong class="form-label fs-6 text-primary"><i class="bi bi-globe-central-south-asia text-primary"></i> Region :</strong>
                 
                    <p style="padding:0px; margin: 0px;" *ngIf="profileInit.region.length > 0"> <span *ngFor="let region of profileInit.region" >  {{ region?.name}} </span></p>
                    <p style="padding:0px; margin: 0px;" *ngIf="profileInit.region.length == 0"> NA </p>
                
              </div>
            </div>
            </div>
            <!-- Strategic Priorities -->
            <div class="card mt-4"  >
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-award-fill  text-primary"></i> Strategic Priorities :</strong>
                </div>
                <ul  *ngIf="profileInit.strategic_priorities.length > 0 ">
                    <ng-container *ngFor="let priority of profileInit.strategic_priorities">
                       <li *ngIf="priority.trim().length > 0">
                          {{priority}}
                       </li>
                    </ng-container>
                 </ul>
                 <p  class="ps-4" *ngIf="profileInit.strategic_priorities.length == 0 "> NA</p>
              </div>
            </div>

            <!-- Role -->
            <div class="card mt-4"  >
              <div class="card-body">
                <strong class="form-label fs-6 text-primary">
                    <strong  class="fs-6 text-primary" *ngIf="!checkLastCharacter"><i class="bi bi-person-rolodex text-primary"></i> {{firstName}}'s Role :</strong>
                    <strong  class="fs-6 text-primary" *ngIf="checkLastCharacter"><i class="bi bi-person-rolodex text-primary"></i> {{firstName}}' Role :</strong>
                    </strong
                >
                <p style="line-height: normal;white-space: pre-line;" *ngIf="profileInit.job_role.trim().length > 0" class="profile-card-body ps-4">{{ profileInit.job_role  }}</p>
                <p  class="ps-4" *ngIf="profileInit.job_role.trim().length == 0"> NA</p>
              </div>
            </div>

            <!-- Tech Stack -->
            <div class="card mt-4" >
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-stack text-primary"></i> Tech Stack :</strong>
                </div>
                <ul   *ngIf="profileInit.tech_stack.length > 0">
                    <ng-container *ngFor="let tech of profileInit.tech_stack">
                       <li *ngIf="tech.trim().length > 0">
                          {{tech}}
                       </li>
                    </ng-container>
                 </ul>
                 <p  class="ps-4"  *ngIf="profileInit.tech_stack.length == 0"> NA</p>
              </div>
            </div>

            <!-- Funded Initiatives -->
            <div class="card mt-4"  >
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-piggy-bank-fill text-primary"></i> Funded Initiatives:</strong>
                   
                </div>
                <ul  *ngIf="profileInit.funded_initiative.length > 0">
                    <ng-container *ngFor="let initiative of profileInit.funded_initiative">
                       <li *ngIf="initiative.trim().length > 0">
                          {{initiative}}
                       </li>
                    </ng-container>
                 </ul>
                 <p  class="ps-4" *ngIf="profileInit.funded_initiative.length == 0"> NA</p> 
              </div>
            </div>
            <!-- Technologies Interested -->
            <div class="card mt-4"  *ngIf="profileInit.technologies_interest.length > 0">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-hand-thumbs-up-fill text-primary"></i> Technologies Interested In :</strong>
                   
                </div>
                <ul style="column-count: 2;" class="profile-card-body" *ngIf="profileInit.technologies_interest.length > 0">
                    <ng-container *ngFor="let technology of profileInit.technologies_interest">
                       <li style="line-height: 200%; margin-top:0px;" *ngIf="technology.trim().length > 0">
                          {{technology}}
                       </li>
                    </ng-container>
                 </ul>
                 <p  class="ps-4" *ngIf="profileInit.technologies_interest.length == 0"> NA</p>
              </div>
            </div>

            <div class="card mt-4"  *ngIf="profileInit.topics_interest.length > 0">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-substack text-primary"></i> Topics Interested In :</strong>
                   
                </div>
                <ul  *ngIf="profileInit.topics_interest.length > 0">
                    <ng-container *ngFor="let topic of profileInit.topics_interest">
                       <li *ngIf="topic.trim().length > 0">
                          {{topic}}
                       </li>
                    </ng-container>
                 </ul>
                 <p  class="ps-4" *ngIf="profileInit.topics_interest.length == 0">NA</p>
              </div>
            </div>

            <div class="card mt-4"  *ngIf="profileExtras?.primary_business_activity?.length > 0">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-activity text-primary"></i> Primary Business Activity :</strong>
                   
                </div>
                <p  class="ps-4" *ngIf="profileExtras?.primary_business_activity?.length > 0">{{profileExtras.primary_business_activity}}</p>
                <p  class="ps-4" *ngIf="profileExtras?.primary_business_activity?.length == 0">NA</p>
              </div>
            </div>
            <div class="card mt-4"  *ngIf="profileExtras?.company_annual_revenue?.length > 0">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-cash-stack text-primary"></i> Company Annual Revenue :</strong>
                   
                </div>
                <p  class="ps-4" *ngIf="profileExtras?.company_annual_revenue?.length > 0">{{profileExtras.company_annual_revenue}}</p>
                <p  class="ps-4" *ngIf="profileExtras?.company_annual_revenue?.length == 0">NA</p>
              </div>
            </div>
            <div class="card mt-4"  *ngIf="profileExtras?.number_of_employees?.length > 0">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-person-fill-add text-primary"></i> Number of Employees :</strong>
                   
                </div>
                <p  class="ps-4" *ngIf="profileExtras?.number_of_employees?.length > 0">{{profileExtras.number_of_employees}}</p>
                <p  class="ps-4" *ngIf="profileExtras?.number_of_employees?.length == 0">NA</p>
              </div>
            </div>
            <div class="card mt-4"   *ngIf="profileExtras?.annual_company_budget?.length > 0">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-currency-exchange text-primary"></i> Annual Company Budget :</strong>
                   
                </div>
                <p  class="ps-4" *ngIf="profileExtras?.annual_company_budget?.length > 0">{{profileExtras.annual_company_budget}}</p>
                <p  class="ps-4" *ngIf="profileExtras?.annual_company_budget?.length == 0">NA</p>
              </div>
            </div>
            <div class="card mt-4"  *ngIf="profileExtras?.scopeof_it_responsibility?.length > 0" >
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-file-easel-fill text-primary"></i> Scope of it Responsibility :</strong>
                   
                </div>
                <p  class="ps-4" *ngIf="profileExtras?.scopeof_it_responsibility?.length > 0">{{profileExtras.scopeof_it_responsibility}}</p>
                <p  class="ps-4" *ngIf="profileExtras?.scopeof_it_responsibility?.length == 0"> NA</p>
              </div>
            </div>
            <div class="card mt-4"   *ngIf="profileExtras?.investment_plans?.length > 0">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-send-arrow-up-fill text-primary"></i> Investment plans :</strong>
                   
                </div>
                <p class="ps-4" *ngIf="profileExtras?.investment_plans?.length > 0">{{profileExtras.investment_plans}}</p>
                <p class="ps-4" *ngIf="profileExtras?.investment_plans?.length == 0">NA</p>
              </div>
            </div>

            <!-- Custom Fields -->
            <div class="card mt-4">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <strong class="fs-6 text-primary"><i class="bi bi-backpack4-fill text-primary"></i> Others :</strong>
                </div>
                <ul  *ngIf="profileInit.custom_fields?.length > 0"  class="ps-4">
                    <li  *ngFor="let field of profileInit.custom_fields">
                        <h6 class="text-capitalize mb-1">{{field.header}} </h6> 
                         <ul class="list-unstyled p-1"> 
                           <li class="ps-1">{{field.value}} </li>
                         </ul> 
                  </li>
                 </ul>
                 <p class="profile-card-body  ps-4"  *ngIf="profileInit.custom_fields == null || profileInit.custom_fields?.length == 0">NA </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</div>
