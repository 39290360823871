/* src/app/desk-feedback/df-selection-modal/df-selection-modal.component.scss */
.select-item {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.07), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  border: 0;
}
.select-item:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.07), 0 1px 13px -4px rgb(0, 0, 0);
  border: 0;
}
/*# sourceMappingURL=df-selection-modal.component.css.map */
