import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, map, catchError, Observable } from 'rxjs';
import { IApiServiceDelegate } from '../model/booklet.model';
 


@Injectable({
  providedIn: 'root'
})


export class ApiService {

  api_cont = environment.api_url;
  api_desk = environment.desk_api_url;
  api_full = this.api_cont + '/buyerprofile/v1/'
  // api_full = 'http://13.76.186.166:8000/buyerprofile/v1/';
   storedUsername = localStorage.getItem('bfs_buyerprofile_uid');
   


  constructor( private http: HttpClient) { }


  
  
  callDeskFeedbackService(data: IApiServiceDelegate) {
    var newURL = this.api_desk + data.Action;
    this.executeDeskFeedback(data.Data, newURL, data.Method).subscribe(
      (result: any) => {
        let res = result.res;
        data.Success(res);
      },
      (error: any) => {
        if (typeof error['Errors'] !== 'undefined') {
          data.Error(error['Errors']);
        }
      }
    );
  }
  getDeskFeedbackHeaderOptions() {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Content-Type', 'application/json'); 
    const httpOptions = {
      headers: httpHeaders
    };
    return httpOptions;
  }

  errorHandler(e: any) {
    return throwError({ Errors: e.error });
}
executeDeskFeedback(apiData: any, url: string, method: string) {
  if (method === 'POST') {
    return this.http
      .post(url, apiData, this.getDeskFeedbackHeaderOptions())
      .pipe(
        map((res: any) => ({res:res})),  // Correctly returns the response
        catchError(this.errorHandler)  // Handles any errors
      );
  } else {
    throw new Error(`Method ${method} is not supported`);
  }
}

 


// to create request headers
getHeaderOptions() {
  let httpHeaders = new HttpHeaders();
  httpHeaders = httpHeaders.set("Content-Type", "application/json");
  // if (HLGlobal.getloggedInObject() && HLGlobal.getloggedInObject().token)
  //   httpHeaders = httpHeaders.set(
  //     "Authorization",
  //     "Bearer " + HLGlobal.getloggedInObject().token
  //   );

  const httpOptions = {
    headers: httpHeaders,
  };
  return httpOptions;
}

  // this method is provided to client classes to call api
  callService(data: IApiServiceDelegate) {
    var newURL =this.api_full  + data.Action;
    // console.log(data,newURL)
    this.execute(data.Data, newURL, data.Method).subscribe(
      (result: any) => {
        let res = result.res;
        // hideLoadingBar()
        data.Success(res);
      },
      (error: any) => {
        // hideLoadingBar()
        if (typeof error["Errors"] !== "undefined") {
          data.Error(error["Errors"]);
        }
      }
    );
  }

 
 

  // this method is provided to client classes to call api
  callExternalService(data: IApiServiceDelegate) {
    var newURL = "" + data.Action;
    this.execute(data.Data, newURL, data.Method).subscribe(
      (result: any) => {
        let res = result.res;
        // hideLoadingBar()
        data.Success(res);
      },
      (error: any) => {
        // hideLoadingBar()
        if (typeof error["Errors"] !== "undefined") {
          data.Error(error["Errors"]);
        }
      }
    );
  }


 
  // to handle api of download a file
  download(url: any) { 
    var newURL =this.api_full  + url;
    let httpHeaders = new HttpHeaders();
    // httpHeaders.append("Accept", "application/json");
    // if (HLGlobal.getloggedInObject() && HLGlobal.getloggedInObject().token)
    //   httpHeaders = httpHeaders.set(
    //     "Authorization",
    //     "Bearer " + HLGlobal.getloggedInObject().token,
    //   );
    var HTTPOptions = {
      headers: httpHeaders,
      // observe: "response" as "body", // to display the full response & as 'body' for type cast
      responseType: "blob" as "json",
    };
    return this.http
      .get(newURL, HTTPOptions)
      .pipe(catchError(this.erroHandler));
  }

  

  downloadWithPost(url: any, data: string | Blob) { 
    const uploadedFilters = new FormData();
    uploadedFilters.set(
      "explore_filters", data
    );
    var newURL =this.api_full  + url;
    let httpHeaders = new HttpHeaders();
    httpHeaders.append("Accept", "application/json");
    // if (HLGlobal.getloggedInObject() && HLGlobal.getloggedInObject().token)
    //   httpHeaders = httpHeaders.set(
    //     "Authorization",
    //     "Bearer " + HLGlobal.getloggedInObject().token,
    //   );
    var HTTPOptions = {
      headers: httpHeaders,
      observe: "response" as "body", // to display the full response & as 'body' for type cast
      responseType: "blob" as "json",
    };
    return this.http
      .post(newURL, uploadedFilters, HTTPOptions)
      .pipe(catchError(this.erroHandler));
  }

  
   

  uploadFile(url: string, file: File, args?: {name: string, value: string}[]){

    var newURL =this.api_full  + url;
    const uploadedFile = new FormData();

    uploadedFile.set("file", new Blob([file]), file.name);

    args?.forEach(element => {
      uploadedFile.set(element.name, element.value);
    });
    
    let httpHeaders = new HttpHeaders();

    // if (HLGlobal.getloggedInObject() && HLGlobal.getloggedInObject().token){
    //   httpHeaders = httpHeaders.set("Authorization", "Bearer " + HLGlobal.getloggedInObject().token);
    // } 

    httpHeaders.append("Accept", "application/x-www-form-urlencoded");
    const httpOptions = {
      headers: httpHeaders,
    };
  
    return this.http.post(newURL, uploadedFile, httpOptions).pipe(
        map((res: any) => {
          return { res: res };
        })
      ).pipe(catchError(this.erroHandler));
  }
  
 
  // to handle end http api calls from FE
  execute(apiData: any, url: string, method: String) {
    if (method == "POST") {
      return this.http
        .post(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: any) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.erroHandler));
    } else if (method == "DELETE") {
      return this.http
        .delete(url, this.getHeaderOptions())
        .pipe(
          map((res: any) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.erroHandler));
    } else if (method == "PATCH") {
      return this.http
        .patch(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: any) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.erroHandler));
    } else if (method == "PUT") {
      return this.http
        .put(url, apiData, this.getHeaderOptions())
        .pipe(
          map((res: any) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.erroHandler));
    } else {
      return this.http
        .get(url, this.getHeaderOptions())
        .pipe(
          map((res: any) => {
            return { res: res };
          })
        )
        .pipe(catchError(this.erroHandler));
    }
  }

  // to handle all the errors recieved from the api
  erroHandler(e: any) {
    var msg = JSON.stringify(e.error)
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/"/g, "")
      .replace(/\[/g, "")
      .replace(/]/g, "");
    return throwError({ Errors: msg });
  }


  //  retrieveUserbyID(userId: string) {
  //   let API_URL: string = this.api_full.concat('user/:userId');
  //   API_URL = API_URL.replace(':userId', userId);
  //   return this.apiClient.get(API_URL);
  // }

  //  retrieveDuplicate(type:string , dupItem: string,bookletId:string) {
  //   let API_URL: string = this.api_full.concat('profile-duplicate/check_profile_duplicate?booklet_id='+bookletId);
  //   if(type =='email'){
  //     API_URL = API_URL.concat("&email_address="+dupItem);
  //   } else if(type = 'linkedin'){
  //     API_URL = API_URL.concat("&LinkedIn="+dupItem);
  //   }

  //   return this.apiClient.get(API_URL);
  // }

  //  loginUser(userCredential: string) {
  //   const API_URL: string = this.api_full.concat('user/login');
  //   const API_PARAMS = { cred: userCredential };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  createBooklet(booklet: any) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   return this.apiClient.post(API_URL, booklet);
  // }

  //  updateBooklet(bookletId: string, booklet: any) {
  //   let API_URL: string = this.api_full.concat('booklet/:bookletId');
  //   API_URL = API_URL.replace(':bookletId', bookletId);
  //   return this.apiClient.put(API_URL, booklet);
  // }

  //  updateBookletProfileMusking(bookletId: string, payload: any) {
  //   let API_URL: string = this.api_full.concat('booklet/:bookletId');
  //   API_URL = API_URL.replace(':bookletId', bookletId);
  //   return this.apiClient.patch(API_URL, payload);
  // }

  //  uploadBooklet(booklet: any, booklet_id:any) {
  //   let API_URL: string = this.api_full.concat('profile-bulk-upload?user=');
    
  //   API_URL = API_URL.concat(+!localStorage.getItem('bfs_buyerprofile_uid')+'&booklet_id='+booklet_id);
     
  //   return this.apiClient.post(API_URL, booklet);
  // }

  //  retrieveAllBookletsInit(bookletType?: string,page?:number,page_size?:number): Observable<BookletPaginatedListModel> {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   const API_PARAMS: any = {};
  
  //   if (bookletType) {
  //     API_PARAMS.bklt_type = bookletType;
  //   }
    
  //   return this.apiClient.get<BookletPaginatedListModel>(API_URL, { params: API_PARAMS });
  // }

  //  retrievePaginatedBookletData( url: string,) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   const API_PARAMS = url  ;
  //   return this.apiClient.get(API_URL.concat('?' + API_PARAMS));
  //   // return this.apiClient.get(API_PARAMS);
  // }

  //  retrieveAllPaginatedBookletsInit(bookletType: string, pageId: string) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   const API_PARAMS = { bklt_type: bookletType, page: pageId};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllBooklets(bookletType: string, organizedYear: string, organizedMonth: string,) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   const API_PARAMS = { bklt_type: bookletType, oz_year: organizedYear, oz_month: organizedMonth,};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });

  // }
  //  retrieveAllBookletsClient(bookletType: string, organizedYear: string, organizedMonth: string,bookletTitle: string,clientId:number) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   let API_PARAMS
  //   if(organizedYear !== 'YEAR' && clientId !== null  && bookletTitle !== ''){
  //     API_PARAMS = { bklt_type: bookletType ,bklt_title: bookletTitle ,oz_year: organizedYear, oz_month: organizedMonth, client:String(clientId)}
  //   } else if( bookletTitle !== ''  && clientId !== null) {
  //     API_PARAMS = {bklt_type: bookletType ,bklt_title: bookletTitle,client:String(clientId)}
  //   } else if( organizedYear !== 'YEAR' && clientId !== null ){
  //     API_PARAMS = { bklt_type: bookletType ,oz_year: organizedYear, oz_month: organizedMonth, client:String(clientId)}
  //   } else if(clientId !== null ){
  //     API_PARAMS = { bklt_type: bookletType,client:String(clientId) }
  //   } else if(organizedYear !== 'YEAR' ){
  //     API_PARAMS = { bklt_type: bookletType ,oz_year: organizedYear, oz_month: organizedMonth, }
  //   } else if(bookletTitle !== '' ){
  //     API_PARAMS = { bklt_type: bookletType ,bklt_title: bookletTitle }
  //   }
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });

  // }

  //  retrieveAllPaginatedBooklets(bookletType: string, organizedYear: string, organizedMonth: string, pageId: string) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   const API_PARAMS = { bklt_type: bookletType, oz_year: organizedYear, oz_month: organizedMonth, page: pageId };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  // retrieveAllFilterBooklets(data:BookletSearchModel){
  //   const API_URL: string = this.api_full.concat('booklet');
  //   let API_PARAMS: { [key: string]: any }  = {}
  //   for(const item in data){
  //     if (data.hasOwnProperty(item)) {  // Check to ensure it's not inheriting from prototype
  //         // API_PARAMS[item] = data[item];  // Assign the actual value
  //         if (data[item] !== null  ) {
  //           API_PARAMS[item] = data[item]
  //         }
  //     }
  //   }
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllFilterClientBooklets(bookletType: string, organizedYear: string, organizedMonth: string, bookletTitle: string,clientId:number,region:number) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   let API_PARAMS = {bklt_type:'',bklt_title:'',oz_year:'',oz_month:'',client:'',region:''}
  //   // console.log(clientId)
  //   API_PARAMS.bklt_type= bookletType
  //   bookletTitle !== ''? API_PARAMS.bklt_title= bookletTitle  : null
  //   organizedYear !== 'YEAR'?  API_PARAMS.oz_year= organizedYear : null
  //   API_PARAMS['oz_month']= organizedMonth
  //   clientId >= 0 ?  API_PARAMS.client= String(clientId) : null
  //   region >= 0 ?  API_PARAMS.region= String(region): null
    
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  // //  retrieveAllFilterBooklets(bookletType: string, organizedYear: string, organizedMonth: string, bookletTitle: string) {
  // //   const API_URL: string = this.api_full.concat('booklet');
  // //   const API_PARAMS = { bklt_type: bookletType, oz_year: organizedYear, oz_month: organizedMonth, bklt_title: bookletTitle, };
  // //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // // }

  //  retrieveAllClientBooklets(bookletType: string,  bookletTitle: string,clientId:number) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   const API_PARAMS = { bklt_type: bookletType, bklt_title: bookletTitle ,client:String(clientId)};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveNameFilterBooklets(bookletType: string, bookletTitle: string) {
  //   const API_URL: string = this.api_full.concat('booklet');
  //   const API_PARAMS = { bklt_type: bookletType, bklt_title: bookletTitle };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }



  //  retrieveBookletbyID(bookletId: number): Observable<BookletModel> {
  //   let API_URL: string = this.api_full.concat('booklet/:bookletId');
  //   API_URL = API_URL.replace(':bookletId', String(bookletId));
  //   return this.apiClient.get<BookletModel>(API_URL);
  // }

  //  archiveBooklet(bookletId: number, request: any) {
  //   let API_URL: string = this.api_full.concat('booklet/:bookletId/archive_booklet');
  //   API_URL = API_URL.replace(':bookletId', String(bookletId));
  //   return this.apiClient.put(API_URL, request);
  // }

  //  deleteBooklet(bookletId: number){
  //   let API_URL: string = this.api_full.concat(('booklet/:bookletId'));
  //   API_URL = API_URL.replace(':bookletId', String(bookletId));
  //   return this.apiClient.delete(API_URL);
  // }

  //  retrieveAllProfilesByBookletID(bookletId: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { bklt: bookletId };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllProfilesByBookletIDandName(bookletId: string, profileName: string, frozen: any) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   var API_PARAMS = {}
  //   if(frozen){
  //     API_PARAMS = { bklt: bookletId, name: profileName, show_archives: frozen};
  //   } else {
  //     API_PARAMS = { bklt: bookletId, name: profileName};
  //   }
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllPaginatedProfilesByBookletID(bookletId: string, pageId: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { bklt: bookletId, page: pageId};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  restoreBooklet(bookletId: string, request: any) {
  //   let API_URL: string = this.api_full.concat('booklet-archive/:bookletId/restore_booklet');
  //   API_URL = API_URL.replace(':bookletId', bookletId);
  //   return this.apiClient.put(API_URL, request);
  // }

  //  createProfile(profile: any) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   return this.apiClient.post(API_URL, profile);
  // }

  //  updateProfile(profileId: string, profile: any) {
  //   let API_URL: string = this.api_full.concat('profile/:profileId');
  //   API_URL = API_URL.replace(':profileId', profileId);
  //   return this.apiClient.put(API_URL, profile);
  // }

  //  deleteProfile(profileId: number) {
  //   let API_URL: string = this.api_full.concat('profile/:profileId');
  //   API_URL = API_URL.replace(':profileId', String(profileId));
  //   return this.apiClient.delete(API_URL);
  // }

  //  retrieveProfilebyId(profileId: string) {
  //   let API_URL: string = this.api_full.concat('profile/:profileId');
  //   API_URL = API_URL.replace(':profileId', profileId);
  //   return this.apiClient.get(API_URL);
  // }

  //  retrieveAllProfilesInit() {
  //   const API_URL: string = this.api_full.concat('profile');
  //   return this.apiClient.get(API_URL);
  // }

  //  retrieveAllClients():Observable<ClientModel[]> {
  //   const API_URL: string = this.api_full.concat('client');
  //   return this.apiClient.get<ClientModel[]>(API_URL);
  // }

  //  retrieveAllIndustry() {
  //   const API_URL: string = this.api_full.concat('industry');
  //   return this.apiClient.get(API_URL);
  // }


  //  retrieveAllRegion():Observable<RegionModel[]>  {
  //   const API_URL: string = this.api_full.concat('region');
  //   return this.apiClient.get<RegionModel[]>(API_URL);
  // }

 

  //  createClients(client:any) {
  //   const API_URL: string = this.api_full.concat('client');
  //   return this.apiClient.post(API_URL, client);
  // }

  //  getProfileExtras(profileId: string){
  //   const API_URL: string = this.api_full.concat('profile') + '/' + profileId + '/extras';
  //   return this.apiClient.get(API_URL);
  // }

  //  deleteProfileExtras(extrasId: string){
  //   const API_URL: string = this.api_full.concat('profile-extras') + '/' + extrasId;
  //   return this.apiClient.delete(API_URL);
  // }

  //  postProfileExtras(payload: any){
  //   const API_URL: string = this.api_full.concat('profile-extras');
  //   return this.apiClient.post(API_URL, payload);
  // }

  //  associateClient(payload: any):Observable<any> {
  //   const API_URL: string = this.api_full.concat('booklet/add_to_client');
  //   return this.apiClient.post(API_URL, payload);
  // }

  //  removeClient(payload: any):Observable<any> {
  //   const API_URL: string = this.api_full.concat('booklet/'+payload +'/remove_from_client');
  //   return this.apiClient.delete (API_URL, payload);
  // }

  //  patchProfileExtras(payload: { id: string; }){
  //   const API_URL: string = this.api_full.concat('profile-extras') + '/' + payload.id;
  //   return this.apiClient.patch(API_URL, payload);
  // }

  //  retrieveAllProfileExtras(index: string, size: string, name_search: string ) {
  //   var API_URL: string = this.api_full.concat('profile-extras?page_size=100');
  //   API_URL += '?page=' + index + '&page_size=' + size;
  //   if(name_search.length > 0){
  //     API_URL += "&name=" + encodeURIComponent(name_search);
  //   }
  //   return this.apiClient.get(API_URL);
  // }

  //  retrieveAllPaginatedProfilesInit(pageId: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { page: pageId};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllPaginatedProfiles(createdYear: string, createdMonth: string, pageId: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, page: pageId};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }
 

  //  retrievePageProfiles( url: string ) {
  //   const API_URL: string = this.api_full.concat('profile') + url;
  //   // const API_PARAMS = { cm_name: companyName, page: pageId };
  //   return this.apiClient.get(API_URL, {});
  // }

  //  retrievePaginatedProfileData( url: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = url ;
  //   return this.apiClient.get(API_URL.concat('?' + API_PARAMS));
  // }

  //  retrieveCmNameandProfileName( companyName: string, profileName: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { cm_name: companyName, name: profileName} ;
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveCmNameandJobTitle( companyName: string, jobTitle: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { cm_name: companyName, j_title: jobTitle} ;
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveProfileNameandJobTitle( profileName: string, jobTitle: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { name: profileName, j_title: jobTitle} ;
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllFiltersProfileswithoutYr(companyName: string, jobTitle: string, profileName: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { cm_name: companyName, j_title: jobTitle, name: profileName };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllComNameFilterProfiles(createdYear: string, createdMonth: string, companyName: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveComNameFilterProfiles( companyName: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { cm_name: companyName };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveComNameClientFilterProfiles( companyName: string, clientId:number) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { cm_name: companyName , client:String(clientId) };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  // // tslint:disable-next-line: max-line-length
  //  retrieveAllFiltersClientProfiles(createdYear: string, createdMonth: string, companyName: string, jobTitle: string, profileName: string,clientId:number) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, j_title: jobTitle, name: profileName, client:String(clientId) };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }
  //  retrieveAllFiltersProfiles(createdYear: string, createdMonth: string, companyName: string, jobTitle: string, profileName: string,) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, j_title: jobTitle, name: profileName,  };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllFiltersProfilesClient(createdYear: string, createdMonth: string, companyName: string, jobTitle: string, profileName: string,clientId:number) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   let API_PARAMS
  //   if(createdYear !== 'YEAR' && clientId !== null){
  //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId };
  //   } else if(createdYear !== 'YEAR' && clientId !== null && companyName !== ''){
  //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId ,cm_name: companyName, };
  //   } else if(createdYear !== 'YEAR' && clientId !== null && jobTitle !== ''){
  //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId ,j_title: jobTitle, };
  //   }else if(createdYear !== 'YEAR' && clientId !== null && profileName !== ''){
  //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId ,name: profileName, };
  //   }else if(clientId !== null && profileName !== '' && companyName !== ''){
  //     API_PARAMS = { cm_name: companyName, client: clientId ,name: profileName, };
  //   }else if(clientId !== null && profileName !== '' && jobTitle !== ''){
  //     API_PARAMS = { j_title: jobTitle, client: clientId ,name: profileName, };
  //   }else if(clientId !== null && companyName !== '' && jobTitle !== ''){
  //     API_PARAMS = { j_title: jobTitle, client: clientId ,cm_name: companyName, };
  //   } else if(companyName !== '' && clientId !== null){
  //     API_PARAMS = { cm_name: companyName,client: clientId  };
  //   } else if(jobTitle !== '' && clientId !== null){
  //     API_PARAMS = { j_title: jobTitle,client: clientId  };
  //   } else if(profileName !== '' && clientId !== null){
  //     API_PARAMS = { name: profileName,client: clientId  };
  //   } else if(clientId !== null){
  //     API_PARAMS = { client: clientId };
  //   }

  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveCmNamePrflNameFiltersProfiles(createdYear: string, createdMonth: string, companyName: string, profileName: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, name: profileName };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveCmNameJbTitleFiltersProfiles(createdYear: string, createdMonth: string, companyName: string, jobTitle: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, j_title: jobTitle, };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrievePrflNameJbTitleFiltersProfiles(createdYear: string, createdMonth: string, profileName: string, jobTitle: string) {
  //   const API_URL: string = this.api_full.concat('profile');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, name: profileName, j_title: jobTitle};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  copyProfile(profileId: string, request: any) {
  //   let API_URL: string = this.api_full.concat('profile/:profileId/copy_profile');
  //   API_URL = API_URL.replace(':profileId', profileId);
  //   return this.apiClient.put(API_URL, request);
  // }

  //  archiveProfile(profileId: string, request: any) {
  //   let API_URL: string = this.api_full.concat('profile/:profileId/archive_profile');
  //   API_URL = API_URL.replace(':profileId', profileId);
  //   return this.apiClient.put(API_URL, request);
  // }

  //  restoreProfile(profileId: string, request: any) {
  //   let API_URL: string = this.api_full.concat('profile-archive/:profileId/restore_profile');
  //   API_URL = API_URL.replace(':profileId', profileId);
  //   return this.apiClient.put(API_URL, request);
  // }

  // getDownloadBookletParams(custom_ph:string,custom_email:string){
  //   let API_PARAMS = {custom_ph:'',custom_email:''}
  //   if(custom_ph !== ''){
  //     API_PARAMS.custom_ph = encodeURIComponent(custom_ph) 
  //   }
  //   if(custom_email !== ''){
  //     API_PARAMS.custom_email = custom_email 
  //   }
  //   if ( custom_ph !== '' &&  custom_email !== '' ){
  //     API_PARAMS.custom_ph = encodeURIComponent(custom_ph)
  //     API_PARAMS.custom_email = custom_email;
  //   } 
  //   return API_PARAMS
  // }

  //  retrieveBookletCompositebyId(bookletId: string ,custom_ph:string,custom_email:string) {
  //   let API_URL: string = this.api_full.concat('booklet-composite/:bookletId');
  //   API_URL = API_URL.replace(':bookletId', bookletId);
    
  //   return this.apiClient.get(API_URL , {params:  this.getDownloadBookletParams(custom_ph,custom_email)});
  // }

  //  downloadBookletCompositePDF(bookletId: string,custom_ph:string,custom_email:string,masking_profiles_param: string) {
  //   let API_URL: string = this.api_full.concat('booklet-composite/:bookletId/export_custom_booklet');
  //   API_URL = API_URL.replace(':bookletId', bookletId);

  //   if(masking_profiles_param?.length > 0){
  //     API_URL += "?masking_profiles=" + masking_profiles_param;
  //   }
  
  //   return this.apiClient.get(API_URL, {responseType: 'arraybuffer',params:this.getDownloadBookletParams(custom_ph,custom_email)});
  // }

  //  downloadBookletCompositeExcell(bookletId: string,custom_ph:string,custom_email:string,) {
  //   let API_URL: string = this.api_full.concat('booklet-composite/download_booklet?booklet=');
  //   API_URL = API_URL.concat(bookletId);
   
  //   return this.apiClient.get(API_URL, {responseType: 'arraybuffer',params:this.getDownloadBookletParams(custom_ph,custom_email)});
  // }

  //  retrieveProfileCompositebyId(profileId: string,remove_client_logo:string,custom_ph:string,custom_email:string,custom_location:string) {
  //   let API_URL: string = this.api_full.concat('profile-composite/:profileId');
  //   API_URL = API_URL.replace(':profileId', profileId);
  //   let API_PARAMS = {remove_client_logo:'',custom_ph:'',custom_email:'',custom_location:''}
  //   if(remove_client_logo == 'true' ){
  //     API_PARAMS.remove_client_logo = remove_client_logo
  //   }
  //   if(custom_ph !== ''){
  //     API_PARAMS.custom_ph = encodeURIComponent(custom_ph) 
  //   }
  //   if(custom_email !== ''){
  //     API_PARAMS.custom_email = custom_email 
  //   }
  //   if(custom_location !== ''){
  //     API_PARAMS.custom_location = custom_location 
  //   }
 
  //   return this.apiClient.get(API_URL, {params:API_PARAMS });
  // }

  //  downloadProfileCompositePDF(profileId: string,remove_client_logo:string,custom_ph:string,custom_email:string,custom_location:string) {
  //   let API_URL: string = this.api_full.concat('profile-composite/:profileId/export_custom_profile');
  //   API_URL = API_URL.replace(':profileId', profileId);
  //   let API_PARAMS
  //   if (remove_client_logo == 'true' && custom_ph !== '' &&  custom_email !== '' && custom_location !== ''){
  //     API_PARAMS = { remove_client_logo: remove_client_logo,custom_ph:encodeURIComponent(custom_ph),custom_email:custom_email,custom_location:custom_location };
  //   } else if(custom_ph !== '' &&  custom_email !== '' && custom_location !== ''){
  //     API_PARAMS = { custom_ph:encodeURIComponent(custom_ph),custom_email:custom_email,custom_location:custom_location };
  //   } else if(custom_ph !== '' &&  custom_email !== ''){
  //     API_PARAMS = { custom_ph:encodeURIComponent(custom_ph),custom_email:custom_email, };
  //   } else if(custom_email !== '' && custom_location !== ''){
  //     API_PARAMS = { custom_email:custom_email,custom_location:custom_location };
  //   } else if(remove_client_logo == 'true' && custom_email !== '' && custom_location !== ''){
  //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_email:custom_email,custom_location:custom_location };
  //   }else if(remove_client_logo == 'true' && custom_ph !== '' && custom_location !== ''){
  //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_ph:encodeURIComponent(custom_ph),custom_location:custom_location };
  //   } else if(remove_client_logo == 'true' && custom_email !== ''  && custom_location !== ''){
  //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_email:custom_email,custom_location:custom_location };
  //   } else if(remove_client_logo == 'true' && custom_email !== ''  ){
  //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_email:custom_email };
  //   }else if(remove_client_logo == 'true'  && custom_location !== ''){
  //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_location:custom_location };
  //   }else if(remove_client_logo == 'true' && custom_ph !== ''){
  //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_ph:encodeURIComponent(custom_ph), };
  //   }   else if (remove_client_logo == 'true'){
  //     API_PARAMS = { remove_client_logo: remove_client_logo}
  //   } else if(custom_ph !== ''){
  //     API_PARAMS = {custom_ph:encodeURIComponent(custom_ph),}
  //   } else if(custom_email !== ''){
  //     API_PARAMS = {custom_email:custom_email}
  //   } else if(custom_location !== ''){
  //     API_PARAMS = {custom_location:custom_location}
  //   }
  //   return this.apiClient.get(API_URL, {responseType: 'arraybuffer',params:API_PARAMS});
  // }

  //  retrieveAllBookletArchivesInit() {
  //   const API_URL: string = this.api_full.concat('booklet-archive');
  //   return this.apiClient.get(API_URL);
  // }

  //  retrieveAllPaginatedBookletArchivesInit(pageId: string) {
  //   const API_URL: string = this.api_full.concat('booklet-archive');
  //   const API_PARAMS = { page: pageId };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllBookletArchives(organizedYear: string, organizedMonth: string) {
  //   const API_URL: string = this.api_full.concat('booklet-archive');
  //   const API_PARAMS = { oz_year: organizedYear, oz_month: organizedMonth };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllPaginatedBookletArchives(organizedYear: string, organizedMonth: string, pageId: string) {
  //   const API_URL: string = this.api_full.concat('booklet-archive');
  //   const API_PARAMS = { oz_year: organizedYear, oz_month: organizedMonth, page: pageId};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllFilterBookletArchives(organizedYear: string, organizedMonth: string, bookletTitle: string) {
  //   const API_URL: string = this.api_full.concat('booklet-archive');
  //   const API_PARAMS = { oz_year: organizedYear, oz_month: organizedMonth, bklt_title: bookletTitle };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveNameFilterBookletArchives(bookletTitle: string) {
  //   const API_URL: string = this.api_full.concat('booklet-archive');
  //   const API_PARAMS = { bklt_title: bookletTitle };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllProfileArchivesInit() {
  //   const API_URL: string = this.api_full.concat('profile-archive');
  //   return this.apiClient.get(API_URL);
  // }

  //  retrieveAllPaginatedProfileArchivesInit(pageId: string) {
  //   const API_URL: string = this.api_full.concat('profile-archive');
  //   const API_PARAMS = { page: pageId };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllProfileArchives(createdYear: string, createdMonth: string) {
  //   const API_URL: string = this.api_full.concat('profile-archive');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllPaginatedProfileArchives(createdYear: string, createdMonth: string, pageId: string) {
  //   const API_URL: string = this.api_full.concat('profile-archive');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, page: pageId};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveAllFilterProfileArchives(createdYear: string, createdMonth: string, profileName: string) {
  //   const API_URL: string = this.api_full.concat('profile-archive');
  //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, name: profileName};
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveNameFilterProfileArchives(profileName: string) {
  //   const API_URL: string = this.api_full.concat('profile-archive');
  //   const API_PARAMS = { name: profileName };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  retrieveProfileMonthlyStat(createdYear: string, bookletType: string) {
  //   const API_URL: string = this.api_full.concat('stats-profile-monthly');
  //   const API_PARAMS = { crt_year: createdYear, bklt_type: bookletType };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  getStatProList(search: string) {
  //   const API_URL: string = this.api_full.concat('profile/stat_prio_list');
  //   const API_PARAMS = { search: search };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }
  //  getFundedList(search: string) {
  //   const API_URL: string = this.api_full.concat('profile/funded_initiative_list');
  //   const API_PARAMS = { search: search };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }

  //  getJobList(search: string) {
  //   const API_URL: string = this.api_full.concat('profile/job_role_list');
  //   const API_PARAMS = { search: search };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }
  //  getTechList(search: string) {
  //   const API_URL: string = this.api_full.concat('profile/tech_stack_list');
  //   const API_PARAMS = { search: search };
  //   return this.apiClient.get(API_URL, { params: API_PARAMS });
  // }
}
