 
<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>

      <div class="modal-header">
        <h5 class="modal-title" *ngIf="data.type=='Copy'">Copy To</h5>
        <h5 class="modal-title" *ngIf="data.type=='Restore'">Restore To</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="eventCallNo()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
  
         
  
        <!-- Dropdown Buttons for Booklets, Year, and Month -->
        <div class="d-flex justify-content-between mb-1">
            <div class="input-group  me-2">
                <input type="text" class="form-control" placeholder="Booklets" [(ngModel)]="bookletSearchModel.bklt_title  ">
                <button class="btn btn-outline-secondary" (click)="filterName()">
                  <i class="bi bi-search"></i>
                </button>
            </div>
  
          <!-- Booklet Type Dropdown -->
          <div class="dropdown me-2">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownBooklet" data-bs-toggle="dropdown" aria-expanded="false">
              {{selectedBookletType}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownBooklet">
              <li *ngFor="let selBooklet of bookletTypeLists">
                <span class="dropdown-item" (click)="filterBooklet(selBooklet.key)">{{selBooklet.value}}</span>
              </li>
            </ul>
          </div>
  
          <!-- Year Dropdown -->
          <div class="dropdown me-2  ">
            <button class="btn btn-secondary dropdown-toggle  " type="button" id="dropdownYear" data-bs-toggle="dropdown" aria-expanded="false">
              {{selectedYear}}
            </button>
            <ul class="dropdown-menu " aria-labelledby="dropdownYear" placeholder="Year" clearable="true">
          
              <!-- ERROR -->
              <li *ngFor="let year of calendarYears">
                <span class="dropdown-item"   (click)="filterYear(year)">{{year}}</span>
              </li>
            </ul>
          </div>
  
          <!-- Month Dropdown (Disable if Year not selected) -->
          <div class="dropdown w-25" [ngClass]="(selectedYear === 'Year') ? 'disabled' : ''">
            <button class="btn btn-secondary dropdown-toggle w-100" type="button" id="dropdownMonth" data-bs-toggle="dropdown" aria-expanded="false" [disabled]="selectedYear === 'YEAR'">
              {{selectedMonth}}
            </button>
            <ul class="dropdown-menu w-100" aria-labelledby="dropdownMonth">
              <li *ngFor="let month of calendarMonths">
                <span class="dropdown-item" href="#" (click)="filterMonth(month)">{{month}}</span>
              </li>
            </ul>
          </div>

          <button class="btn btn-primary w-25 ms-2" (click)="applyBookletFilter()">Filter</button>
  
        </div>
  
         
  

        
  <!-- Booklet Listing -->
  <div class="listing " *ngIf="bookletResults.length > 0">
    <div class="row m-0 p-0">
      
      <table class="table table-hover table-borderless table-responsive table"  >

        <tbody class="bg-body-tertiary  mainContent" >
          <tr class="shadow-sm overflow-auto rounded bg-primary-light  border-bottom cursor-pointer" (click)="eventCallYes(booklet)"  *ngFor="let booklet of bookletResults ;let i = index" >
            <td width="50"  *ngIf="selectedBookletType == 'CS'" class="align-middle">
                <img class="" width="35" src="../../assets/conference.png" alt="">
            </td>
            <td width="50"  *ngIf="selectedBookletType == 'RT'" class="align-middle">
                <img class="" width="35" src="../../assets/meeting-room.png" alt="">
            </td>
            <td class="align-middle">
              
                <h6 class="m-0 text-primary fw-semibold text-capitalize">{{ booklet.title }}</h6>
                 
            </td>
            <td class="align-middle"> 
              <p class="m-0 text-muted small fst-italic"><i class="bi bi-person me-1"></i> {{booklet.created_by.name}}
            </p>
            </td>
            <!-- <td class="text-muted small"><i class="bi bi-globe me-1"></i>  <span *ngIf="getRegionName(booklet.region) "> {{getRegionName(booklet.region)}} </span></td> -->
            <td class="text-muted small align-middle"><i class="bi bi-calendar4 me-1"></i> {{booklet.created_at | date:'medium'}} </td>
             
             
          </tr>
         
          </tbody>
    </table>
    </div>
  
    <!-- Pagination Controls -->
    <div class="d-flex justify-content-between mt-3">
      <button class="btn btn-secondary" [disabled]="this.bookletInit.previous === null" (click)="previousPage(this.bookletInit.previous)">
        <i class="bi bi-chevron-left"></i> Previous
      </button>
      <button class="btn btn-secondary" [disabled]="this.bookletInit.next === null" (click)="nextPage(this.bookletInit.next)">
        Next <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </div>
  <div class="w-75 m-auto">  <app-no-data-found [show]="!bookletResults || bookletResults?.length == 0"></app-no-data-found>  </div> 
 
      </div>
     
  
  