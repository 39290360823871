/* src/app/profile/profile-details/profile-details.component.scss */
p,
li {
  font-size: 13px;
}
.preview-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: solid 0.5px #ccc;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  margin-bottom: 0;
  opacity: 1;
  overflow: hidden;
  padding: 6px;
  position: relative;
  text-align: left;
  top: 0;
  vertical-align: center;
  white-space: nowrap;
  width: 40px;
  color: #fff !important;
  background-color: #022253 !important;
}
.preview-btn:hover {
  width: 162px;
}
.preview-btn i {
  width: 32px !important;
}
/*# sourceMappingURL=profile-details.component.css.map */
