/* src/app/login/login.component.scss */
.form-signin {
  max-width: 430px;
  padding: 2rem;
  background-color: white;
  margin-top: 20px !important;
}
.form-signin .form-floating:focus-within {
  z-index: 2;
}
.form-signin input[type=email] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type=password] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/*# sourceMappingURL=login.component.css.map */
