/* src/app/shared/add-edit-profile/add-edit-profile.component.scss */
.cursor-pointer {
  cursor: pointer;
}
.profile-inner-division {
  margin-top: 10px;
}
.add-btn {
  filter: invert(0.8);
}
.remove-btn {
  filter: invert(0);
}
::ng-deep .cutomSelect {
  width: 100%;
  height: 30px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: var(--bs-border-radius-pill) !important;
  background-color: #fff;
  padding: 6px 10px;
  line-height: 1 !important;
  font-size: 13px;
}
::ng-deep .cutomSelect.ng-select .ng-arrow-wrapper {
  width: 16px !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel {
  left: 0px;
}
::ng-deep .ng-select-disabled {
  background-color: #e2dddd !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background: #fff;
  top: 11px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  width: 94%;
  left: 10px;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid #dee2e6;
  padding: 6px 10px;
  text-align: start;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #c3c6c9;
}
::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}
/*# sourceMappingURL=add-edit-profile.component.css.map */
