/* src/app/frozen-item/frozen-item.component.scss */
::ng-deep .cutomSelect {
  width: 100%;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: var(--bs-border-radius-pill) !important;
  background-color: #cfd1f9;
  padding: 10px;
  line-height: 1 !important;
  font-size: 13px !important;
}
::ng-deep .ng-select-disabled {
  background-color: #bec0e5 !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel {
  left: 0px;
}
.cursor-pointer {
  cursor: pointer !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background-color: #DBDDFF !important;
  top: 11px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  width: 94%;
  left: 10px;
}
.cursor-pointer {
  cursor: pointer !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid #dee2e6;
  padding: 6px 10px;
  text-align: start;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #ffffff;
}
::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}
table ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container,
table ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: #fff !important;
}
table ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value::before {
  color: #fff !important;
  content: "\f87d";
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  padding-right: 10px;
}
table ::ng-deep .ng-select .ng-clear-wrapper .ng-clear {
  color: #fff !important;
}
::ng-deep .table tr td h6 {
  font-size: 14px;
}
::ng-deep .table tr td p {
  font-size: 12px;
}
.form-control {
  font-size: 13px !important;
}
.custom-input {
  background-color: #cfd1f9 !important;
  height: 34px;
}
.sidebar-filter-btn.btn-outline-primary:hover {
  background-color: #DBDDFF !important;
}
.sidebar-filter-btn.btn-outline-primary {
  color: #022253 !important;
  font-weight: 600;
  border-color: #C2C5EE !important;
}
.sidebar-filter-btn.btn-outline-primary.active {
  background-color: #cfd1f9 !important;
  color: #022253 !important;
  font-weight: 600;
  border-color: #C2C5EE !important;
}
.bi {
  color: #26ADE8;
}
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container,
::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  height: 13px !important;
}
::ng-deep .ng-select .ng-select-container {
  height: 14px !important;
}
/*# sourceMappingURL=frozen-item.component.css.map */
