import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { StatsChartFilters, StatsChartModel } from './stats.model';
import { StatsViewModel } from './stats.view.model';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrl: './stats.component.scss',
  providers: [StatsViewModel, DatePipe]
})
export class StatsComponent implements OnInit {
  serviceResponseProgress:boolean = true
  public chart: any;
  chartData: any = StatsChartModel;
  yearChoices: number[] = [];
  typeChoices = [
    {id: 'CS', name: 'Conference'},
    {id: 'RT', name: 'Roundtable'}
  ];
  statsDataResponse = new BehaviorSubject<[]>([]);
  statsChartFilter = new StatsChartFilters();


  constructor(private statsViewModel: StatsViewModel){
    this.statsChartFilter.crt_year = new Date().getFullYear(); 
    this.statsChartFilter.bklt_type = 'CS';
    this.addYearsToDropdown(this.statsChartFilter.crt_year);
  }
  

  ngOnInit(): void {
    this.statsViewModel.parentStatsComponent = this;
    this.statsViewModel.subscribeToStatsDataResponse(this.statsDataResponse);
    this.getChartData();
  }

  addYearsToDropdown(currentYear: number){
    for (let i = 0; i < 6; i++) {
      this.yearChoices.push(currentYear - i);
    }
  }

  getChartData(){
    this.serviceResponseProgress = true
    this.statsViewModel.getStats(this.statsChartFilter.crt_year, this.statsChartFilter.bklt_type);
  }

  prepairChart(){
    if (this.chart) this.chart.destroy();
    this.chart = new Chart('canvas', this.chartData); 
  }

  dataReceived(){
    this.chartData.data.datasets[0].data = this.statsDataResponse.value.map(x => x['cnt_profile']);
    this.prepairChart();
    this.serviceResponseProgress = false
  }

  typeFilterClicked(id: string){
    this.statsChartFilter.bklt_type = id;
    this.getChartData();
  }

  yearFilterClicked(event: any){
    this.getChartData();
  }

}
