/* src/app/desk-feedback/df-form-modal/df-form-modal.component.scss */
.error-message,
.success-message {
  font-size: 10px;
  top: 28px;
}
.form-control {
  font-size: 14px;
  line-height: 1;
}
.form-label {
  font-size: 12px;
  margin-bottom: 0px;
}
.form-description-mesaage {
  top: 66px;
}
/*# sourceMappingURL=df-form-modal.component.css.map */
