import { Component, inject, OnInit } from '@angular/core';
import { faSearch, faFileExport, faWind,  faMinusCircle , faTicketAlt , faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { NgbAlertModule, NgbModal, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, debounceTime, filter, map, Observable, Subject, switchMap, tap } from 'rxjs';
import { BookletListModel, BookletModel, BookletPaginatedListModel, BookletSearchModel, ClientModel, RegionModel, StandardModel, StandardResponseModal } from '../model/booklet.model';
import { DeleteBookletComponent } from './booklet-modals/delete-booklet/delete-booklet.component';
import { FreezeBookletComponent } from './booklet-modals/freeze-booklet/freeze-booklet.component';
import { Router, RouterModule } from '@angular/router';
import { profileBookletEditBeforeExportComponent } from './booklet-modals/profileBookletEditBeforeExportComponent/profile-booklet-edit-before-export.component';
import { BookletViewModel } from './booklet.viewModel';
 
 

@Component({
  selector: 'app-booklet',
  standalone: false,
  templateUrl: './booklet.component.html',
  styleUrl: './booklet.component.scss'
})

export class BookletComponent implements OnInit {
  calendarMonths = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  currentDate = new Date();
  currentMonth = this.currentDate.getMonth();
  currentYear = this.currentDate.getUTCFullYear();
  calendarYears = [this.currentYear - 2 , this.currentYear - 1, this.currentYear, this.currentYear + 1, ];
  selectedYear = 'YEAR';
  selectedMonthIndex =  this.currentMonth+1;
  selectedMonth = 'Select month';
  serviceResponseProgress:boolean = false
  active = 1;
  ActiveBookletClient =[]
  faSearch= faSearch
  faFileExport = faFileExport
  faWind= faWind
  faMinusCircle = faMinusCircle
  faTicketAlt = faTicketAlt
  faPlusSquare = faPlusSquare
  selectedRegion:any
  selectedClient:any
  bookletClient:any
  booklets$:BookletListModel[] = [];
  searchTimeout:any
  loading$: Observable<boolean> | undefined;
  error$: Observable<any> | undefined;
  selectedBookletType = 'CS';
  actionsSubscription: any;
  pageSize=20
  pageNo=1
 
  bookletSearchModel:BookletSearchModel =
  {
    bklt_type:'CS',
    bklt_title: null,
    client: null,
    region:null,
    oz_month:null,
    oz_year:null
  }
  bookletList = new BehaviorSubject<BookletPaginatedListModel>(new BookletPaginatedListModel())
  regionList = new BehaviorSubject<StandardModel[]>([])
  clientList = new BehaviorSubject<StandardModel[]>([])

  private modalService = inject(NgbModal);
  private searchSubject = new Subject<void>();

  constructor(private vm:BookletViewModel, private router:Router) {
    this.serviceResponseProgress = true
    this.vm.bookletComponent = this
    // document.getElementById('main').classList.add('allow-scroll') 
  }

  ngOnInit(): void {
    this.vm.retrieveAllClients()
    this.vm.retrieveAllRegion()
    this.vm.subscribeBookletList(this.bookletList,this.regionList,this.clientList )
    this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
  }

   
  bookletListRecived(){
    if(this.booklets$.length == 0){
      this.booklets$ = this.bookletList.value.results
    } else{
      this.booklets$.push(...this.bookletList.value.results)
    }
    // console.log( this.booklets$, this.booklets$.length)
    this.serviceResponseProgress = false
  }
  
 associateClient(e:any, booklet:any){
  // console.log(e, booklet.client_list)
  if(e){
    let data = {booklet:booklet.id,client:e,created_by:0};
    data.created_by = Number(localStorage.getItem('bfs_buyerprofile_uid'));
    this.serviceResponseProgress = true;
 
    this.vm.associateClient(data)
  } 
  
  // else if(booklet.client_list.length > 0){
  //   this.serviceResponseProgress = true;
  //   this.vm.removeClient(booklet.client_list[0].association_id )
  // }

 }


 removeClient(booklet:any){
  this.vm.removeClient(booklet.client_list[0].association_id ,booklet)

 }

 clientRemoved(booklet:any){
  this.booklets$.find(e => e.id == booklet.id).client_list = []
 }


 getClientName(id:number){
  return this.clientList.value.find(e => e.id == id ).name
 }
 

 filterBooklet() {
  this.serviceResponseProgress = true;
  this.booklets$ = []
  this.pageNo = 1;
  setTimeout(()=> this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize) , 50 )
}

onInputChange(e:any): void {
  clearTimeout(this.searchTimeout)
  this.searchTimeout  = setTimeout(()=>{
    if(!e){
      this.bookletSearchModel.bklt_title = null
    }   
    this.filterBooklet();
    }, 600)

}

resetFilter(){

  this.bookletSearchModel =
  {
    bklt_type:this.bookletSearchModel.bklt_type,
    bklt_title: null,
    client: null,
    region:null,
    oz_month:null,
    oz_year:null
  }
  this.pageNo = 1;
   this.selectedMonth = 'Select month'
  this.filterBooklet();
}

onValueChange(e:any, identification?):void {
 
  this.filterBooklet();
  if(e == null && identification === 'year'){
    this.bookletSearchModel.oz_month = null
    this.selectedMonth = 'Select Month';
  }
}

 monthChanged(e:any){
  if(e){
    this.bookletSearchModel.oz_month = this.calendarMonths.indexOf(this.selectedMonth)  + 1
    this.filterBooklet();
  }else{
    this.bookletSearchModel.oz_month = null
    this.filterBooklet();
  }
 }

 onbookletScroll(){
  if(this.bookletList.value.next){
    this.serviceResponseProgress = true;
    this.pageNo++
    this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
  }
 }
 
 

  setActive(item:string){

    if(item == 'Conference'){
      this.selectedBookletType = 'CS'
      this.bookletSearchModel.bklt_type = 'CS'
    } else if(item == 'RoundTable'){
      this.selectedBookletType = 'RT'
       this.bookletSearchModel.bklt_type = 'RT'
    }

    this.resetFilter()
    
    setTimeout(()=> this.booklets$ = [] , 100 ) 

  }
 
  

  getRegionName(id: number)  {
    let list = Array.isArray(this.regionList.value) ? this.regionList.value : []; 
    return list.find(region => region.id === id)?.name
  
  }

 

  deleteBooklet(e:Event,selectedItem: BookletListModel){
    e.preventDefault();  // Prevent page from refreshing
    const modalRef = this.modalService.open(DeleteBookletComponent);
     modalRef.componentInstance.data = {
      selectedItemName: selectedItem.title,
      selectedItemId: selectedItem.id,
     };
     
     modalRef.result.then(
      (result) => {
        console.log('Modal closed with result:', result);
        this.pageNo = 1
        this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
      },
      (reason) => {
        // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
        // console.log('Modal dismissed with reason:', reason);
      })
      
    }
    
    freezeDialog(e:Event,selectedItem: BookletListModel){
    e.preventDefault();  // Prevent page from refreshing
    const modalRef = this.modalService.open(FreezeBookletComponent);
     modalRef.componentInstance.data = { 
      selectedItemName: selectedItem.title,
       selectedItemId: selectedItem.id
       }
     
     modalRef.result.then(
      (result) => {
        // Handle the value returned when the modal is closed
        // this.store.dispatch(BookletActions.loadBookletList({booklettype: this.selectedBookletType}));
        this.pageNo = 1
        this.vm.retrieveAllBookletsInit(this.bookletSearchModel,this.pageNo,this.pageSize)
        // console.log('Modal closed with result:', result);
      },
      (reason) => {
        // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
        // console.log('Modal dismissed with reason:', reason);
      })
      
    }
     
    exportBookletDialog(e:Event,booklet:any,path:any,id:any, m_profiles: []) {
      e.preventDefault();  // Prevent page from refreshing
      if(!m_profiles){
        m_profiles = []
      }
  
      if(m_profiles.length > 0){
  
        const PROFILES = m_profiles.join(',')
        let Param = {bklt_id: id, masking_profiles: PROFILES}
        const url = this.router.serializeUrl(this.router.createUrlTree([path, booklet.title] ,{ queryParams: Param} )); 
        window.open(url, '_blank');
        } else {
         const modalRef = this.modalService.open(profileBookletEditBeforeExportComponent);
          modalRef.componentInstance.data = { 
           type:'Booklet',
           selectedItemName: booklet.title,
           selectedItemId: booklet.id,
           path:path,
           booklet:booklet
           }
      }
   
      
    }

    addBooklet(){
      let url= 'add-booklet'
      this.router.navigateByUrl(url)
    }
 
}
