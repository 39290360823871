<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>

<div class="nav-scroller bg-primary shadow-sm px-4 py-3 d-flex justify-content-between align-items-center">
 <div class="w-25"> 
  <h6 class="text-white pb-1 mb-0 lh-1 " [title]="bookletInit$?.title"> {{bookletInit$?.title.length < 37 ? bookletInit$?.title :bookletInit$?.title.slice(0,36) + '....'  }}</h6>
  <p class="small pb-0 mb-0 fst-italic text-white" style="font-size:10px">No. of Profiles: {{profileResults?.length }} of {{ this.profileListModel.value.count }}  </p>
</div>
  <div class="input-group w-25"  >
    <input type="text" class="form-control-sm form-control " placeholder="Search Profile..." [formControl]="searchName" (input)="searchProfile()">
    <button class="btn btn-sm btn-outline-secondary border-light" type="button" (click)="searchProfile()">
      <i class="bi bi-search text-white fw-600 "></i>
      
    </button>
  </div>
  <ul class="nav flex-row mt-0 gap-3 justify-content-between ">

   
    
    <li class="nav-item text-start">
     
     <div class="input-group form-check form-switch mt-1"  >
        <input class="form-check-input cursor-pointer" type="checkbox" id="showFrozenCheckbox" [checked]="showFrozen" (change)="showFrozenCheckbox($event)">
        <label class="form-check-label ms-3 text-white" for="showFrozenCheckbox">Show Frozen</label>
     </div>
      
   </li>
  
   
   <li class="nav-item text-start">
     
    
      <div class="btn-group float-end">
         
        <div class="dropdown">

                <button class="btn btn-light btn-sm rounded-pill dropdown-toggle pe-2" id="dropdownMenuButtonHeader"   type="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-gear text-primary"></i> Actions
                </button>
                <ul class="dropdown-menu p-2"  [attr.aria-labelledby]="'dropdownMenuButtonHeader'"   >
                <li class="text-primary" (click)="uploadBookletDialog()"> <a class="text-decoration-none dropdown-item" href="javascript:void(0)"> <i class="bi bi-upload text-primary fw-800 "></i>  Upload </a>
                   
               </li>
                <li class="text-primary" (click)="editBookletDialog()">  <a class="text-decoration-none dropdown-item" href="javascript:void(0)">  <i class="bi bi-pencil-square text-primary fw-800 "></i> Edit  </a>
                   
                </li>
                <li class="text-primary" (click)="exportBookletDialog(bookletInit$, '/booklet-composite', bookletInit$.id, bookletInit$.masking_profiles)">  <a class="text-decoration-none dropdown-item" href="javascript:void(0)">  <i class="bi bi-download text-primary fw-800"></i> Download  </a>
                   
                </li>
                </ul>
        </div>
      </div>
   </li>
   <li class="nav-item text-start">
     
    <button class="btn btn-light btn-sm  w-100 pe-3  rounded-pill" [routerLink]="['add-profile']">
       <i class="bi bi-plus text-primary fw-800 "></i> Add
     </button> 
  </li>
  </ul>
</div>

<div class="container-fluid px-0 mt-1">
   <div class="row p-0 m-0">
        
      
       <!-- Main Content -->
       <main class="col-md-12 ms-sm-auto col-lg-12  bg-body-tertiary pt-2" style="background-color: #f2f2f2;">
        
        
           <!-- Booklet List -->
           <div class="row p-0 m-0"> 
               <div class="col-12 profile-table-wrapper p-0 ">
                <div  style="height: 74vh !important; overflow-x: hidden; overflow-y: auto;"  *ngIf=" profileResults?.length > 0"
                      infiniteScroll  [fromRoot]="true"
                       [infiniteScrollDistance]="2" [infiniteScrollThrottle]="20" (scrolled)="onProfileScroll()"  [scrollWindow]="false">
                  
               <table class="table table-hover " >
                            <thead>
                              <tr> 
                                <th > <i class="bi bi-list"></i> S/N</th>
                              <th > <i class="bi bi-list"></i> Profile</th>
                              <th style="width:300px"><i class="bi bi-list"></i> Job Title</th>
                              <th style="width:260px"><i class="bi bi-list"></i> Company</th>
                              <th><i class="bi bi-list"></i> Region</th>
                              <th style="width:180px"> <i class="bi bi-list"></i> Masking</th>
                              <th class="text-center"> <i class="bi bi-list"></i> Actions</th>
                            </tr>
                            </thead>
                           <tbody class="mainContent border-bottom border-top" >
                             <tr class=" bg-primary-light "  *ngFor="let profile of profileResults; let i=index;" >
                              <td class="text-center ps-0"> {{i + 1}}</td>
                               <td style="cursor: pointer;">
                                   
                                 <a *ngIf="!showFrozen"  [routerLink]="['/profile', profile.id]" class="text-decoration-none"> 
                                   <div class="d-flex">
                                    <img class="rounded-5 shadow-sm" width="30" height="30" [src]="profile.image" alt="">
                                    <div class="ms-1 p-1 text-primary text-nowrap fw-bold text-capitalize">
                                      {{profile.fullname}}
                                    </div>
                                  </div>
                                </a> 
                                <div class="d-flex" *ngIf="showFrozen">
                                  <img class="rounded-5 shadow-sm" width="30" height="30" [src]="profile.image" alt="">
                                  <div class="ms-1 p-1 text-primary text-nowrap fw-bold text-capitalize">
                                    {{profile.fullname}}
                                  </div>
                                </div>
                               </td>
                               <td class="text-start "> 
                                <p class="m-0 text-muted small fst-italic" [title]="profile.job_title"><i class="bi bi-person-fill "></i> {{profile.job_title.length <30 ? profile.job_title : profile.job_title.slice(0,31) + '...' }}  
                                </p>
                               </td>
                               <td class="text-start"> 
                                <p class="m-0 text-muted small fst-italic"><i class="bi bi-buildings-fill "></i> {{profile.company_name.length < 30 ? profile.company_name : profile.company_name.slice(0,30) + '...' }}
                                </p>
                               </td>
                               <td class="text-muted small  " *ngIf="profile.region.length > 0"><i class="bi bi-globe "></i>  <span > {{profile?.region[0]?.name}} </span></td>
                               <td class="text-muted small   " *ngIf="profile.region.length == 0"><i class="bi bi-globe "></i>  <span > N/A </span></td>
                              
                               <td >
                                 <input class="form-check-input cursor-pointer" type="checkbox" id="maskingCheckbox-{{profile.id}}" (change)="maskingToggle($event, profile)" [checked]="profile?.is_booklet_masking">
                                 <label class="form-check-label ms-3" for="maskingCheckbox-{{profile.id}}">
                                    {{profile?.is_booklet_masking ? 'Profile is Masked' : 'Mask Profile'}}
                                 </label>
                               </td>
                               <td>
                                  
                               
                                 <div class="btn-group  rounded-0 m-auto w-50 d-flex">
                                   <button class="btn btn-light btn-sm rounded-pill " [disabled]="showFrozen" (click)="exportProfileDialog(profile.fullname, '/profile-composite', profile.id)" >
                                     <i class="bi bi-download text-info "></i>
                                   </button>
                                   <div class="dropdown">

                                           <button class="btn btn-light btn-sm rounded-pill dropdown-toggle" id="dropdownMenuButton{{i}}"   type="button"
                                           data-bs-toggle="dropdown" aria-expanded="false">
                                           <i class="bi bi-share text-dark"></i>
                                           </button>
                                           <ul class="dropdown-menu"  [attr.aria-labelledby]="'dropdownMenuButton' + i"   >
                                           <li *ngIf="!showFrozen"><a class="dropdown-item" href="javascript:void(0)" (click)="copyDialog(profile)">
                                               <i class="bi bi-copy text-warning me-2" ></i> Copy</a> </li>
                                           <li *ngIf="!showFrozen"><a class="dropdown-item" href="javascript:void(0)"  (click)="freezeDialog(profile)">
                                               <i class="bi bi-archive-fill text-danger me-2"></i>Freeze</a>
                                           </li>
                                           <li *ngIf="showFrozen"> <a class="dropdown-item" href="javascript:void(0)"  (click)="restoreProfileDialog(profile)">
                                             <i class="bi bi-brilliance text-primary me-2"></i> Restore </a>
                                           </li>
                                           </ul>
                                   </div>
                                 </div>
                               </td>
                             </tr>
                            
                             </tbody>
                       </table>
                      </div>
                      <div class="w-50 m-auto">
                        <app-no-data-found [show]="profileResults  == undefined || profileResults?.length == 0 "></app-no-data-found>  
                      </div>
                    
              
               
               </div>
             
              
           </div>

            
       </main>
   </div>
</div>
 
 
 <!-- <app-booklet-upload-show *ngIf="someError"></app-booklet-upload-show> -->
 