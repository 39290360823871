 
  
    <div class="modal-header">
        <h4 class="modal-title">Copy Profile!</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body text-center">    
         
            <p class="text-secondary h6">Are you sure you want to Copy</p>
            <p class="text-secondary fw-semibold fs-6 text-capitalize">{{data.selectedProfileName}}</p>
            <p class="text-secondary h6">To</p>
            <p class="text-secondary fw-semibold fs-6 text-capitalize">{{data.selectedBookletName}}?</p>
        
    </div>
    <div class="modal-footer">
        
        <button (click)="eventCallNo()" class="btn btn-sm btn-secondary me-2 text-white"  >NO</button>
        <button (click)="eventCallYes()" class="btn btn-sm btn-primary"  >YES</button>
    </div>

 