import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
 
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientModel, RegionModel, StandardModel, StandardResponseModal } from '../../../model/booklet.model';
 
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BookletViewModel } from '../../booklet.viewModel';

@Component({
  selector: 'app-add-region',
  standalone: false,
  templateUrl: './add-region.component.html',
  styleUrl: './add-region.component.scss'
})

export class AddRegionComponent {
	activeModal = inject(NgbActiveModal);
  @Input() data:any
  selectedRegion:any
  regionList = new BehaviorSubject<StandardModel[]>([])
    
 

  constructor( private vm:BookletViewModel,) { }

  ngOnInit(): void {
    this.vm.retrieveAllRegion()
    this.vm.subscribeRegionList(this.regionList)
     
  }

  
  closeDialog(){
    this.activeModal.close('modal closed')
  }

  closeDialogWithData(){
    this.activeModal.close(this.selectedRegion)
  }
}
