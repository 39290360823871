<div class="modal-header">
    <h4 class="modal-title">Delete Booklet!</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
     
        <p class="text-secondary h6">Are you sure you want to delete.</p>
        <p class="text-secondary fw-semibold fs-6 text-capitalize">{{data.selectedItemName}}?</p>
        
    
</div>
<div class="modal-footer">
    <button (click)="activeModal.dismiss()" class="btn btn-sm btn-secondary me-2 text-white"  >NO</button>
    <button (click)="deleteBooklet()" class="btn btn-sm btn-primary"  >YES</button>
</div>