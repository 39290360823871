<app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>

<div class="container">
    <div class="row">
      
      <!-- Main content -->
      <div class="col-md-12 col-lg-12">

        <button type="button" *ngIf="data" class="btn-close position-absolute top-0 m-2 end-0 bg-white text-primary" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
        <div class="profile-inner-division">
  
          <!-- Profile Form -->
          <form *ngIf="newProfileForm" [formGroup]="newProfileForm">
            <div class="row">
              
              <!-- Profile Image Section -->
              <div class="col-md-3 d-flex flex-column align-items-center bg-body-secondary py-4 px-4">
                <img [src]="profileImageEncoded" width="200" height="200" (click)="compressFile()" class="rounded-5 img-fluid d-block mb-5 float-start bg-light border-1">
                <p *ngIf="imgResult.length > 1" class="text-danger text-center" style="font-size: 9px; margin-top: 10px;"> Format error. Only png, jpg are supported. </p>
                <button *ngIf="imgResult.length > 1" class="btn btn-danger btn-block mt-3 mb-4" (click)="removeImage()">
                  Remove Image <i class="bi bi-x-circle"></i>
                </button>

                <div class="row">
                    <!-- Full Name -->
                    <div class="col-md-12 mb-3">
                      <label class="form-label fs-6 fw-bold"><i class="bi bi-person-circle text-primary"></i> Full Name <span class="text-danger ms-1">*</span></label>
                      <input type="text" class="form-control form-control-sm rounded-pill" formControlName="fullName">
                    </div>
                    
                    <!-- Company Name -->
                    <div class="col-md-12 mb-3">
                      <label class="form-label fs-6 fw-bold"><i class="bi bi-building-fill text-primary"></i> Company Name <span class="text-danger ms-1">*</span></label>
                      <input type="text" class="form-control form-control-sm rounded-pill" formControlName="companyName">
                    </div>
                    
                    <!-- Job Title -->
                    <div class="col-md-12 mb-3">
                      <label class="form-label fs-6 fw-bold"><i class="bi bi-journal-album text-primary"></i> Job Title <span class="text-danger ms-1">*</span></label>
                      <input type="text" class="form-control form-control-sm rounded-pill" formControlName="jobTitle">
                    </div>
                    
                    <!-- Phone -->
                    <div class="col-md-12 mb-3">
                      <label class="form-label fs-6 fw-bold"><i class="bi bi-phone-fill text-primary"></i> Phone <span class="text-danger ms-1">*</span></label>
                      <input type="text" class="form-control form-control-sm rounded-pill" formControlName="contactNo">
                    </div>
    
                   
                  </div>
              </div>
  
              <!-- Profile Details Section (Full Name, Company Name, Job Title, Phone, Email, LinkedIn) -->
              <div class="col-md-9 bg-light p-4">
                <div class="row m-2 p-1">
                    <!-- Company Website -->
                    <div class="card col-md-12"> 
                      <div class="card-body row m-0 p-1 pb-3" > 
                      <div class="col-md-6 mb-3 mt-2">
                        <label class="form-label fs-6 fw-bold"><i class="bi bi-browser-chrome text-primary"></i> Company Website <span class="text-danger ms-1">*</span></label>
                        <input type="url" class="form-control form-control-sm rounded-pill" formControlName="companyWebsite">
                      </div>
                      
                      <!-- Company Industry --> 
                      <div class="col-md-6 mb-3 mt-2">
                        <label class="form-label fs-6 fw-bold"><i class="bi bi-buildings-fill text-primary"></i> Company Industry <span class="text-danger ms-1">*</span> </label>
                        <ng-select class="form-select form-select-sm cutomSelect" formControlName="industry">
                          <ng-option *ngFor="let industry of industryList" [value]="industry.id">{{ industry.name }}</ng-option>
                        </ng-select>
                      </div>
          
                      
                      <!-- Email -->
                      <div class="col-md-6 mb-3">
                          <label class="form-label fs-6 fw-bold"><i class="bi bi-envelope-at-fill text-primary"></i> Email <span class="text-danger ms-1">*</span></label>
                          <input type="email" class="form-control form-control-sm rounded-pill" formControlName="emailAddress" (blur)="checkDuplicate($event, 'email')">
                        </div>
        
                        <!-- LinkedIn -->
                        <div class="col-md-6 mb-3">
                          <label class="form-label fs-6 fw-bold"><i class="bi bi-linkedin text-primary"></i> Linkedin </label>
                          <input type="url" class="form-control form-control-sm rounded-pill" formControlName="linkedinUrl" (blur)="checkDuplicate($event ,'linkedin')" >
                        </div>
                        
                        <!-- Company Address -->
                      <div class="col-md-12 mb-2">
                          <label class="form-label fs-6 fw-bold"><i class="bi bi-house-add-fill text-primary"></i> Address <span class="text-danger ms-1">*</span></label>
                          <textarea type="text" class="form-control form-control-sm rounded-4 p-3" [class.border-danger]="addressLimitExceed" (change)="addressChange()" formControlName="companyAddress"> </textarea>
                          <p class="text-danger small mt-2 mb-0" *ngIf="addressLimitExceed"> Address text max limit (200) exceeded.</p>
                        </div>
                      </div>
                    </div>
                </div>
                   <!-- Role -->
                    <div class="row m-2 p-1"> 
                    <div class="card col-md-12 ">
                      <div class="card-body p-2">
                        <label class="form-label fs-6"><strong class="fs-6"><i class="bi bi-person-rolodex text-primary"></i> Role</strong></label>
                        <textarea rows="3" class="form-control form-control-sm rounded-4 mb-2 p-3" formControlName="jobRole"></textarea>
                      </div>
                    </div>
                  </div>
                  <!-- Strategic Priorities -->
                <div class="row m-2 p-1"> 
                  <div class="card col-md-12 ">
                
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <strong class="fs-6"><i class="bi bi-award-fill  text-primary"></i> Strategic Priorities</strong>
                      <div>
                        <img width="20" (click)="addPriorities()" src="assets/add-new.png" class="add-btn cursor-pointer">
                       
                      </div>
                    </div>
                    <div *ngFor="let priority of getStrategicPrioritesFormControls(); let i = index" class="mb-3 mt-2">
                      <label class="form-label fs-6">Strategic Priority {{ i + 1 }}</label>
                      <!-- ERROR -->
                    <div class="d-flex justify-content-between align-items-center" > 
                       <input type="text" class="form-control form-control-sm rounded-pill me-2" [formControl]="priority">
                       <img width="20" (click)="removePriorities(i)" src="assets/remove.png" class="remove-btn cursor-pointer">
                    </div>
                    </div>
                  </div>
                
                </div>
                </div>
      
             
    
              <!-- Tech Stack -->
              <div class="row m-2 p-1"> 
              <div class="card col-md-12 ">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center">
                    <strong class="fs-6"><i class="bi bi-stack text-primary"></i>Tech Stack</strong>
                    <div>
                      <img width="20" (click)="addTechStack()" src="assets/add-new.png" class="add-btn   cursor-pointer">
                     
                    </div>
                  </div>
                  <div *ngFor="let techstack of getTechStackFormControls(); let i = index" class="mb-3 mt-2">
                    <label class="form-label fs-6">Tech {{ i + 1 }}</label>
                    <!-- Error -->
                    <div class="d-flex justify-content-between align-items-center " > 
                    <input type="text" class="form-control form-control-sm rounded-pill me-2" [formControl]="techstack">
                    <img width="20" (click)="removeTechstack(i)" src="assets/remove.png" class="remove-btn cursor-pointer">
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <!-- Funded Initiatives -->
              <div class="row m-2 p-1"> 
              <div class="card  col-md-12">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center">
                    <strong class="fs-6"><i class="bi bi-piggy-bank-fill text-primary"></i> Funded Initiatives</strong>
                    <div>
                      <img width="20" (click)="addInitiatives()" src="assets/add-new.png" class="add-btn   cursor-pointer">
                    
                    </div>
                  </div>
                  <div *ngFor="let initiative of getInitiativeFormControls(); let i = index" class="mb-3 mt-2">
                    <label class="form-label fs-6">Initiative {{ i + 1 }}</label>
                    <!-- Error -->
                    <div class="d-flex justify-content-between align-items-center" > 
                    <input type="text" class="form-control form-control-sm rounded-pill me-2" [formControl]="initiative">
                    <img width="20" (click)="removeInitiative(i)" src="assets/remove.png" class="remove-btn cursor-pointer">
                    </div>
                  </div>
                </div>
              </div>
              </div>
    
              <!-- Custom Fields -->
              <div class="row m-2 p-1"> 
              <div class="card col-md-12">
                <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center">
                    <strong class="fs-6"><i class="bi bi-backpack4-fill text-primary"></i> Others</strong>
                    <div>
                      <img width="20" (click)="addCustomFields()" src="assets/add-new.png" class="add-btn   cursor-pointer">
                     
                    </div>
                  </div>
                  <div *ngFor="let control of getCustomFormControls().controls; let i = index" class="mb-3 mt-2">
                   <div class="d-flex justify-content-between align-items-center" >   
                    <div class="me-4 w-100"> 
                    <label class="form-label fs-6">
                      {{ 'Header ' + (i + 1) }} 
                    </label>
                    <input type="text" class="form-control form-control-sm rounded-pill" 
                           [formControl]="castToFormControl(control.get('header'))" 
                           placeholder="Enter header">
                
                    <label class="form-label fs-6 mt-2"> {{ 'Value ' + (i + 1) }}  </label>
                    <input type="text" class="form-control form-control-sm rounded-pill" 
                           [formControl]="castToFormControl(control.get('value'))" 
                           placeholder="Enter value">
                    </div>
                    <img width="20" (click)="removeCustomFields(i)" src="assets/remove.png" class="remove-btn cursor-pointer">
                  </div>
                </div>
                </div>
              </div>
              </div>
              <div class="row m-2 p-1"> 
              <div class="card col-md-12" *ngIf="enableExtra">
                <div class="card-body" >
                <div class="d-flex align-items-center  justify-content-between" >
                  <strong class="fs-6"><i class="bi bi-backpack2-fill text-primary"></i> Extras</strong>
                  <div>
                    <img width="20" *ngIf="enableExtraAddButton" (click)="addProfileExtras()" src="assets/add-new.png" class="add-btn   cursor-pointer">
                    <img width="20" *ngIf="!enableExtraAddButton"  (click)="removeProfileExtras()" src="assets/remove.png" class="remove-btn cursor-pointer">
                  </div>
                  
                </div>
              
                <!-- Extras Form Section (Visible only if Add button is clicked) -->
                <div *ngIf="!enableExtraAddButton" class="mt-4"> 
                  <!-- Primary business activity -->
                  <div class="mb-3" *ngFor="let field of getProfileExtraControls() | keyvalue ; let i = index">
                    <label class="form-label fs-6 text-capitalize">{{ field.key.replaceAll('_', ' ') }}</label>
                    <div class="d-flex justify-content-between align-items-center" >  
                        <textarea type="text" class="form-control form-control-sm rounded-4 me-2 p-3" [formControl]="field.value"></textarea>
                       
                    </div>
                  </div>
                   

                  
                </div>
                </div>
              </div>
              </div>
              </div>
  
            </div>
  
             
  
          </form>
  
          <!-- Form Buttons -->
          <div class="d-flex justify-content-end mt-4">
            
            <button class="btn btn-primary me-3" (click)="activeModal.dismiss('Cross click')"> <i class="bi bi-x-circle-fill me-2"></i> Close</button>
            <button class="btn btn-danger me-3" (click)="resetForm()"> <i class="bi bi-arrow-counterclockwise me-2"></i> Reset</button>
            <button class="btn btn-secondary " [disabled]="duplicateValue" (click)="addProfile()"><i class="bi bi-send-check-fill me-2"></i> Sumbit</button>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
  
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Duplicate Found!</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
           
        
            <p>{{duplicateMsg?.result}} </p>
           
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')">No</button>
      <button type="button" class="btn btn-secondary" (click)="modal.close('Save click')">Yes</button>
    </div>
  </ng-template>