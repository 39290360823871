import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, filter } from 'rxjs';
import { EditBookletDialogComponent } from '../booklet/booklet-modals/edit-booklet-dialog/edit-booklet-dialog.component';
import { profileBookletEditBeforeExportComponent } from '../booklet/booklet-modals/profileBookletEditBeforeExportComponent/profile-booklet-edit-before-export.component';
import { UploadBookletDialogComponent } from '../booklet/booklet-modals/upload-booklet-dialog/upload-booklet-dialog.component';
import { BookletViewModel } from '../booklet/booklet.viewModel';
import { BookletModel, StandardModel } from '../model/booklet.model';
import { ProfilePaginatedListModel, ProfileListModel, ProfileSearchModel } from '../model/profile.model';
import { CopyProfileComponent } from '../shared/copy-profile/copy-profile.component';
import { FreezeProfileComponent } from './profile-modals/freeze-profile/freeze-profile.component';
import { ProfileViewModel } from './profile.viewModel';

@Component({
  selector: 'app-profile',
  standalone: false,
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  calendarMonths = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  currentDate = new Date();
  currentMonth = this.currentDate.getMonth();
  currentYear = this.currentDate.getUTCFullYear();
  calendarYears = [this.currentYear - 2 , this.currentYear - 1, this.currentYear, this.currentYear + 1, ];
  selectedYear = 'YEAR';
  selectedMonthIndex =  this.currentMonth+1;
  selectedMonth = 'Select month';
  serviceResponseProgress:boolean = false
  faSearch= faSearch
  faAdd = faPlus
  someError:boolean = false
   
  profileListModel = new BehaviorSubject<ProfilePaginatedListModel>(new ProfilePaginatedListModel())
  bookletInit$: BookletModel;
  profilesInit$: any ;
  profileResults = [];
  bookletId: string | undefined;
  selectorSrool: string = ".mainContent";
  previousPageId: string | undefined;
  nextPageId: string | undefined;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  searchName = new FormControl('');
  showFrozen: boolean = false;
  private modalService = inject(NgbModal);
  actionsSubscription: any;
  page:number = 1
  page_size:number = 20
  regionList = new BehaviorSubject<StandardModel[]>([])
  clientList = new BehaviorSubject<StandardModel[]>([])
  industryList = new BehaviorSubject<StandardModel[]>([])

  profileSearchModel:ProfileSearchModel = {
    deep_search:null,
    string:null,
    cm_name:null,
    j_title: null,
    client: null,
    industry:null,
    region:null,
    crt_month:null,
    crt_year:null
  }
  searchTimeout:any
  
  constructor( private appActiveRoute: ActivatedRoute, private router: Router,private vm:ProfileViewModel,) { 
    this.serviceResponseProgress = true
    this.vm.profileComponent = this
    // document.getElementById('main').classList.add('allow-scroll') 
  }

  ngOnInit() {
    this.bookletId = this.appActiveRoute.snapshot.paramMap.get('Id') ;
    
    this.vm.subscribeProfileList(this.regionList, this.clientList, this.industryList , this.profileListModel) 
    
    this.vm.retrieveAllClients()
    this.vm.retrieveAllRegion()
    this.vm.retrieveAllIndustry('profile')

    this.vm.retrieveAllProfile(this.profileSearchModel,this.page_size,this.page)
  
   
    

    this.router.events
    .pipe(
      filter(event => event instanceof NavigationStart) // Filter NavigationStart events
    )
    .subscribe((event: NavigationStart) => {
      if (event.url && event.url.startsWith('/profile')) {
        localStorage.setItem('fromBooklet', 'true');
        localStorage.setItem('BookletID', String(this.bookletId));
      } else {
        localStorage.removeItem('fromBooklet');
      }
    });
    
  }

  resetFilter(){
    this.profileSearchModel = {
      deep_search:null,
      string:null,
      cm_name:null,
      j_title: null,
      client: null,
      industry:null,
      region:null,
      crt_month:this.currentMonth,
      crt_year:null
    }
    this.page = 1
    this.profileResults = undefined
    this.filterProfile();
  }
   
  
profileSearch(e:any): void {
  clearTimeout(this.searchTimeout)
  this.searchTimeout  = setTimeout(()=>{
    
    if(e.length == 0){
     this.profileSearchModel.deep_search = null
    } else{
      this.profileSearchModel.deep_search = true
    } 
 
    this.filterProfile();
  }, 700)
  
 }

onInputChange(e:any): void {
  clearTimeout(this.searchTimeout)
     this.searchTimeout  = setTimeout(()=>{  
      this.filterProfile();
      }, 600)
 }
 
 onValueChange(e:any, identification?):void {
  setTimeout(()=> this.filterProfile() , 700 );
  if(e == null && identification === 'year'){
    this.profileSearchModel.crt_month  = null
    this.selectedMonth = 'Select month';
  }
 }
 
  monthChanged(e:any){
   if(e){
     this.profileSearchModel.crt_month = this.calendarMonths.indexOf(this.selectedMonth) + 1
     this.filterProfile();
   }else{
     this.profileSearchModel.crt_month = null
     this.filterProfile();
   }
    
  }
 
  filterProfile(){
    this.serviceResponseProgress = true;
    this.page = 1
    this.profileResults = undefined
    this.vm.retrieveAllProfile(this.profileSearchModel,this.page_size,this.page)
  }

  profileListRecived(){
    
    this.serviceResponseProgress = false;
    this.profilesInit$ = this.profileListModel.value;  
    if(this.profileResults == undefined){
      this.profileResults = this.profileListModel.value.results;
    } else{
      this.profileResults.push(...this.profileListModel.value.results);
    }

  }

  onProfileScroll(){
    if(this.profileListModel.value.next){
      this.serviceResponseProgress = true;
      this.page++
      this.vm.retrieveAllProfile(this.profileSearchModel,this.page_size,this.page)
    }
  }
 
  
  

  maskingToggle(event: any, profile: ProfileListModel) {
    const updateMaskingProfiles = (maskingProfiles: number[], profileId: number, add: boolean): any[] => {
      return add 
        ? [...new Set([...maskingProfiles, profileId])]  // Adds the element only if it doesn't exist
        : maskingProfiles.filter(id => id !== profileId); // Removes the element if it exists
    };
    // console.log(this.bookletInit$, updateMaskingProfiles)
    if (!this.bookletInit$) {
      console.error('bookletInit$ is undefined');
      return;
    }

    var payload = {
      "masking_profiles": this.bookletInit$.masking_profiles as any
    }
    if (event.target.value){
      payload['masking_profiles'] = updateMaskingProfiles(payload['masking_profiles'], profile.id, true); 
    } else {
      payload['masking_profiles'] = updateMaskingProfiles(payload['masking_profiles'], profile.id, false)
    }

    this.serviceResponseProgress = true;
    profile.is_booklet_masking =  event.target.value;
    this.bookletInit$.masking_profiles = payload['masking_profiles']
    this.vm.updateBookletProfileMusking(String(this.bookletInit$.id), payload)
  
  }

  profileMasked(){
    this.serviceResponseProgress = false;
   
  }


  updateMaskingInfoOnprofiles(masking_profiles: number[]){
    this.profileResults?.forEach(element => {
        if((masking_profiles || []).includes(element.id)){
          element.is_booklet_masking = true;
        } else {
          element.is_booklet_masking = false;
        }
    });
    // console.log( 
  }

  setBookletBack(){
    localStorage.setItem('redirectFrom', 'booklet')
  }

 
 
  filterName() {
    this.serviceResponseProgress = true;
    this.page = 1
    this.vm.retrieveAllProfile(this.profileSearchModel,this.page_size,this.page)
  }

   
 

  copyDialog(selectedItem: ProfileListModel) {
     
    const modalRef = this.modalService.open(CopyProfileComponent,{size:'xl'});
    modalRef.componentInstance.data = {type:'Copy', selectedProfileName: selectedItem.fullname, selectedProfileId: selectedItem.id }
    
    modalRef.result.then(
     (result) => {
       // Handle the value returned when the modal is closed
       this.profileResults = undefined
      // this.store.dispatch(BookletActions.retrieveAllProfilesByBookletID({id:String(this.bookletId)}))
      this.filterName();
       console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
       console.log('Modal dismissed with reason:', reason);
     })
  }
 
  exportProfileDialog(fullname:any,path:any,id:any) {
    const modalRef = this.modalService.open(profileBookletEditBeforeExportComponent);
    modalRef.componentInstance.data = { selectedItemName: fullname ,path : path,  selectedItemId: id,type:'Profile',}
   
    modalRef.result.then(
     (result) => {
       // Handle the value returned when the modal is closed
       this.profileResults = undefined
      //  this.store.dispatch(BookletActions.loadBookletList({booklettype: this.selectedBookletType}));
      this.filterName();
       console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
       console.log('Modal dismissed with reason:', reason);
     })
  }
}
