 
  <div class="modal-header">
    <h4 class="modal-title">Create client</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
  
  <div class="modal-body">
    <div class="container">
  
      <ng-container>
        <!-- If not creating a client -->
        <!-- <div *ngIf="!makeClient" class="d-flex justify-content-between align-items-center">
          <div class="mb-3 w-50">
            <label for="clientSelect" class="form-label">Select a client</label>
            <ng-select [(ngModel)]="selectedClient" [items]="clientList.value" class="form-select ms-2 cutomSelect  " bindLabel="name" bindValue="id">
            </ng-select>
          </div>
  
          <button class="btn btn-primary" (click)="showCreateClientFn()">Create A Client</button>
        </div> -->
  
        <!-- If creating a client -->
        <div *ngIf="makeClient" >

          <form [formGroup]="form" (ngSubmit)="createClient()">
            <div class="mb-3" >
              <label for="clientName" class="form-label small">Name</label>
              <input type="text" class="form-control" id="clientName" formControlName="clientName" maxlength="50" placeholder="Client name">
            </div>
  
            <div class="mb-3" >
              <label for="clientUrl" class="form-label small">Url</label>
              <input type="text" class="form-control" id="clientUrl" formControlName="clientUrl" placeholder="Client url">
            </div>
  
            <br/>
            <button type="submit" class="btn btn-primary d-flex align-items-center m-auto" [disabled]="!form.valid" style="margin-bottom: 20px;">
              Submit
              <i class="ms-2 bi bi-arrow-right"></i>
            </button>
          </form>
        </div>
      </ng-container>
  
    </div>
  </div>
  
  <div class="modal-footer justify-content-center">
    <!-- <button class="btn btn-secondary" (click)="closeDialogWithData()">Save</button> -->
    <button  class="btn btn-primary" (click)="closeDialog()">Close</button>
  </div>