import { Component, ViewEncapsulation } from '@angular/core';
import { ProfileCompositeModel } from '../../model/profile.model';
import { ActivatedRoute } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';
import { ProfileViewModel } from '../profile.viewModel';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-profile-composite',
  templateUrl: './profile-composite.component.html',
  styleUrl: './profile-composite.component.scss',
 
})
export class ProfileCompositeComponent {
  serviceResponseProgress = true;
  srpColor = 'primary';
  srpMode = 'indeterminate';
  srpValue = 60;

  profileId: string;
  profileName: string;
  remove_client_logo:string
  custom_ph:string
  custom_email:string
  custom_location:string
  profileCompositeInit:any
  profileCompositeModel = new BehaviorSubject<ProfileCompositeModel>(new ProfileCompositeModel())
  profileComposite : any =   {
    remove_client_logo: null,
    custom_ph: null,
    custom_email: null,
    custom_location: null 
  }
  constructor(private appActiveRoute: ActivatedRoute, private vm: ProfileViewModel, private fileSaverService: FileSaverService) { 
    this.vm.profileCompositeComponent = this
    this.serviceResponseProgress = true;
 
  }
  

  ngOnInit() {
    this.loadData()
  
      this.profileComposite.remove_client_logo= this.remove_client_logo,
      this.profileComposite.custom_ph= this.custom_ph,
      this.profileComposite.custom_email= this.custom_email,
      this.profileComposite.custom_location= this.custom_location 
    this.vm.subscribeProfileComposite(this.profileCompositeModel)
    this.vm.retrieveProfileCompositebyId(this.profileId,this.profileComposite)

     
  }

  profilepreviewReceived(){
    this.profileCompositeInit = this.profileCompositeModel.value
    console.log(this.profileCompositeModel.value , this.profileCompositeInit )
    this.serviceResponseProgress = false
  }

  downloadPDF() {
    this.serviceResponseProgress = true;
    const fileName = this.profileCompositeInit.fullname + '.pdf';
    this.vm.downloadProfileCompositePDF(this.profileId,this.profileComposite, fileName)

  }

  loadData(){
    this.profileId = this.appActiveRoute.snapshot.queryParamMap.get('prfl_id') == null ? '' : this.appActiveRoute.snapshot.queryParamMap.get('prfl_id');
    this.profileName = this.appActiveRoute.snapshot.paramMap.get('name')  == null ? '' : this.appActiveRoute.snapshot.paramMap.get('name');
    this.remove_client_logo = this.appActiveRoute.snapshot.queryParamMap.get('remove_client_logo') == null ? '' : this.appActiveRoute.snapshot.queryParamMap.get('remove_client_logo');
    this.custom_ph = this.appActiveRoute.snapshot.queryParamMap.get('custom_ph_no') == null ? '' : this.appActiveRoute.snapshot.queryParamMap.get('custom_ph_no')  ;
    this.custom_email = this.appActiveRoute.snapshot.queryParamMap.get('custom_email_address') == null ? '' : this.appActiveRoute.snapshot.queryParamMap.get('custom_email_address') ;
    this.custom_location = this.appActiveRoute.snapshot.queryParamMap.get('custom_location_address')  == null ? '' : this.appActiveRoute.snapshot.queryParamMap.get('custom_location_address');
    console.log(this.profileId,this.profileName ,this.remove_client_logo,this.custom_ph,this.custom_email,this.custom_location  )
  }

  downloadSuccess(){
    this.serviceResponseProgress = false;
  }

  downloadFailed(){
    this.serviceResponseProgress = false;
  }

}
