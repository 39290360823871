import { Component, inject, OnInit } from '@angular/core';
import { BookletModel } from '../../model/booklet.model';
import { ProfileListModel, ProfilePaginatedListModel } from '../../model/profile.model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router, RouterEvent, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UploadBookletDialogComponent } from '../booklet-modals/upload-booklet-dialog/upload-booklet-dialog.component';
import { EditBookletDialogComponent } from '../booklet-modals/edit-booklet-dialog/edit-booklet-dialog.component';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
 
import { BookletViewModel } from '../booklet.viewModel';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FreezeProfileComponent } from '../../profile/profile-modals/freeze-profile/freeze-profile.component';
import { CopyProfileComponent } from '../../shared/copy-profile/copy-profile.component';
import { profileBookletEditBeforeExportComponent } from '../booklet-modals/profileBookletEditBeforeExportComponent/profile-booklet-edit-before-export.component';
import { ProfileArchiveListModel } from '../../model/archive.model';

@Component({
  selector: 'app-booklet-details',
  standalone: false,
  templateUrl: './booklet-details.component.html',
  styleUrl: './booklet-details.component.scss'
})
export class BookletDetailsComponent implements OnInit {

  serviceResponseProgress = true;
  faSearch= faSearch
  faAdd = faPlus
  someError:boolean = false
  bookletDetails = new BehaviorSubject<BookletModel>(new BookletModel())
  profileListModel = new BehaviorSubject<ProfilePaginatedListModel>(new ProfilePaginatedListModel())
  bookletInit$: BookletModel;
  profilesInit$: any ;
  profileResults =[];
  bookletId: string | undefined;
  selectorSrool: string = ".mainContent";
  previousPageId: string | undefined;
  nextPageId: string | undefined;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  searchName = new FormControl('');
  showFrozen: boolean = false;
  private modalService = inject(NgbModal);
  actionsSubscription: any;
  page:number = 1
  page_size:number = 20
  searchTimeout:any
  constructor( private appActiveRoute: ActivatedRoute, private router: Router,private vm:BookletViewModel,) { 
    this.serviceResponseProgress = true
    this.vm.bookletDetailsComponent = this
  }

  ngOnInit() {
    this.bookletId = this.appActiveRoute.snapshot.paramMap.get('Id') ;
    
    this.vm.subscribeBookletDetails(this.bookletDetails, this.profileListModel)  
  
    this.vm.retrieveBookletbyID(Number(this.bookletId))
    
    
      
    this.vm.retrieveAllProfilesByBookletID(Number(this.bookletId), this.searchName.value, this.showFrozen,this.page_size,this.page)
      
      
        // this.profilesInit = data1 as any as ProfilePaginatedListModel;
        // this.profileResults = this.profilesInit.results;
        // this.updateMaskingInfoOnprofiles(this.bookletInit.masking_profiles);
        // this.serviceResponseProgress = false;
       
    

    this.router.events
    .pipe(
      filter(event => event instanceof NavigationStart) // Filter NavigationStart events
    )
    .subscribe((event: NavigationStart) => {
      if (event.url && event.url.startsWith('/profile')) {
        localStorage.setItem('fromBooklet', 'true');
        localStorage.setItem('BookletID', String(this.bookletId));
      } else {
        localStorage.removeItem('fromBooklet');
      }
    });
    
  }


  profilesByBookletIdReceived(){
    
    
    this.profilesInit$ = this.profileListModel.value;  
    if(this.profileResults == undefined){
      this.profileResults = this.profileListModel.value.results;
    } else{
      this.profileResults.push(...this.profileListModel.value.results);
    }
    this.updateMaskingInfoOnprofiles(this.bookletInit$?.masking_profiles);
    this.page > 1 && (this.serviceResponseProgress = false) ;
  }

  onProfileScroll(){
    if(this.profileListModel.value.next){
      this.serviceResponseProgress = true;
      this.page++
      this.vm.retrieveAllProfilesByBookletID(Number(this.bookletId), this.searchName.value, this.showFrozen,this.page_size,this.page)
    }
  }
 
  retrieveBookletbyIdReceived(){
    this.bookletInit$ = this.bookletDetails.value ;
    this.serviceResponseProgress = false;
    if (!this.bookletInit$.masking_profiles){
        this.bookletInit$.masking_profiles = []
      }
    this.updateMaskingInfoOnprofiles(this.bookletInit$.masking_profiles);
    
    // console.log(this.bookletInit$)
  }

  exportBookletDialog(booklet:any,path:any,id:any, m_profiles: []) {

    if(!m_profiles){
      m_profiles = []
    }

    if(m_profiles.length > 0){

      const PROFILES = m_profiles.join(',')
      let Param = {bklt_id: id, masking_profiles: PROFILES}
      const url = this.router.serializeUrl(this.router.createUrlTree([path, booklet.title] ,{ queryParams: Param} )); 
      window.open(url, '_blank');
      } else {
       const modalRef = this.modalService.open(profileBookletEditBeforeExportComponent);
        modalRef.componentInstance.data = { 
         type:'Booklet',
         selectedItemName: booklet.title,
         selectedItemId: booklet.id,
         path:path,
         booklet:booklet
         }
    }
 
    
  }
  
  

  maskingToggle(event: any, profile: ProfileListModel) {
    const updateMaskingProfiles = (maskingProfiles: number[], profileId: number, add: boolean): any[] => {
      return add 
        ? [...new Set([...maskingProfiles, profileId])]  // Adds the element only if it doesn't exist
        : maskingProfiles.filter(id => id !== profileId); // Removes the element if it exists
    };

    if (!this.bookletInit$) {
      console.error('bookletInit$ is undefined');
      return;
    }

    var payload = {
      "masking_profiles": this.bookletInit$.masking_profiles as any
    }
    if (event.target.checked){
      payload['masking_profiles'] = updateMaskingProfiles(payload['masking_profiles'], profile.id, true); 
    } else {
      payload['masking_profiles'] = updateMaskingProfiles(payload['masking_profiles'], profile.id, false)
    }
    // console.log(this.bookletInit$, payload , event.target.checked)
    this.serviceResponseProgress = true;
    profile.is_booklet_masking =  event.target.checked;
    this.bookletInit$.masking_profiles = payload['masking_profiles']
    this.vm.updateBookletProfileMusking(String(this.bookletInit$.id), payload)
  
  }

  profileMasked(){
    this.serviceResponseProgress = false;
   
  }


  updateMaskingInfoOnprofiles(masking_profiles: number[]){
    this.profileResults?.forEach(element => {
        if((masking_profiles || []).includes(element.id)){
          element.is_booklet_masking = true;
        } else {
          element.is_booklet_masking = false;
        }
    });
    // console.log( 
  }

  setBookletBack(){
    localStorage.setItem('redirectFrom', 'booklet')
  }

  showFrozenCheckbox(completed: any) {
    let checked = completed.target as HTMLInputElement
    // console.log( checked.checked )
    this.showFrozen = checked.checked ;
    this.profileResults  = undefined
    this.filterName();
  }

 
  filterName() {
    this.serviceResponseProgress = true;
    this.page = 1
    this.vm.retrieveAllProfilesByBookletID(Number(this.bookletId), this.searchName.value, this.showFrozen,this.page_size,this.page)
   
  }

  searchProfile(){
    clearTimeout(this.searchTimeout)
    this.searchTimeout  = setTimeout(()=>{
        this.profileResults  = undefined
        this.filterName();
      }, 600)
  }

  restoreProfileDialog(selectedItem: ProfileArchiveListModel) {

    const modalRef = this.modalService.open(CopyProfileComponent,{size:'xl'});
    modalRef.componentInstance.data =   {type:'Restore', selectedProfileName: selectedItem.fullname, selectedProfileId: selectedItem.id };
   
    modalRef.result.then(
     (result) => {
      this.profileResults  = undefined
      this.filterName();
       console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
       console.log('Modal dismissed with reason:', reason);
     })
    
   
  }
   

  freezeDialog(selectedItem: ProfileListModel) {
    
    const modalRef = this.modalService.open(FreezeProfileComponent);
    modalRef.componentInstance.data = { selectedItemName: selectedItem.fullname, selectedItemId: selectedItem.id }
    
    modalRef.result.then(
     (result) => {
      this.profileResults  = undefined
      this.filterName();
       console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
       console.log('Modal dismissed with reason:', reason);
     })
  }

  copyDialog(selectedItem: ProfileListModel) {
   
        const modalRef = this.modalService.open(CopyProfileComponent,{size:'xl'});
    modalRef.componentInstance.data = {type:'Copy', selectedProfileName: selectedItem.fullname, selectedProfileId: selectedItem.id }
    
    modalRef.result.then(
     (result) => {
       // Handle the value returned when the modal is closed

      this.profileResults  = undefined
      this.filterName();
      //  console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
      //  console.log('Modal dismissed with reason:', reason);
     })
  }

  editBookletDialog() {
     

    const modalRef = this.modalService.open(EditBookletDialogComponent,{size:'xl'});
    modalRef.componentInstance.data = { selectedBookletData: this.bookletInit$ }
    
    modalRef.result.then(
     (result) => {
       // Handle the value returned when the modal is closed
       this.profileResults  = undefined
       this.filterName();
      // this.store.dispatch(BookletActions.retrieveAllProfilesByBookletID({id:String(this.bookletId)}))
      //  console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
      //  console.log('Modal dismissed with reason:', reason);
     })
  }

  uploadBookletDialog() {
      
    const modalRef = this.modalService.open(UploadBookletDialogComponent);
    modalRef.componentInstance.data = { selectedBookletData: this.bookletInit$ }
    
    modalRef.result.then(
     (result) => {
       // Handle the value returned when the modal is closed
      // this.store.dispatch(BookletActions.retrieveAllProfilesByBookletID({id:String(this.bookletId)}))
      this.profileResults  = undefined
      this.filterName();
      //  console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
      //  console.log('Modal dismissed with reason:', reason);
     })
  }

  exportProfileDialog(fullname:any,path:any,id:any) {
   
    const modalRef = this.modalService.open(profileBookletEditBeforeExportComponent);
    modalRef.componentInstance.data = { selectedItemName: fullname ,path : path,  selectedItemId: id,type:'Profile',}
   
    modalRef.result.then(
     (result) => {
       // Handle the value returned when the modal is closed
      //  this.store.dispatch(BookletActions.loadBookletList({booklettype: this.selectedBookletType}));
      this.profileResults  = undefined
      this.filterName();
      //  console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
      //  console.log('Modal dismissed with reason:', reason);
     })
  }
}
