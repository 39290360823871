<div class="modal-header">
    <h4 class="modal-title">Restore {{data.type}}!</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body text-center">
     <div *ngIf="data.type== 'Booklet'"> 
        <p class="text-secondary h6">Are you sure you want to restore.</p>
        <p class="text-secondary fw-semibold fs-6 text-capitalize">{{selectedItemName}}?</p>
    </div>  
    <div *ngIf="data.type== 'Profile'"> 
        <p class="text-secondary h6">Are you sure you want to restore.</p>
        <p class="text-secondary fw-semibold fs-6 text-capitalize">{{selectedProfileName}}</p>
        <p class="text-secondary fw-semibold fs-6">To</p>
        <p class="text-secondary fw-semibold fs-6 text-capitalize"> {{selectedBookletName}}? </p>
    </div>  
</div>
<div class="modal-footer">
    
    <button (click)="activeModal.dismiss()" class="btn btn-sm btn-secondary me-2 text-white"  >NO</button>
    <button (click)="eventCallYes()" class="btn btn-sm btn-primary"  >YES</button>
</div>