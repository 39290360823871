<div class="position-absolute top-50 end-0 " style="z-index:999999 ;" *ngIf="sendfeedbackViewEnabled === false" (click)="clickOFF($event)">
  <div class="feedback-btn-wrapper">
    <button class="btn btn-sm btn-secondary feedback-btn" (click)="openFeedback($event)">      
      <i class="bi bi-chat-left-dots-fill"></i> Feedback
    </button>
  </div>
</div>

<div id="feedbackScreeMain" class="d-flex align-items-center justify-content-center" *ngIf="sendfeedbackViewEnabled" (click)="clickOFF($event)" >  
  <div id="feedbackScreeSelection" (click)="clickOFF($event)"></div>
</div>

 
