import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IApiServiceDelegate } from '../model/desk-feedback.model';
import { APILIST } from './api-list';
import { LoginComponent } from '../login/login.component';
import { BehaviorSubject, Observer } from 'rxjs';
import { LoginModel } from '../login/login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  parentLoginComponent: LoginComponent;
  userProfile = new BehaviorSubject<LoginModel>(new LoginModel());
  public loginMasterEvent = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService
  ) { }

  updateLoginEventChanged(){
    this.loginMasterEvent.next(true);
  }

  getLoggedInUserId(){
    return localStorage.getItem('bfs_buyerprofile_uid') || null;
  }

  setLoggedInUserId(userId: string){
    localStorage.setItem('bfs_buyerprofile_uid', userId);
  }


  clearLoggedInUserId(){
    localStorage.removeItem('bfs_buyerprofile_uid');
  }


  setLoggedInUserProfile(userProfile: LoginModel){
    localStorage.setItem('buyerprofile_user', JSON.stringify(userProfile));
  }

  getLoggedInUserProfile(){
    return JSON.parse(localStorage.getItem('buyerprofile_user')) || null;
  }

  clearLoggedInUserProfile(){
    localStorage.removeItem('buyerprofile_user');
  }


  subscribeToUserProfile(observer:Observer<LoginModel>){
    this.userProfile.subscribe(observer);
  }

  storeLoginInformation(username: string, password: string){
    localStorage.setItem('bfs_buyerprofile_unm', username);
    localStorage.setItem('bfs_buyerprofile_pwd', password);
  }

  getLoginRememberedInformation(){
    const storedUsername = localStorage.getItem('bfs_buyerprofile_unm');
    const storedPassword = localStorage.getItem('bfs_buyerprofile_pwd')
    return {
      username: storedUsername,
      password: storedPassword
    }
  }

  clearLoginInformation(){
    localStorage.removeItem('bfs_buyerprofile_unm');
    localStorage.removeItem('bfs_buyerprofile_pwd');
  }

  
  login(userCredential: string) {
    const url = APILIST.USER_LOGIN + "?cred=" + userCredential;
    let d: IApiServiceDelegate = {
      Data: { },
      Method: "GET",
      Action: url,
      Success: (e: any) => {
        if (e && Object.keys(e).length > 0 && e?.id) {
          this.userProfile.next(e);
          this.parentLoginComponent.loginSuccess();
        } else {
          this.parentLoginComponent.loginError('Unable to login.');
        }
        return e;
      },
      Error: (e: String) => {
        this.parentLoginComponent.loginError(e);
        return e;
      },
    };
    this.apiService.callService(d);
  }

  isLoggedIn(){
    return this.getLoggedInUserId() && this.getLoggedInUserProfile()? true : false;
  }

}
