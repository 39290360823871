import { Component, ElementRef, inject, Input, viewChild, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BookletViewModel } from '../../booklet.viewModel';
import { HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { BookletModel } from '../../../model/booklet.model';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-upload-booklet-dialog',
  standalone: false,
  templateUrl: './upload-booklet-dialog.component.html',
  styleUrl: './upload-booklet-dialog.component.scss'
})
export class UploadBookletDialogComponent {
  serviceResponseProgress = false;
  @Input() data:any

   

  activeModal = inject(NgbActiveModal);
    @ViewChild('fileUpload' , {static:true}) fileUpload: ElementRef
    @Input() files: File[] = []
    file
    inputFileName: string
    userId: string;
    bookletInit: BookletModel;
    someError:boolean = false
    someErrorList = [{}]
   
    // bookletId:any
    newBookletForm: FormGroup;
    updatedBooklet: BookletModel = new BookletModel();

  constructor(  public toastService: ToastService, private fb: FormBuilder, private vm:BookletViewModel, private sanitizer: DomSanitizer) {
    this.vm.uploadBookletDialogComponent = this
  }

ngOnInit() { 

         this.bookletInit = this.data.selectedBookletData as BookletModel;
        this.userId = localStorage.getItem('bfs_buyerprofile_uid');
       

}

onClick(event) {
  if (this.fileUpload)
    this.fileUpload.nativeElement.click()
}

onInput(event) {
}

onFileSelected(event) {

this.file = event.dataTransfer ? event.dataTransfer.files : event.target.files;
let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
console.log('event::::::', event,files , this.file)
for (let i = 0; i < files.length; i++) {
  let file = files[i];

  //if(!this.isFileSelected(file)){
  if (this.validate(file)) {
    //      if(this.isImage(file)) {
    file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
    //      }
    // if (!this.isMultiple()) {
    //   this.files = []
    // }
    this.files.push(files[i]);
    //  }
  }
  //}
}
}

removeFile() {
// let ix
// if (this.files && -1 !== (ix = this.files.indexOf(file))) {
//   this.files.splice(ix, 1)
//   this.clearInputElement()
// }
this.file = undefined
this.inputFileName = undefined
}

validate(file: File) {
// for (const f of this.files) {
//   if (f.name === file.name
//     && f.lastModified === file.lastModified
//     && f.size === f.size
//     && f.type === f.type
//   ) {
//     return false
//   }
// }
// return true
if(file){
  return true
} else {
  return false
}
}

clearInputElement() {
this.fileUpload.nativeElement.value = ''
}

switchError(){
this.someError = false
}

eventCallUpdate() {
  // console.log(this.file , this.file[0].name , this.bookletId, this.bookletInit.id)
  if (this.file == undefined) {
    this.toastService.show("No file selected!", "warning");
    
  } else {
    this.serviceResponseProgress = true;
    // var formData = new FormData();
    // var httpHeaders = new HttpHeaders();
    // var uploadContentType = { type: 'multipart/form-data' }
    // formData.set('file', new Blob([this.file[0]], uploadContentType), this.file[0].name);
    // formData.set('created_by', currentUser);
    // formData.set('name', this.file.name);
    // console.log(this.file,new Blob([this.file[0]], uploadContentType) )
    this.vm.uploadBooklet(this.file[0], this.bookletInit.id)

    

    }
      (error) => {
        this.serviceResponseProgress = false;
        console.log(error.error)
        let err

        if(typeof(error.error.error) == 'string'){
          err = 'Error :' + error.error.error
        } else {
          const myJSON = JSON.stringify(error.error.error);
          const ye = myJSON.replace(/{|}/gi ,function (x) {
            return '';
          })
          err = ye.slice(1,ye.length-1) + ". Please try after correcting!"
        }
         
      }
     
  }

  bookletUploaded(){
    this.serviceResponseProgress = false;
    this.activeModal.close('modal closed')
  }

  eventCallSkip() {
    this.activeModal.close('modal closed')
  }
}

 
