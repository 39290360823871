import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookletViewModel } from '../../booklet.viewModel';
 
 

@Component({
  selector: 'app-freeze-booklet',
  standalone: false,
  templateUrl: './freeze-booklet.component.html',
  styleUrl: './freeze-booklet.component.scss'
})
export class FreezeBookletComponent {
  activeModal = inject(NgbActiveModal);
  userId!: string | null
  @Input() data:any
  serviceResponseProgress:boolean = false
  actionsSubscription: any;

  constructor(private vm:BookletViewModel,) {this.vm.freezeBookletComponent = this}
  
  ngOnInit(): void {
   
    this.userId = localStorage.getItem('bfs_buyerprofile_uid');
   
      // Handle the action here
      
  
  }

  freezeBooklet(){
  const payload = {created_by: Number(this.userId)};
  this.vm.archiveBooklet(this.data.selectedItemId, payload)
  this.serviceResponseProgress = true;
 
  }

  closeIt(){
    this.serviceResponseProgress = false;
    this.activeModal.close('modal closed')
  }
 
}
