import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { ApiService } from '../services/api.service';
import { BehaviorSubject } from 'rxjs';
import { LoginModel } from './login.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [ApiService]
})
export class LoginComponent implements OnInit {


  loginForm: FormGroup;
  userProfile = new BehaviorSubject<LoginModel>(new LoginModel());

  constructor(private loginService: LoginService, private router: Router){
    this.initLoginForm();
  }


  ngOnInit(): void {
    if (this.loginService.isLoggedIn()) {
      this.router.navigate(['/booklet']);
    }
    this.getRememberCreds();
    this.loginService.parentLoginComponent = this;
    this.loginService.subscribeToUserProfile(this.userProfile);
  }

  getRememberCreds(){
    const { username, password } = this.loginService.getLoginRememberedInformation();
    if (username && password) {
      this.loginForm.patchValue({
        username: username,
        password: password,
        rememberMe: true
      })
    }    
  }
  

  initLoginForm(){
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(3)]),
      password: new FormControl('', [Validators.required, Validators.minLength(3)]),
      rememberMe: new FormControl(false)
    })
  }
  

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty();
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.validateAllFormFields(this.loginForm);
      return
     }
    // TODO: Use EventEmitter with form value
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;
    const userCredential = btoa(username + ':' + password);
    this.loginService.login(userCredential);
  }

  loginSuccess(){
    const username = this.loginForm.value.username;
    const password = this.loginForm.value.password;
    const rememberMe = this.loginForm.value.rememberMe;
    if (rememberMe) {
      this.loginService.storeLoginInformation(username, password);
    } else {
      this.loginService.clearLoginInformation();
    }
    this.loginService.setLoggedInUserId(this.userProfile.value.id.toString());
    this.loginService.setLoggedInUserProfile(this.userProfile.value);
    this.loginService.updateLoginEventChanged();
    this.router.navigate(['/booklet']);
  }

  loginError(response){
    console.log(response)
  }

}
