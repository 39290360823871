import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
 
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-booklet-edit-before-export',
  standalone: false,
  templateUrl: './profile-booklet-edit-before-export.component.html',
  styleUrl: './profile-booklet-edit-before-export.component.scss'
})
export class profileBookletEditBeforeExportComponent implements OnInit {
@Input() data:any
activeModal = inject(NgbActiveModal);
profileDetailsForm: FormGroup;
  bookletDetailsForm: FormGroup;
  name:string= ''
  prfl_id:string = ''
  path:string = ''
  booklet:any = {}

  validation_messages = {
    'custom_location_address': [
      { type: 'required', message: 'Please provide your location address' }
    ],

    'custom_email_address': [
      { type: 'required', message: 'Please provide your email address' }
    ],
    'custom_ph_no': [
      { type: 'required', message: 'Phone is required' },
      { type: 'validCountryPhone', message: 'Phone incorrect for the country selected' }
    ]
  };

constructor(private router:Router ,private fb: FormBuilder) {

}

ngOnInit() {
  if(this.data.type == 'Booklet'){
    this.booklet = this.data.booklet
  }
  this.name= this.data.selectedItemName
  this.prfl_id= this.data.selectedItemId
  this.path= this.data.path
  this.createForms();
  // console.log(this.data)
}


createForms() {
  // user links form validations
  if(this.data.type !== 'Booklet'){
      this.profileDetailsForm = this.fb.group({
        remove_client_logo: new FormControl(null),
        custom_ph_no:new FormControl(''),
        custom_email_address: new FormControl(''),
        custom_location_address:new FormControl('' ),
    })
  } else{
      this.bookletDetailsForm = this.fb.group({
        // remove_client_logo: new FormControl(false),
        custom_ph:new FormControl(''),
        custom_email: new FormControl(''),
        // custom_location_address:new FormControl('' ),
    })
  }

}

onSubmitProfileDetails(data:any){

  let Param = {name:this.name,prfl_id:this.prfl_id,remove_client_logo:data.remove_client_logo,custom_ph_no:data.custom_ph_no,custom_email_address:data.custom_email_address,custom_location_address:data.custom_location_address}
  const url = this.router.serializeUrl(this.router.createUrlTree([this.path,this.name ] , { queryParams: Param}));
  console.log(url,Param)
  window.open(url, '_blank');
  this.activeModal.close('Profile download Starting!');
}

skipEditAndDownloadProfile( ){

  let Param = {name:this.name,prfl_id:this.prfl_id,}
  const url = this.router.serializeUrl(this.router.createUrlTree([this.path,this.name ] , { queryParams: Param}));
  console.log(url,Param)
  window.open(url, '_blank');
  this.activeModal.close('Profile download Starting!');
}


onSubmitBookletDetails(data:any){
  let Param = {bklt_id:this.prfl_id,custom_ph:data.custom_ph,custom_email:data.custom_email,}
  const url = this.router.serializeUrl(this.router.createUrlTree([this.path,this.prfl_id] , { queryParams: Param}));
  window.open(url, '_blank');
  this.activeModal.close('Booklet download Starting!');
}

skipEditAndDownloadBooklet( ){
  let Param = {bklt_id:this.prfl_id,}
  const url = this.router.serializeUrl(this.router.createUrlTree([this.path,this.booklet.title] ,{ queryParams: Param} ));
  window.open(url, '_blank');
  this.activeModal.close('Booklet download Starting!');
}

closePopup(){
  this.activeModal.close('modal closed')
}

addProfile(){
  let url = 'booklet/:Id/add-profile'.replace(':Id',this.data.selectedItemId)
  this.router.navigateByUrl(url)
  this.activeModal.close('modal closed')
}
}
