import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProfileViewModel } from '../profile/profile.viewModel';
import { BookletArchivePaginatedListModel, ProfileArchivePaginatedListModel, BookletArchiveListModel, ProfileArchiveListModel } from '../model/archive.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestoreBookletProfileComponent } from './restore-booklet-profile/restore-booklet-profile.component';
import { BehaviorSubject } from 'rxjs';
import { CopyProfileComponent } from '../shared/copy-profile/copy-profile.component';


@Component({
  selector: 'app-frozen-item',
  standalone: false,
  templateUrl: './frozen-item.component.html',
  styleUrl: './frozen-item.component.scss'
})
export class FrozenItemComponent {
  serviceResponseProgress = true;
  private modalService = inject(NgbModal);
  userId: string;
  bookletArchiveInit: BookletArchivePaginatedListModel;
  bookletArchiveResults: any[] = [];
  profileArchiveInit: ProfileArchivePaginatedListModel;
  profileArchiveResults: any[] = [];

  bookletArchiveList = new BehaviorSubject<BookletArchivePaginatedListModel>(new BookletArchivePaginatedListModel())
  profileArchiveList = new BehaviorSubject<ProfileArchivePaginatedListModel>(new ProfileArchivePaginatedListModel())
  searchName = new FormControl('');
  selectedTabType = 'B';
  searchTimeout:any
  calendarMonths = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  currentDate = new Date();
  currentMonth = this.currentDate.getMonth();
  currentYear = this.currentDate.getUTCFullYear();
  calendarYears = [this.currentYear - 2 ,this.currentYear - 1, this.currentYear, this.currentYear + 1, ];

  selectedYear = 'Year';
  selectedMonthIndex = this.currentMonth;
  selectedMonth = 'Select month';
  page_size:number = 10
  page:number = 1
  previousPageId: string;
  nextPageId: string;

  frozenItemSearch:any = {
    'selectedTabType': 'B',
    'selectedMonthIndex':null,
    'selectedYear':null,
    'name':null
  }
  
  constructor(private vm: ProfileViewModel,) { this.vm.frozenItemComponent = this }

  ngOnInit() {
    this.userId = localStorage.getItem('bfs_buyerprofile_uid');
    this.vm.subscribeFrozenItemDetails(this.bookletArchiveList ,this.profileArchiveList)

    this.apiCall()
  }

  bookletArchiveListRecived(){
    if(this.bookletArchiveResults == undefined){
      this.bookletArchiveResults = this.bookletArchiveList.value.results ;
    } else{
      this.bookletArchiveResults.push(...this.bookletArchiveList.value.results) ;
    }
    this.serviceResponseProgress = false;
    // console.log(this.bookletArchiveResults)
  }

  resetFilter(){
    this.frozenItemSearch  = {
      'selectedTabType': 'B',
      'selectedMonthIndex':null,
      'selectedYear':null,
      'name':null
    }
    this.serviceResponseProgress = true;
    this.selectedMonth = 'Select Month';
    this.profileArchiveResults = undefined
    this.bookletArchiveResults = undefined
    this.page = 1
    this.apiCall()
  }

  profileArchiveListRecived(){
    if(this.profileArchiveResults == undefined){
      this.profileArchiveResults = this.profileArchiveList.value.results;
    } else{
      this.profileArchiveResults.push(...this.profileArchiveList.value.results);
    }
    this.serviceResponseProgress = false;
    // console.log(this.profileArchiveResults)
  }


  apiCall(){
    if(this.selectedTabType === 'B'){
      this.getBookletList()
    } else if(this.selectedTabType === 'P') {
      this.getProfileList()
    }
  }
  
  onInputChange(e:any): void {
    clearTimeout(this.searchTimeout)
    this.searchTimeout  = setTimeout(()=>{
  
       this.page = 1
       this.profileArchiveResults = undefined
       this.bookletArchiveResults = undefined
       this.apiCall()
       
       }, 600)
   }
   
   onValueChange(e:any, identification?):void {
    if(this.frozenItemSearch.selectedMonthIndex == null)   {
      this.frozenItemSearch.selectedMonthIndex = this.selectedMonthIndex 
      this.selectedMonth = this.calendarMonths[this.selectedMonthIndex] ;
    }
    if(e == null && identification === 'year'){
      this.frozenItemSearch.selectedMonthIndex = null
      this.selectedMonth = 'Select Month';
    }
    this.page = 1
    this.profileArchiveResults = undefined
    this.bookletArchiveResults = undefined
    this.apiCall()
   }
 

 monthChanged(e:any){
  this.page = 1
  this.profileArchiveResults = undefined
  this.bookletArchiveResults = undefined
  if(e){
    this.frozenItemSearch.selectedMonthIndex = this.calendarMonths.indexOf(this.selectedMonth) +1
    this.apiCall()
   } else{
    this.apiCall()
   }
 }

  getBookletList(){
    let payload = { oz_year: this.frozenItemSearch.selectedYear , oz_month:  this.frozenItemSearch.selectedMonthIndex , bklt_title:  this.frozenItemSearch.name };
    this.vm.retrieveAllBookletArchivesInit(payload, this.page_size, this.page)
    this.serviceResponseProgress = true;
  }

  getProfileList() {
    let payload = { crt_year:  this.frozenItemSearch.selectedYear, crt_month: this.frozenItemSearch.selectedMonthIndex, name:this.frozenItemSearch.name};
    this.vm.retrieveAllProfileArchivesInit(payload, this.page_size, this.page)
    this.serviceResponseProgress = true;
  }

  onScroll(type:string){
    if(this.selectedTabType === 'B' &&  this.bookletArchiveList.value.next != null){
      this.page++
      this.getBookletList()
    } 
    if(this.selectedTabType === 'P' && this.profileArchiveList.value.next != null) {
      this.page++
      this.getProfileList()
    }
  }

  checkCurrentTab(type: any) {
    this.selectedTabType = type
    this.resetFilter()
  }


  restoreBookletDialog(selectedItem: BookletArchiveListModel) {

    const modalRef = this.modalService.open(RestoreBookletProfileComponent);
    modalRef.componentInstance.data =  {type:'Booklet', selectedItemName: selectedItem.title, selectedItemId: selectedItem.id }
   
    modalRef.result.then(
     (result) => {
      this.bookletArchiveResults = undefined
      this.apiCall()
       console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
       console.log('Modal dismissed with reason:', reason);
     })
   
  }

  restoreProfileDialog(selectedItem: ProfileArchiveListModel) {

    const modalRef = this.modalService.open(CopyProfileComponent,{size:'xl'});
    modalRef.componentInstance.data =   {type:'Restore', selectedProfileName: selectedItem.fullname, selectedProfileId: selectedItem.id };
   
    modalRef.result.then(
     (result) => {
      this.profileArchiveResults = undefined
      this.apiCall()
       console.log('Modal closed with result:', result);
     },
     (reason) => {
       // Handle the dismissal (if modal is dismissed by clicking outside or pressing ESC)
       console.log('Modal dismissed with reason:', reason);
     })
    
   
  }

}
