import { Pipe, PipeTransform } from '@angular/core';
import { BookletListModel } from '../model/booklet.model';
import { ProfileListModel } from '../model/profile.model';

 

@Pipe({ name: 'getFirstName'})
export class GetFirstName implements PipeTransform {
  transform(fullName: string): string {
    let firstName = fullName.split(' ')[0];
    if ((firstName[firstName.length - 1]) === 's') {
      firstName = firstName + `'`;
    } else if ((firstName[firstName.length - 1]) !== 's') {
      firstName = firstName + `'s`;
    }
    return firstName;
  }
}


@Pipe({ name: 'searchBookletFilter' })
export class SearchBookletFilter implements PipeTransform {
  transform(data: BookletListModel[], searchText: string): BookletListModel[] {
    if (!data) {
      return [];
    }
    if (!searchText || searchText.length === 0) {
      return data;
    }
    return data.filter(x => {
      return x.title.toLowerCase().startsWith(searchText.toLowerCase());
    });
  }
}


@Pipe({ name: 'searchProfileFilter' })
export class SearchProfileFilter implements PipeTransform {
  transform(data: ProfileListModel[], searchText: string): ProfileListModel[] {
    if (!data) {
      return [];
    }
    if (!searchText || searchText.length === 0) {
      return data;
    }
    return data.filter(x => {
      return x.fullname.toLowerCase().startsWith(searchText.toLowerCase());
    });
  }
}
