import { Injectable } from "@angular/core";
import { ApiService } from "../services/api.service";
import { IApiServiceDelegate } from "../model/desk-feedback.model";
import { APILIST } from "../services/api-list";
import { BookletComponent } from "./booklet.component";
import { BehaviorSubject, Observer } from "rxjs";
import { BookletCompositeModel, BookletModel, BookletPaginatedListModel, ClientModel, RegionModel, StandardResponseModal } from "../model/booklet.model";
import { BookletCompositeComponent } from "./booklet-composite/booklet-composite.component";
import { FileSaverService } from "ngx-filesaver";
import { BookletDetailsComponent } from "./booklet-details/booklet-details.component";
import { ProfilePaginatedListModel } from "../model/profile.model";
import { PreviewBookletComponent } from "./booklet-modals/preview-booklet/preview-booklet.component";
import { ToastService } from "../services/toast.service";
import { DeleteBookletComponent } from "./booklet-modals/delete-booklet/delete-booklet.component";
import { FreezeBookletComponent } from "./booklet-modals/freeze-booklet/freeze-booklet.component";
import { UploadBookletDialogComponent } from "./booklet-modals/upload-booklet-dialog/upload-booklet-dialog.component";
import { EditBookletDialogComponent } from "./booklet-modals/edit-booklet-dialog/edit-booklet-dialog.component";
import { AddClientComponent } from "./booklet-modals/add-client/add-client.component";

@Injectable({
    providedIn: "root"
  })


export class BookletViewModel {

    constructor(private apiService: ApiService,  private fileSaverService: FileSaverService,public toastService: ToastService){
      
    }

    bookletComponent:BookletComponent 
    bookletCompositeComponent:BookletCompositeComponent
    bookletDetailsComponent:BookletDetailsComponent
    previewBookletComponent:PreviewBookletComponent
    deleteBookletComponent:DeleteBookletComponent
    freezeBookletComponent:FreezeBookletComponent
    editBookletDialogComponent:EditBookletDialogComponent
    uploadBookletDialogComponent:UploadBookletDialogComponent
    addClientComponent:AddClientComponent
    profileListModel = new BehaviorSubject<ProfilePaginatedListModel>(new ProfilePaginatedListModel())
    bookletList = new BehaviorSubject<BookletPaginatedListModel>(new BookletPaginatedListModel())
    bookletComposite = new BehaviorSubject<BookletCompositeModel>(new BookletCompositeModel())
    bookletDetails = new BehaviorSubject<BookletModel>(new BookletModel())
    regionList = new BehaviorSubject<[]>([])
    clientList = new BehaviorSubject<[]>([])

    subscribeBookletList(observer: Observer<BookletPaginatedListModel>,observer1:Observer<[]>,observer2:Observer<[]>){
      this.bookletList.subscribe(observer),this.regionList.subscribe(observer1), this.clientList.subscribe(observer2)
    }
    subscribeRegionList(observer1:Observer<[]>){
     this.regionList.subscribe(observer1) 
    }
    subscribeClientList(observer2:Observer<[]>){
       this.clientList.subscribe(observer2)
    }

    subscribeBookletComposite(observer:Observer<BookletCompositeModel>){
      this.bookletComposite.subscribe(observer)
    }
    subscribeBookletDetails(observer:Observer<BookletModel>, observer1:Observer<ProfilePaginatedListModel>){
      this.bookletDetails.subscribe(observer), this.profileListModel.subscribe(observer1)
    }

  

    retrieveAllBookletsInit(data?: any,page?:number,page_size?:number) {
        var str = "?page_size="+page_size +"&page="+ page 
          for(const item in data){
            if (data.hasOwnProperty(item)) {  // Check to ensure it's not inheriting from prototype
                // API_PARAMS[item] = data[item];  // Assign the actual value
                if ((data[item] !== null && String(data[item])?.length > 0) || data[item] == true  ) {
                  str += '&'+ item + '=' + data[item]
              }
            }
          }
        
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: APILIST.BOOKLET + str  ,
          Success: (e: any) => {
            
            this.bookletList.next(e);
            this.bookletComponent.bookletListRecived()
            
            return e;
          },
          Error: (e: String) => {
            return e;
          },
        };
        this.apiService.callService(d);
      }

      retrieveAllClients() {
         
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: APILIST.CLIENT    ,
          Success: (e: any) => {
            
            this.clientList.next(e);
            // console.log(this.clientList.value , e)
            return e;
          },
          Error: (e: String) => {
            return e;
          },
        };
        this.apiService.callService(d);
      }

      retrieveAllRegion() {
         
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: APILIST.REGION    ,
          Success: (e: any) => {
            
            this.regionList.next(e);
            return e;
          },
          Error: (e: String) => {
            return e;
          },
        };
        this.apiService.callService(d);
      }
      
      retrieveBookletbyID(bookletId: number) {
         
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: APILIST.BOOKLET + '/' +  bookletId ,
          Success: (e: any) => {
            this.bookletDetails.next(e)
            this.bookletDetailsComponent.retrieveBookletbyIdReceived()
            return e;
          },
          Error: (e: String) => {
            return e;
          },
        };
        this.apiService.callService(d);
      }

      retrieveAllProfilesByBookletID(bookletId: number, profileName: string, frozen: any, page_size?:number,page?:number) {
        let url = APILIST.PROFILE + '?bklt=' +  bookletId +  '&name=' +  profileName + "&page_size="+page_size +"&page="+ page  
        if(frozen){
          url += '&show_archives=' + frozen ;
        } 
         
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action:  url,
          Success: (e: any) => {
            this.profileListModel.next(e)
            this.bookletDetailsComponent.profilesByBookletIdReceived()
            return e;
          },
          Error: (e: String) => {
            return e;
          },
        };
        this.apiService.callService(d);
      }

      
      

      retrieveBookletCompositebyId(bookletId: number,custom_ph?:string, custom_email?:string) {
         let url = APILIST.BOOKLET_PREVIEW  +  bookletId
         if(custom_ph?.length > 0){
          url+='?' + 'custom_ph='+custom_ph
         }
         if(custom_email?.length > 0){
          url+='&' + 'custom_email='+custom_email
         }
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "GET",
          Action: url ,
          Success: (e: any) => {
            // console.log( e)
            this.bookletComposite.next(e)
            this.bookletCompositeComponent.bookletpreviewReceived()
            return e;
          },
          Error: (e: String) => {
            return e;
          },
        };
        this.apiService.callService(d);
      }

      downloadBookletCompositePDF(bookletId: number, data:any, fileName: string) {
        let API_URL = APILIST.BOOKLET_DOWNLOAD_PDF.replace(':bookletId',String(bookletId))
          
        const queryParams = Object.keys(data)
        .filter(key => data[key] !== null && data[key] !== undefined)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
        
        if (queryParams) {
          API_URL += `?${queryParams}`;
        }
        
        this.apiService.download(API_URL).subscribe((e: any) => {;
          const blob = new Blob([e], { type: 'application/pdf' });
          this.fileSaverService.save(blob, fileName);
          this.toastService.show("Booklet download succesfully!", "success");
          this.bookletCompositeComponent.downloadSuccess();
        },
          (error: any) => {
            this.toastService.show(String(error), "success");
            this.bookletCompositeComponent.downloadFailed();
        });
      

        // let d: IApiServiceDelegate = {
        //   Data: {},
        //   Method: "GET",
        //   Action: API_URL,
        //   Success: (e: any) => {
        //     console.log( e)
        //     // this.bookletCompositeComponent.bookletpreviewReceived(e)
        //     this.toastService.show('Booklet download succesfully !', 'success');
        //     const fileName = this.bookletCompositeComponent.bookletCompositeInit.title + '.pdf';
        //     this.fileSaverService.save(new Blob([data]), fileName, 'application/pdf');
        //     return e;
        //   },
        //   Error: (e: String) => {
        //     this.toastService.show('Error in downloading booklet!', 'danger');
        //     return e;
        //   },
        // };
        // this.apiService.callService(d);
      }

      downloadBookletCompositeExcell(bookletId: number,data:any, fileName) {
        let API_URL = APILIST.BOOKLET_DOWLOAD_EXCELL + '?booklet=' + bookletId
          
        const queryParams = Object.keys(data)
          .filter(key => data[key] !== null && data.hasOwnProperty(key))
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join('&');

        if (queryParams) {
          API_URL += `&${queryParams}`;
        }

        this.apiService.download(API_URL).subscribe((e: any) => {;
          const blob = new Blob([e], { type: 'application/xlsx' });
          this.fileSaverService.save(blob, fileName);
          this.toastService.show("Booklet downloaded succesfully!", "success");
          this.bookletCompositeComponent.downloadSuccess();
        },
          (error: any) => {
            this.toastService.show(String(error), "success");
            this.bookletCompositeComponent.downloadFailed();
        });

        
      }

     
       
       
      
        deleteBooklet(bookletId: number){
        let d: IApiServiceDelegate = {
          Data: {},
          Method: "DELETE",
          Action:  APILIST.BOOKLET +'/'+ bookletId  ,
          Success: (e: any) => { 
            this.deleteBookletComponent.closeIt()
            this.toastService.show('Booklet deleted succesfully!', 'success');
            return e;
          },
          Error: (e: String) => {
            this.toastService.show('Error in deleting booklet!', 'danger');
            return e;
          },
        };
        this.apiService.callService(d);
        }

        archiveBooklet(bookletId: number, payload: any){
        let d: IApiServiceDelegate = {
          Data: payload,
          Method: "PUT",
          Action:  APILIST.BOOKLET +'/'+ bookletId +'/archive_booklet' ,
          Success: (e: any) => { 
            this.freezeBookletComponent.closeIt()
            this.toastService.show('Booklet archived succesfully!', 'success');
            return e;
          },
          Error: (e: String) => {
            this.toastService.show('Error in archiving booklet!', 'danger'); 
            return e;
          },
        };
        this.apiService.callService(d);
        }

        associateClient(payload: any){
        let d: IApiServiceDelegate = {
          Data: payload,
          Method: "POST",
          Action:  APILIST.BOOKLET + '/add_to_client' ,
          Success: (e: any) => { 
            this.toastService.show('Client added to booklet succesfully!', 'success');
            this.bookletComponent.filterBooklet()
            return e;
          },
          Error: (e: String) => {
            this.toastService.show('Error in adding client to booklet!', 'danger');  
            return e;
          },
        };
        this.apiService.callService(d);
        }

        removeClient(id: any,b:any){
        let d: IApiServiceDelegate = {
          Data:id,
          Method: "DELETE",
          Action:  APILIST.BOOKLET +'/'+ id  +'/remove_from_client' ,
          Success: (e: any) => { 
            this.toastService.show('Client removed from booklet succesfully!', 'success');
            this.bookletComponent.clientRemoved(b)
            return e;
          },
          Error: (e: String) => {
            this.toastService.show('Error in removing client to booklet!', 'danger');
            return e;
          },
        };
        this.apiService.callService(d);
        }


        

     
      createBooklet(booklet: any){
        let d: IApiServiceDelegate = {
          Data: booklet,
          Method: "POST",
          Action:  APILIST.BOOKLET ,
          Success: (e: any) => { 
            this.previewBookletComponent.bookletCreated(e)
            this.toastService.show('Booklet created succesfully!', 'success');
            return e;
          },
          Error: (e: String) => {
            this.toastService.show('Error in creating booklet!', 'danger');
            return e;
          },
        };
        this.apiService.callService(d);
      }

      updateBooklet(bookletId: string, booklet: any){
        let d: IApiServiceDelegate = {
          Data: booklet,
          Method: "PATCH",
          Action:  APILIST.BOOKLET +'/' + bookletId  ,
          Success: (e: any) => { 
            this.editBookletDialogComponent.bookletEdited()
            this.toastService.show('Booklet updated succesfully!', 'success');
            return e;
          },
          Error: (e: String) => {
            // HeaderComponent.myApp.showAlert(e, "danger");
            this.toastService.show('Error in updating booklet!', 'danger');
            return e;
          },
        };
        this.apiService.callService(d);
      }

      updateBookletProfileMusking(bookletId: string, payload: any){
        let d: IApiServiceDelegate = {
          Data: payload,
          Method: "PATCH",
          Action:  APILIST.BOOKLET +'/' + bookletId  ,
          Success: (e: any) => { 
            this.bookletDetailsComponent.profileMasked();
            this.toastService.show('Profile masking updated succesfully!', 'success');
            return e;
          },
          Error: (e: String) => {
            this.toastService.show('Error in profile masking updating booklet!', 'danger');
            // HeaderComponent.myApp.showAlert(e, "danger");
            return e;
          },
        };
        this.apiService.callService(d);
      }

      uploadBooklet(booklet: any, booklet_id:any){
       
        this.apiService.uploadFile(APILIST.UPLOAD_BOOKLET + localStorage.getItem('bfs_buyerprofile_uid')+'&booklet_id='+booklet_id,booklet).subscribe((res:any) => {
            if(res.error){
              this.toastService.show('Failed to update Booklet!', 'danger');
            } else{
              this.uploadBookletDialogComponent.bookletUploaded()
              this.toastService.show('Booklet upload succesfully!', 'success');
            }
          })
      }

        createClients(client:any) {
          let d: IApiServiceDelegate = {
            Data: client,
            Method: "POST",
            Action:  APILIST.CLIENT ,
            Success: (e: any) => { 
              this.addClientComponent.closeDialog()
              this.toastService.show('Client created succesfully!', 'success');
              return e;
            },
            Error: (e: String) => {
              this.toastService.show('Error in creating client!', 'danger');
              return e;
            },
          };
          this.apiService.callService(d);
        }


    //   createBooklet(booklet: any) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   return this.apiClient.post(API_URL, booklet);
    //     // }
      
    //     //  updateBooklet(bookletId: string, booklet: any) {
    //     //   let API_URL: string = this.api_full.concat('booklet/:bookletId');
    //     //   API_URL = API_URL.replace(':bookletId', bookletId);
    //     //   return this.apiClient.put(API_URL, booklet);
    //     // }
      
    //     //  updateBookletProfileMusking(bookletId: string, payload: any) {
    //     //   let API_URL: string = this.api_full.concat('booklet/:bookletId');
    //     //   API_URL = API_URL.replace(':bookletId', bookletId);
    //     //   return this.apiClient.patch(API_URL, payload);
    //     // }
      
    //     //  uploadBooklet(booklet: any, booklet_id:any) {
    //     //   let API_URL: string = this.api_full.concat('profile-bulk-upload?user=');
          
    //     //   API_URL = API_URL.concat(+!localStorage.getItem('bfs_buyerprofile_uid')+'&booklet_id='+booklet_id);
           
    //     //   return this.apiClient.post(API_URL, booklet);
    //     // }
      
    //     //  retrieveAllBookletsInit(bookletType?: string,page?:number,page_size?:number): Observable<BookletPaginatedListModel> {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   const API_PARAMS: any = {};
        
    //     //   if (bookletType) {
    //     //     API_PARAMS.bklt_type = bookletType;
    //     //   }
          
    //     //   return this.apiClient.get<BookletPaginatedListModel>(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrievePaginatedBookletData( url: string,) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   const API_PARAMS = url  ;
    //     //   return this.apiClient.get(API_URL.concat('?' + API_PARAMS));
    //     //   // return this.apiClient.get(API_PARAMS);
    //     // }
      
    //     //  retrieveAllPaginatedBookletsInit(bookletType: string, pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   const API_PARAMS = { bklt_type: bookletType, page: pageId};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllBooklets(bookletType: string, organizedYear: string, organizedMonth: string,) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   const API_PARAMS = { bklt_type: bookletType, oz_year: organizedYear, oz_month: organizedMonth,};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
      
    //     // }
    //     //  retrieveAllBookletsClient(bookletType: string, organizedYear: string, organizedMonth: string,bookletTitle: string,clientId:number) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   let API_PARAMS
    //     //   if(organizedYear !== 'YEAR' && clientId !== null  && bookletTitle !== ''){
    //     //     API_PARAMS = { bklt_type: bookletType ,bklt_title: bookletTitle ,oz_year: organizedYear, oz_month: organizedMonth, client:String(clientId)}
    //     //   } else if( bookletTitle !== ''  && clientId !== null) {
    //     //     API_PARAMS = {bklt_type: bookletType ,bklt_title: bookletTitle,client:String(clientId)}
    //     //   } else if( organizedYear !== 'YEAR' && clientId !== null ){
    //     //     API_PARAMS = { bklt_type: bookletType ,oz_year: organizedYear, oz_month: organizedMonth, client:String(clientId)}
    //     //   } else if(clientId !== null ){
    //     //     API_PARAMS = { bklt_type: bookletType,client:String(clientId) }
    //     //   } else if(organizedYear !== 'YEAR' ){
    //     //     API_PARAMS = { bklt_type: bookletType ,oz_year: organizedYear, oz_month: organizedMonth, }
    //     //   } else if(bookletTitle !== '' ){
    //     //     API_PARAMS = { bklt_type: bookletType ,bklt_title: bookletTitle }
    //     //   }
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
      
    //     // }
      
    //     //  retrieveAllPaginatedBooklets(bookletType: string, organizedYear: string, organizedMonth: string, pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   const API_PARAMS = { bklt_type: bookletType, oz_year: organizedYear, oz_month: organizedMonth, page: pageId };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     // retrieveAllFilterBooklets(data:BookletSearchModel){
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   let API_PARAMS: { [key: string]: any }  = {}
    //     //   for(const item in data){
    //     //     if (data.hasOwnProperty(item)) {  // Check to ensure it's not inheriting from prototype
    //     //         // API_PARAMS[item] = data[item];  // Assign the actual value
    //     //         if (data[item] !== null  ) {
    //     //           API_PARAMS[item] = data[item]
    //     //         }
    //     //     }
    //     //   }
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllFilterClientBooklets(bookletType: string, organizedYear: string, organizedMonth: string, bookletTitle: string,clientId:number,region:number) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   let API_PARAMS = {bklt_type:'',bklt_title:'',oz_year:'',oz_month:'',client:'',region:''}
    //     //   // console.log(clientId)
    //     //   API_PARAMS.bklt_type= bookletType
    //     //   bookletTitle !== ''? API_PARAMS.bklt_title= bookletTitle  : null
    //     //   organizedYear !== 'YEAR'?  API_PARAMS.oz_year= organizedYear : null
    //     //   API_PARAMS['oz_month']= organizedMonth
    //     //   clientId >= 0 ?  API_PARAMS.client= String(clientId) : null
    //     //   region >= 0 ?  API_PARAMS.region= String(region): null
          
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     // //  retrieveAllFilterBooklets(bookletType: string, organizedYear: string, organizedMonth: string, bookletTitle: string) {
    //     // //   const API_URL: string = this.api_full.concat('booklet');
    //     // //   const API_PARAMS = { bklt_type: bookletType, oz_year: organizedYear, oz_month: organizedMonth, bklt_title: bookletTitle, };
    //     // //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // // }
      
    //     //  retrieveAllClientBooklets(bookletType: string,  bookletTitle: string,clientId:number) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   const API_PARAMS = { bklt_type: bookletType, bklt_title: bookletTitle ,client:String(clientId)};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveNameFilterBooklets(bookletType: string, bookletTitle: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet');
    //     //   const API_PARAMS = { bklt_type: bookletType, bklt_title: bookletTitle };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
      
      
    //     //  retrieveBookletbyID(bookletId: number): Observable<BookletModel> {
    //     //   let API_URL: string = this.api_full.concat('booklet/:bookletId');
    //     //   API_URL = API_URL.replace(':bookletId', String(bookletId));
    //     //   return this.apiClient.get<BookletModel>(API_URL);
    //     // }
      
    //     //  archiveBooklet(bookletId: number, request: any) {
    //     //   let API_URL: string = this.api_full.concat('booklet/:bookletId/archive_booklet');
    //     //   API_URL = API_URL.replace(':bookletId', String(bookletId));
    //     //   return this.apiClient.put(API_URL, request);
    //     // }
      
    //     //  deleteBooklet(bookletId: number){
    //     //   let API_URL: string = this.api_full.concat(('booklet/:bookletId'));
    //     //   API_URL = API_URL.replace(':bookletId', String(bookletId));
    //     //   return this.apiClient.delete(API_URL);
    //     // }
      
    //     //  retrieveAllProfilesByBookletID(bookletId: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { bklt: bookletId };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllProfilesByBookletIDandName(bookletId: string, profileName: string, frozen: any) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   var API_PARAMS = {}
    //     //   if(frozen){
    //     //     API_PARAMS = { bklt: bookletId, name: profileName, show_archives: frozen};
    //     //   } else {
    //     //     API_PARAMS = { bklt: bookletId, name: profileName};
    //     //   }
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllPaginatedProfilesByBookletID(bookletId: string, pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { bklt: bookletId, page: pageId};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  restoreBooklet(bookletId: string, request: any) {
    //     //   let API_URL: string = this.api_full.concat('booklet-archive/:bookletId/restore_booklet');
    //     //   API_URL = API_URL.replace(':bookletId', bookletId);
    //     //   return this.apiClient.put(API_URL, request);
    //     // }
      
    //     //  createProfile(profile: any) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   return this.apiClient.post(API_URL, profile);
    //     // }
      
    //     //  updateProfile(profileId: string, profile: any) {
    //     //   let API_URL: string = this.api_full.concat('profile/:profileId');
    //     //   API_URL = API_URL.replace(':profileId', profileId);
    //     //   return this.apiClient.put(API_URL, profile);
    //     // }
      
    //     //  deleteProfile(profileId: number) {
    //     //   let API_URL: string = this.api_full.concat('profile/:profileId');
    //     //   API_URL = API_URL.replace(':profileId', String(profileId));
    //     //   return this.apiClient.delete(API_URL);
    //     // }
      
    //     //  retrieveProfilebyId(profileId: string) {
    //     //   let API_URL: string = this.api_full.concat('profile/:profileId');
    //     //   API_URL = API_URL.replace(':profileId', profileId);
    //     //   return this.apiClient.get(API_URL);
    //     // }
      
    //     //  retrieveAllProfilesInit() {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   return this.apiClient.get(API_URL);
    //     // }
      
    //     //  retrieveAllClients():Observable<ClientModel[]> {
    //     //   const API_URL: string = this.api_full.concat('client');
    //     //   return this.apiClient.get<ClientModel[]>(API_URL);
    //     // }
      
    //     //  retrieveAllIndustry() {
    //     //   const API_URL: string = this.api_full.concat('industry');
    //     //   return this.apiClient.get(API_URL);
    //     // }
      
      
    //     //  retrieveAllRegion():Observable<RegionModel[]>  {
    //     //   const API_URL: string = this.api_full.concat('region');
    //     //   return this.apiClient.get<RegionModel[]>(API_URL);
    //     // }
      
       
      
    //     //  createClients(client:any) {
    //     //   const API_URL: string = this.api_full.concat('client');
    //     //   return this.apiClient.post(API_URL, client);
    //     // }
      
    //     //  getProfileExtras(profileId: string){
    //     //   const API_URL: string = this.api_full.concat('profile') + '/' + profileId + '/extras';
    //     //   return this.apiClient.get(API_URL);
    //     // }
      
    //     //  deleteProfileExtras(extrasId: string){
    //     //   const API_URL: string = this.api_full.concat('profile-extras') + '/' + extrasId;
    //     //   return this.apiClient.delete(API_URL);
    //     // }
      
    //     //  postProfileExtras(payload: any){
    //     //   const API_URL: string = this.api_full.concat('profile-extras');
    //     //   return this.apiClient.post(API_URL, payload);
    //     // }
      
    //     //  associateClient(payload: any):Observable<any> {
    //     //   const API_URL: string = this.api_full.concat('booklet/add_to_client');
    //     //   return this.apiClient.post(API_URL, payload);
    //     // }
      
    //     //  removeClient(payload: any):Observable<any> {
    //     //   const API_URL: string = this.api_full.concat('booklet/'+payload +'/remove_from_client');
    //     //   return this.apiClient.delete (API_URL, payload);
    //     // }
      
    //     //  patchProfileExtras(payload: { id: string; }){
    //     //   const API_URL: string = this.api_full.concat('profile-extras') + '/' + payload.id;
    //     //   return this.apiClient.patch(API_URL, payload);
    //     // }
      
    //     //  retrieveAllProfileExtras(index: string, size: string, name_search: string ) {
    //     //   var API_URL: string = this.api_full.concat('profile-extras?page_size=100');
    //     //   API_URL += '?page=' + index + '&page_size=' + size;
    //     //   if(name_search.length > 0){
    //     //     API_URL += "&name=" + encodeURIComponent(name_search);
    //     //   }
    //     //   return this.apiClient.get(API_URL);
    //     // }
      
    //     //  retrieveAllPaginatedProfilesInit(pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { page: pageId};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllPaginatedProfiles(createdYear: string, createdMonth: string, pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, page: pageId};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
       
      
    //     //  retrievePageProfiles( url: string ) {
    //     //   const API_URL: string = this.api_full.concat('profile') + url;
    //     //   // const API_PARAMS = { cm_name: companyName, page: pageId };
    //     //   return this.apiClient.get(API_URL, {});
    //     // }
      
    //     //  retrievePaginatedProfileData( url: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = url ;
    //     //   return this.apiClient.get(API_URL.concat('?' + API_PARAMS));
    //     // }
      
    //     //  retrieveCmNameandProfileName( companyName: string, profileName: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { cm_name: companyName, name: profileName} ;
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveCmNameandJobTitle( companyName: string, jobTitle: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { cm_name: companyName, j_title: jobTitle} ;
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveProfileNameandJobTitle( profileName: string, jobTitle: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { name: profileName, j_title: jobTitle} ;
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllFiltersProfileswithoutYr(companyName: string, jobTitle: string, profileName: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { cm_name: companyName, j_title: jobTitle, name: profileName };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllComNameFilterProfiles(createdYear: string, createdMonth: string, companyName: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveComNameFilterProfiles( companyName: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { cm_name: companyName };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveComNameClientFilterProfiles( companyName: string, clientId:number) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { cm_name: companyName , client:String(clientId) };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     // // tslint:disable-next-line: max-line-length
    //     //  retrieveAllFiltersClientProfiles(createdYear: string, createdMonth: string, companyName: string, jobTitle: string, profileName: string,clientId:number) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, j_title: jobTitle, name: profileName, client:String(clientId) };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
    //     //  retrieveAllFiltersProfiles(createdYear: string, createdMonth: string, companyName: string, jobTitle: string, profileName: string,) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, j_title: jobTitle, name: profileName,  };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllFiltersProfilesClient(createdYear: string, createdMonth: string, companyName: string, jobTitle: string, profileName: string,clientId:number) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   let API_PARAMS
    //     //   if(createdYear !== 'YEAR' && clientId !== null){
    //     //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId };
    //     //   } else if(createdYear !== 'YEAR' && clientId !== null && companyName !== ''){
    //     //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId ,cm_name: companyName, };
    //     //   } else if(createdYear !== 'YEAR' && clientId !== null && jobTitle !== ''){
    //     //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId ,j_title: jobTitle, };
    //     //   }else if(createdYear !== 'YEAR' && clientId !== null && profileName !== ''){
    //     //     API_PARAMS = { crt_year: createdYear ,crt_month: createdMonth , client: clientId ,name: profileName, };
    //     //   }else if(clientId !== null && profileName !== '' && companyName !== ''){
    //     //     API_PARAMS = { cm_name: companyName, client: clientId ,name: profileName, };
    //     //   }else if(clientId !== null && profileName !== '' && jobTitle !== ''){
    //     //     API_PARAMS = { j_title: jobTitle, client: clientId ,name: profileName, };
    //     //   }else if(clientId !== null && companyName !== '' && jobTitle !== ''){
    //     //     API_PARAMS = { j_title: jobTitle, client: clientId ,cm_name: companyName, };
    //     //   } else if(companyName !== '' && clientId !== null){
    //     //     API_PARAMS = { cm_name: companyName,client: clientId  };
    //     //   } else if(jobTitle !== '' && clientId !== null){
    //     //     API_PARAMS = { j_title: jobTitle,client: clientId  };
    //     //   } else if(profileName !== '' && clientId !== null){
    //     //     API_PARAMS = { name: profileName,client: clientId  };
    //     //   } else if(clientId !== null){
    //     //     API_PARAMS = { client: clientId };
    //     //   }
      
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveCmNamePrflNameFiltersProfiles(createdYear: string, createdMonth: string, companyName: string, profileName: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, name: profileName };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveCmNameJbTitleFiltersProfiles(createdYear: string, createdMonth: string, companyName: string, jobTitle: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, cm_name: companyName, j_title: jobTitle, };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrievePrflNameJbTitleFiltersProfiles(createdYear: string, createdMonth: string, profileName: string, jobTitle: string) {
    //     //   const API_URL: string = this.api_full.concat('profile');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, name: profileName, j_title: jobTitle};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  copyProfile(profileId: string, request: any) {
    //     //   let API_URL: string = this.api_full.concat('profile/:profileId/copy_profile');
    //     //   API_URL = API_URL.replace(':profileId', profileId);
    //     //   return this.apiClient.put(API_URL, request);
    //     // }
      
    //     //  archiveProfile(profileId: string, request: any) {
    //     //   let API_URL: string = this.api_full.concat('profile/:profileId/archive_profile');
    //     //   API_URL = API_URL.replace(':profileId', profileId);
    //     //   return this.apiClient.put(API_URL, request);
    //     // }
      
    //     //  restoreProfile(profileId: string, request: any) {
    //     //   let API_URL: string = this.api_full.concat('profile-archive/:profileId/restore_profile');
    //     //   API_URL = API_URL.replace(':profileId', profileId);
    //     //   return this.apiClient.put(API_URL, request);
    //     // }
      
    //     // getDownloadBookletParams(custom_ph:string,custom_email:string){
    //     //   let API_PARAMS = {custom_ph:'',custom_email:''}
    //     //   if(custom_ph !== ''){
    //     //     API_PARAMS.custom_ph = encodeURIComponent(custom_ph) 
    //     //   }
    //     //   if(custom_email !== ''){
    //     //     API_PARAMS.custom_email = custom_email 
    //     //   }
    //     //   if ( custom_ph !== '' &&  custom_email !== '' ){
    //     //     API_PARAMS.custom_ph = encodeURIComponent(custom_ph)
    //     //     API_PARAMS.custom_email = custom_email;
    //     //   } 
    //     //   return API_PARAMS
    //     // }
      
    //     //  retrieveBookletCompositebyId(bookletId: string ,custom_ph:string,custom_email:string) {
    //     //   let API_URL: string = this.api_full.concat('booklet-composite/:bookletId');
    //     //   API_URL = API_URL.replace(':bookletId', bookletId);
          
    //     //   return this.apiClient.get(API_URL , {params:  this.getDownloadBookletParams(custom_ph,custom_email)});
    //     // }
      
    //     //  downloadBookletCompositePDF(bookletId: string,custom_ph:string,custom_email:string,masking_profiles_param: string) {
    //     //   let API_URL: string = this.api_full.concat('booklet-composite/:bookletId/export_custom_booklet');
    //     //   API_URL = API_URL.replace(':bookletId', bookletId);
      
    //     //   if(masking_profiles_param?.length > 0){
    //     //     API_URL += "?masking_profiles=" + masking_profiles_param;
    //     //   }
        
    //     //   return this.apiClient.get(API_URL, {responseType: 'arraybuffer',params:this.getDownloadBookletParams(custom_ph,custom_email)});
    //     // }
      
    //     //  downloadBookletCompositeExcell(bookletId: string,custom_ph:string,custom_email:string,) {
    //     //   let API_URL: string = this.api_full.concat('booklet-composite/download_booklet?booklet=');
    //     //   API_URL = API_URL.concat(bookletId);
         
    //     //   return this.apiClient.get(API_URL, {responseType: 'arraybuffer',params:this.getDownloadBookletParams(custom_ph,custom_email)});
    //     // }
      
    //     //  retrieveProfileCompositebyId(profileId: string,remove_client_logo:string,custom_ph:string,custom_email:string,custom_location:string) {
    //     //   let API_URL: string = this.api_full.concat('profile-composite/:profileId');
    //     //   API_URL = API_URL.replace(':profileId', profileId);
    //     //   let API_PARAMS = {remove_client_logo:'',custom_ph:'',custom_email:'',custom_location:''}
    //     //   if(remove_client_logo == 'true' ){
    //     //     API_PARAMS.remove_client_logo = remove_client_logo
    //     //   }
    //     //   if(custom_ph !== ''){
    //     //     API_PARAMS.custom_ph = encodeURIComponent(custom_ph) 
    //     //   }
    //     //   if(custom_email !== ''){
    //     //     API_PARAMS.custom_email = custom_email 
    //     //   }
    //     //   if(custom_location !== ''){
    //     //     API_PARAMS.custom_location = custom_location 
    //     //   }
       
    //     //   return this.apiClient.get(API_URL, {params:API_PARAMS });
    //     // }
      
    //     //  downloadProfileCompositePDF(profileId: string,remove_client_logo:string,custom_ph:string,custom_email:string,custom_location:string) {
    //     //   let API_URL: string = this.api_full.concat('profile-composite/:profileId/export_custom_profile');
    //     //   API_URL = API_URL.replace(':profileId', profileId);
    //     //   let API_PARAMS
    //     //   if (remove_client_logo == 'true' && custom_ph !== '' &&  custom_email !== '' && custom_location !== ''){
    //     //     API_PARAMS = { remove_client_logo: remove_client_logo,custom_ph:encodeURIComponent(custom_ph),custom_email:custom_email,custom_location:custom_location };
    //     //   } else if(custom_ph !== '' &&  custom_email !== '' && custom_location !== ''){
    //     //     API_PARAMS = { custom_ph:encodeURIComponent(custom_ph),custom_email:custom_email,custom_location:custom_location };
    //     //   } else if(custom_ph !== '' &&  custom_email !== ''){
    //     //     API_PARAMS = { custom_ph:encodeURIComponent(custom_ph),custom_email:custom_email, };
    //     //   } else if(custom_email !== '' && custom_location !== ''){
    //     //     API_PARAMS = { custom_email:custom_email,custom_location:custom_location };
    //     //   } else if(remove_client_logo == 'true' && custom_email !== '' && custom_location !== ''){
    //     //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_email:custom_email,custom_location:custom_location };
    //     //   }else if(remove_client_logo == 'true' && custom_ph !== '' && custom_location !== ''){
    //     //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_ph:encodeURIComponent(custom_ph),custom_location:custom_location };
    //     //   } else if(remove_client_logo == 'true' && custom_email !== ''  && custom_location !== ''){
    //     //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_email:custom_email,custom_location:custom_location };
    //     //   } else if(remove_client_logo == 'true' && custom_email !== ''  ){
    //     //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_email:custom_email };
    //     //   }else if(remove_client_logo == 'true'  && custom_location !== ''){
    //     //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_location:custom_location };
    //     //   }else if(remove_client_logo == 'true' && custom_ph !== ''){
    //     //     API_PARAMS = {remove_client_logo: remove_client_logo, custom_ph:encodeURIComponent(custom_ph), };
    //     //   }   else if (remove_client_logo == 'true'){
    //     //     API_PARAMS = { remove_client_logo: remove_client_logo}
    //     //   } else if(custom_ph !== ''){
    //     //     API_PARAMS = {custom_ph:encodeURIComponent(custom_ph),}
    //     //   } else if(custom_email !== ''){
    //     //     API_PARAMS = {custom_email:custom_email}
    //     //   } else if(custom_location !== ''){
    //     //     API_PARAMS = {custom_location:custom_location}
    //     //   }
    //     //   return this.apiClient.get(API_URL, {responseType: 'arraybuffer',params:API_PARAMS});
    //     // }
      
    //     //  retrieveAllBookletArchivesInit() {
    //     //   const API_URL: string = this.api_full.concat('booklet-archive');
    //     //   return this.apiClient.get(API_URL);
    //     // }
      
    //     //  retrieveAllPaginatedBookletArchivesInit(pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet-archive');
    //     //   const API_PARAMS = { page: pageId };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllBookletArchives(organizedYear: string, organizedMonth: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet-archive');
    //     //   const API_PARAMS = { oz_year: organizedYear, oz_month: organizedMonth };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllPaginatedBookletArchives(organizedYear: string, organizedMonth: string, pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet-archive');
    //     //   const API_PARAMS = { oz_year: organizedYear, oz_month: organizedMonth, page: pageId};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllFilterBookletArchives(organizedYear: string, organizedMonth: string, bookletTitle: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet-archive');
    //     //   const API_PARAMS = { oz_year: organizedYear, oz_month: organizedMonth, bklt_title: bookletTitle };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveNameFilterBookletArchives(bookletTitle: string) {
    //     //   const API_URL: string = this.api_full.concat('booklet-archive');
    //     //   const API_PARAMS = { bklt_title: bookletTitle };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllProfileArchivesInit() {
    //     //   const API_URL: string = this.api_full.concat('profile-archive');
    //     //   return this.apiClient.get(API_URL);
    //     // }
      
    //     //  retrieveAllPaginatedProfileArchivesInit(pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('profile-archive');
    //     //   const API_PARAMS = { page: pageId };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllProfileArchives(createdYear: string, createdMonth: string) {
    //     //   const API_URL: string = this.api_full.concat('profile-archive');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllPaginatedProfileArchives(createdYear: string, createdMonth: string, pageId: string) {
    //     //   const API_URL: string = this.api_full.concat('profile-archive');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, page: pageId};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveAllFilterProfileArchives(createdYear: string, createdMonth: string, profileName: string) {
    //     //   const API_URL: string = this.api_full.concat('profile-archive');
    //     //   const API_PARAMS = { crt_year: createdYear, crt_month: createdMonth, name: profileName};
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveNameFilterProfileArchives(profileName: string) {
    //     //   const API_URL: string = this.api_full.concat('profile-archive');
    //     //   const API_PARAMS = { name: profileName };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  retrieveProfileMonthlyStat(createdYear: string, bookletType: string) {
    //     //   const API_URL: string = this.api_full.concat('stats-profile-monthly');
    //     //   const API_PARAMS = { crt_year: createdYear, bklt_type: bookletType };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  getStatProList(search: string) {
    //     //   const API_URL: string = this.api_full.concat('profile/stat_prio_list');
    //     //   const API_PARAMS = { search: search };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
    //     //  getFundedList(search: string) {
    //     //   const API_URL: string = this.api_full.concat('profile/funded_initiative_list');
    //     //   const API_PARAMS = { search: search };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
      
    //     //  getJobList(search: string) {
    //     //   const API_URL: string = this.api_full.concat('profile/job_role_list');
    //     //   const API_PARAMS = { search: search };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
    //     //  getTechList(search: string) {
    //     //   const API_URL: string = this.api_full.concat('profile/tech_stack_list');
    //     //   const API_PARAMS = { search: search };
    //     //   return this.apiClient.get(API_URL, { params: API_PARAMS });
    //     // }
}