<header class="position-absolute top-0 end-0 start-0 " style="z-index:99" *ngIf="userProfile && isLoggedIn">
    <div class="container-fluid px-0">
      <nav class="navbar navbar-expand-lg bd-navbar bg-white  shadow-sm" aria-label="Eleventh navbar example">
        <div class="container-fluid px-4">
          <a class="navbar-brand " style="max-width: 160px; height: 45px;" href="javascript:void()" [routerLink]="['/booklet']">
            <img src="../../../assets/svg/profiler-high-resolution-logo-transparent.svg" class="img-fluid" style="height: 100%;" alt="">
            <!-- <h1 class="logo"><i class="bi bi-person me-3 rounderd"></i>
              Profiler</h1> -->
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample09"
            aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse " id="navbarsExample09">
            <ul class="navbar-nav nav-pills gap-2 ms-lg-3 me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link fw-700 rounded-pill active" [routerLink]="['/booklet']" routerLinkActive="active"  aria-current="page" href="javascript:void()">
                  <i class="bi bi-book text-primary me-2"></i>Booklets
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fw-700 rounded-pill" [routerLink]="['/profile']" routerLinkActive="active"  aria-current="page" href="javascript:void()">
                  <i class="bi bi-people text-primary me-2"></i>Profiles
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fw-700 rounded-pill" [routerLink]="['/frozen']" routerLinkActive="active"  aria-current="page" href="javascript:void()">
                  <i class="bi bi-lock text-primary me-2"></i>Frozen
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link fw-700 rounded-pill" [routerLink]="['/stats']" routerLinkActive="active"  aria-current="page" href="javascript:void()">
                  <i class="bi bi-bar-chart text-primary me-2"></i>Statistics
                </a>
              </li>
            </ul>
            <div class="dropdown text-end">
              <a href="#" class="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle text-dark text-capitalize"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img src="../../../assets/svg/person-circle.svg" alt="mdo" width="16" height="16" class="rounded-circle me-2"> {{userProfile?.username}}
              </a>
              <ul class="dropdown-menu dropdown-menu-end dropdown-sm text-small">
               
                <li><a class="dropdown-item text-secondary" href="javascript:void()" (click)="logout()"><i class="bi bi-box-arrow-right  me-2"></i>Sign
                    out</a></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>