/* src/app/stats/stats.component.scss */
::ng-deep .cutomSelect {
  width: 100%;
  height: 38px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: var(--bs-border-radius-pill) !important;
  background-color: #fff;
  padding: 10px;
  line-height: 1 !important;
}
::ng-deep .cutomSelect .ng-dropdown-panel {
  left: 0px;
}
::ng-deep .cutomSelect .ng-dropdown-panel .scroll-host {
  background-color: #f6f6fc;
  top: 10px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  width: 94%;
  left: 10px;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  border-bottom: 1px solid #a6a6aa;
  padding: 6px 10px;
  text-align: start;
}
::ng-deep .cutomSelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #cee2f7;
}
::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.chart-wrapper {
  height: auto;
  width: 800px;
  padding-bottom: 50px;
}
.bi {
  color: #26ADE8;
}
/*# sourceMappingURL=stats.component.css.map */
