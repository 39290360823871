<div class="modal-header">
    <h4 class="modal-title text-primary" *ngIf="data.type == 'Profile'">Edit Profile Before Export </h4>
    <h4 class="modal-title" *ngIf="data.type == 'Booklet'">Edit Booklet Before Export </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body px-3">
    <div class="dialog-booklet-container"  *ngIf="data.type == 'Profile'">

        <ng-container >
          <div>
            <form [formGroup]="profileDetailsForm" (ngSubmit)="onSubmitProfileDetails(profileDetailsForm.value)">
              <div class="form-check mb-3">
                 <input type="checkbox" class="form-check-input" formControlName="remove_client_logo"  id="flexCheckDefault" />
                 <label class="form-check-label" for="flexCheckDefault">
                    Remove Client Logo!
                 </label>
                 
              </div>
              <div class="mb-3">
                <label>New Phone Number</label>
                <input  placeholder="Phone number" class="form-control rounded-pill" formControlName="custom_ph_no" >
            </div>
            <div class="mb-3">
                <label>New Email Address</label>
                <input  placeholder="Email Address" class="form-control rounded-pill" formControlName="custom_email_address" >
            </div>
            <div class="mb-3">
                <label>New Location Address</label>
                <input  placeholder="Address" class="form-control rounded-pill" formControlName="custom_location_address" >
              </div>
              <br>
               
  
              <br>
            </form>
          </div>
        </ng-container>
  
  
    </div>
    <div class="dialog-booklet-container"  *ngIf="data.type == 'Booklet'">
  
        <ng-container >
          <div>
            <form [formGroup]="bookletDetailsForm" (ngSubmit)="onSubmitBookletDetails(bookletDetailsForm.value)">
              <!-- <div class="row">
                 <mat-checkbox  formControlName="remove_client_logo">Remove Client Logo!</mat-checkbox>
              </div> -->
              <div class="mb-3">
              
                <label>New Phone Number</label>
                <input  placeholder="Phone number" class="form-control" formControlName="custom_ph" >
  
              
            </div>
            <div class="mb-3">
              
                <label>New Email Address</label>
                <input  placeholder="Email Address" class="form-control" formControlName="custom_email" >
  
              
            </div>
            <!-- <div class="row">
              
                <label>New Location Address</label>
                <input  placeholder="Address" formControlName="custom_location_address" >
  
              
              </div> -->
               
            </form>
          </div>
        </ng-container>
  
  
    </div>
    
</div>
<div class="modal-footer" *ngIf="data.type == 'Profile'">
  <button  (click)="closePopup()" type="button"  style=" margin-right:10px" class="btn btn-primary"   >
    Close
 </button>

 <button class="btn btn-primary" (click)="onSubmitProfileDetails(profileDetailsForm.value)"  style=" margin-right:10px"  type="submit" 
 [disabled]="
!( profileDetailsForm.get('custom_ph_no').value?.length > 0 ||
profileDetailsForm.get('custom_email_address').value?.length > 0 ||
profileDetailsForm.get('custom_location_address').value?.length > 0 ||
profileDetailsForm.get('remove_client_logo').value
)
 " >
   Export
 </button>
 <button class="btn btn-primary"  type="button" (click)="skipEditAndDownloadProfile()" >
 Skip &  Export
 </button>
</div>

<div class="modal-footer" *ngIf="data.type == 'Booklet'">
    <button class="btn btn-primary"   style=" margin-right:10px"  type="submit" (click)="onSubmitBookletDetails(bookletDetailsForm.value)"
        [disabled]="!( bookletDetailsForm.get('custom_ph').value?.length > 0 ||
               bookletDetailsForm.get('custom_email').value?.length > 0  )" >
      Export
   </button>
    <button class="btn btn-primary"  type="submit" (click)="skipEditAndDownloadBooklet()" >
     Skip &  Export
   </button>
    <button type="button" class="btn btn-primary"  (click)="activeModal.dismiss('Close click')">Close</button>
</div>