import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-display-toast-msg',
  standalone: false,
  templateUrl: './display-toast-msg.component.html',
  styleUrl: './display-toast-msg.component.scss'
})
export class DisplayToastMsgComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

   

  clearAllToasts() {
    this.toastService.clear();
  }

}
