<div class="modal-content" (click)="clickOFF($event)">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Feedback Screenshot</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="close($event)"></button>
    </div>
    <div class="modal-body">
      

      <div class="row p-3">

        <div class="col-12 text-center mb-3">
          <p>Choose a type of screenshot for the feedback reference. </p>
        </div>

        <div class="col-4 d-flex justify-content-center">

          <div class="card select-item" (click)="selectedType(1 , $event)">
            <img *ngIf="!isIsCDB" src="../../../assets/desk-feedback-nothing.svg" alt="">
            <img *ngIf="isIsCDB" src="../../../assets/desk-feedback-nothingIS.svg" alt="">
          </div>

        </div>

        <div class="col-4 d-flex justify-content-center">
          <div class="card select-item" (click)="selectedType(2 , $event)">
            <img *ngIf="!isIsCDB" src="../../../assets/desk-feedback-generic.svg" alt="">
            <img *ngIf="isIsCDB" src="../../../assets/desk-feedback-genericIS.svg" alt="">
          </div>
        </div>

        <div class="col-4 d-flex justify-content-center">
          <div class="card select-item" (click)="selectedType(3, $event)">
            <img *ngIf="!isIsCDB" src="../../../assets/desk-feedback-crop.svg" alt="">
            <img *ngIf="isIsCDB" src="../../../assets/desk-feedback-cropIS.svg" alt="">
          </div>
        </div>

        <div class="col-12  mt-5"> 
          <p class="m-0 mt-1"><b>No Picture</b>: Submit feedback without attaching any screenshot.</p>
          <p class="m-0 mt-1"><b>Generic</b>: Submit feedback with a screenshot of the entire screen. </p>
          <p class="m-0 mt-1"><b>Crop</b>: Submit feedback with a cropped screenshot of a specific section of the screen.</p>
        </div>
        
      </div>

    </div>
    
  </div>