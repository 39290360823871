  <app-loading [serviceResponseProgress]="serviceResponseProgress"></app-loading>
   
    <div class="container-fluid px-0">
        <div class="row px-0 mx-0">
            <div class="col-md-3 col-lg-3 p-0 sidebar shadow-sm" style="background-color: #e4e5f7;">
                <div class="offcanvas-md offcanvas-end shadow-sm h-100" tabindex="-1" id="sidebarMenu"
                  aria-labelledby="sidebarMenuLabel">
                  <!-- <div class="offcanvas-header shadow-sm">
                    <h5 class="offcanvas-title" id="sidebarMenuLabel">Company name</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu"
                      aria-label="Close"></button>
                  </div> -->
                  <div class="offcanvas-body d-md-flex flex-column p-0 px-2 px-md-4  pt-lg-3 overflow-y-auto ">
                    <div class="btn-group mb-4"> 
                       <button class="btn btn-sm sidebar-filter-btn btn-outline-primary rounded-start-pill" [class.active]="selectedBookletType == 'CS'" id="Conference" (click)="setActive('Conference')"> Conference </button>
                        <button class="btn btn-sm sidebar-filter-btn btn-outline-primary rounded-end-pill" [class.active]="selectedBookletType == 'RT'" id="RoundTable" (click)="setActive('RoundTable')">  Roundtable</button>
                    </div>
                    <p class="fw-semibold small text-primary"> Showing  <span class="small text-gray"> {{booklets$.length}} out of {{bookletList.value.count}} </span> booklets. <span class="float-end mt-1 cursor-pointer" (click)="resetFilter()" style="font-size: 10px;"> Clear All  <i  class="bi bi-x-circle-fill me-1  text-danger d-inline-block"></i>  </span></p>
                    <ul class="nav flex-column mt-2 gap-3">
                       
                      <li class="nav-item text-start">
                        <h6 class=" small text-primary"><i class="bi bi-search text-primary fw-600 me-1"></i>Search</h6>
                        <div class="input-group"  >
                            <input type="text" class="form-control form-control-sm rounded-pill custom-input" name="bklt_title" ngDefaultControl placeholder="Search here.."  [(ngModel)]="bookletSearchModel.bklt_title" (ngModelChange)="onInputChange($event)">
                        </div>
                         
                      </li>
                      <li class="nav-item text-start">
                        <h6 class="text-muteed small text-primary"><i class="bi bi-building text-primary fw-600 me-1"></i>Client</h6>
                        <div class="input-group">
                            <ng-select class="client-dropdown rounded-pill cutomSelect" ngDefaultControl  (ngModelChange)="onValueChange($event)" [(ngModel)]="bookletSearchModel.client" placeholder="Select client">    
                                <ng-option *ngFor="let client of clientList.value "  [value]="client?.id"> 
                                    {{ client?.name }}
                                </ng-option>
                            </ng-select>
                           
                        </div>
                      </li>
                      <li class="nav-item text-start">
                        <h6 class="text-muteed small text-primary"><i class="bi bi-globe2 text-primary fw-600 me-1"></i>Region</h6>
                        <div class="input-group">
                        <ng-select class="region-dropdown rounded-pill cutomSelect" ngDefaultControl  (ngModelChange)="onValueChange($event)" [(ngModel)]="bookletSearchModel.region" placeholder="Select region">    
                                <ng-option *ngFor="let region of regionList.value"  [value]="region.id"> 
                                    {{ region.name }}
                                </ng-option>
                        </ng-select>
                           
                        </div>
                      </li>
                      <li class="nav-item text-start">
                        <h6 class="text-muteed small text-primary"><i class="bi bi-calendar3 text-primary fw-600 me-1"></i>Year</h6>
                        <div class="input-group">
                            <ng-select class="year-dropdown rounded-pill cutomSelect" ngDefaultControl  [(ngModel)]="bookletSearchModel.oz_year" (ngModelChange)="onValueChange($event , 'year')" placeholder="Select year">    
                                <ng-option *ngFor="let years of calendarYears"  [value]="years"> 
                                    {{ years }}
                                </ng-option>
                            </ng-select>
                           
                        </div>
                      </li>
                      <li class="nav-item text-start">
                        <h6 class="text-muteed small text-primary"><i class="bi bi-calendar-month text-primary fw-600 me-1"></i>Month</h6>
                        <div class="input-group">
                            <ng-select class="month-dropdown rounded-pill cutomSelect" ngDefaultControl [disabled]="!bookletSearchModel.oz_year"  [(ngModel)]="selectedMonth" (ngModelChange)="monthChanged($event)" placeholder="Select month">    
                                <ng-option *ngFor="let month of calendarMonths"  [value]="month"> 
                                    {{ month }}
                                </ng-option>
                            </ng-select>
                           
                        </div>
                      </li>
                      
                    </ul>
      
                  </div>
                </div>
              </div>
           
            <!-- Main Content -->
            <main class="col-md-9 ms-sm-auto col-lg-9 px-md-3 bg-body-tertiary pt-1" style="background-color: #f2f2f2;">
          
                <!-- Booklet List -->
                <div class="row "> 
                    <div class="col-12 pe-0">
                     <div class="m-auto mb-2 d-flex justify-content-around flex-column position-fixed align-items-end " style="right:0px;z-index:999;top:10.5%"> 
                     
                      <button class="btn btn-primary rounded-start-pill  mb-3 preview-btn" (click)="addBooklet()">  
                        <i class="bi bi-plus-circle px-2 fs-6 d-inline-block text-light "></i> <span class="d-inline-block fs-6">Add New Booklet</span>
                      </button>
                    </div>  
                      <div  style="height: 88vh !important; overflow-x: hidden; overflow-y: auto;" 
                      infiniteScroll  [fromRoot]="true"  *ngIf="booklets$.length > 0"
                       [infiniteScrollDistance]="2" [infiniteScrollThrottle]="12" (scrolled)="onbookletScroll()"  [scrollWindow]="false"> 
                            <table class="table table-hover custom-table"  >
               
                                <tbody class=" mainContent" >
                                  <tr class="shadow-sm overflow-auto rounded bg-primary-light "  *ngFor="let booklet of booklets$ ;let i = index" >
                                    <td  *ngIf="selectedBookletType == 'CS'">
                                     <a [routerLink]="['/booklet', booklet.id]" class="text-decoration-none">   <img class="" width="30" src="../../assets/conference.png" alt=""> </a> 
                                    </td>
                                    <td [routerLink]="['/booklet', booklet.id]" *ngIf="selectedBookletType == 'RT'">
                                      <a [routerLink]="['/booklet', booklet.id]" class="text-decoration-none"> <img class="cursor-pointer" width="30" src="../../assets/meeting-room.png" alt=""> </a> 
                                    </td>
                                    <td >
                                      <a class="d-flex flex-column text-start cursor-pointer text-decoration-none" [routerLink]="['/booklet', booklet.id]">
                                        <h6 class="m-0 text-primary fw-semibold" [title]="booklet.title">{{ booklet.title.length < 32 ? booklet.title : booklet.title.slice(0,31) +'..'  }}</h6>
                                        <p class="m-0 text-muted small fst-italic"><i class="bi bi-person me-0"></i> {{booklet.created_by.name}}
                                        </p>
                                      </a>
                                    </td>
                                    <td class="text-muted small" > <p *ngIf="getRegionName(booklet.region) " class="text-start mb-0 small"> <i class="bi bi-globe me-1"></i>  <span > {{getRegionName(booklet.region)}} </span> </p> <p class="text-start mb-0 small" *ngIf="!getRegionName(booklet.region) " > <i class="bi bi-globe me-1"></i> NA </p> </td>
                                    <td class="text-muted small"> <p  class="text-start mb-0 small">  <i class="bi bi-calendar4 me-1"></i> {{booklet.created_at | date:'medium'}} </p></td>
                                    <td *ngIf="(booklet.client_list.length > 0 && booklet.client_list[0].client_id )"> <p class="w-100 text-start d-block mt-2 d-flex justify-content-between px-3"> <span class="text-capitalize">  <i class="bi bi-building"></i> {{ getClientName(booklet.client_list[0].client_id)  }} </span> <span class="cursor-pointer"  (click)="removeClient(booklet)">   <i class="bi bi-x-circle text-danger"></i></span> </p> </td>
                                    <td *ngIf="!(booklet.client_list.length > 0 && booklet.client_list[0].client_id )">
                                        <ng-select class="form-control-sm cutomSelect form-select small rounded-pill mb-1 shadow-none" [clearable]="false"  [ngModel]="(booklet.client_list.length > 0 && booklet.client_list[0].client_id ) ? booklet.client_list[0].client_id : 'Associate Client'" (ngModelChange)="associateClient($event, booklet)"  >
                                            <ng-option *ngFor="let client of clientList.value" [value]="client?.id" class="text-black-50"> {{client?.name}} </ng-option>
                                        </ng-select> 
                                      
                                    </td>
                                    <td>
                                      
                                      <div class="btn-group">
                                        <button class="btn btn-light btn-sm rounded-pill me-1" (click)="exportBookletDialog($event,booklet, '/booklet-composite', booklet.id, booklet.masking_profiles)">
                                          <i class="bi bi-download text-info "></i>
                                        </button>
                                        <div class="dropdown">

                                                <button class="btn btn-light btn-sm rounded-pill dropdown-toggle" id="dropdownMenuButton{{i}}"   type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="bi bi-gear text-dark"></i>
                                                </button>
                                                <ul class="dropdown-menu"  [attr.aria-labelledby]="'dropdownMenuButton' + i"   >
                                                <li><a class="dropdown-item" href="javascript:void(0)" (click)="freezeDialog($event,booklet)">
                                                    <i class="bi bi-person-lock text-warning me-2"  ></i>Frozen</a></li>
                                                <li><a class="dropdown-item" href="javascript:void(0)" (click)="deleteBooklet($event,booklet)">
                                                    <i class="bi bi-trash text-danger me-2"></i>Delete</a>
                                                </li>
                                                </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                 
                                  </tbody>
                            </table>
                          </div> 
                          <app-no-data-found [show]="booklets$.length == 0"></app-no-data-found>  
                          
                    
                    </div>
                     
                   
                </div>

                 
            </main>
        </div>
    </div>
 